/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
/* eslint-disable prefer-template */
import React from 'react';
// import {useRouteMatch} from 'react-router-dom';
import {useSelector} from 'react-redux';
import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner';
import className from 'classnames';
// import settings from '../../../services/settings';
// import {
//   selectors as selectorToken,
//   actionTypes as actioTypeToken,
// } from '../../../features/token';
import AuctionMulticriterialForm from './AuctionMulticriterialForm';
import {
  // actionTypes as actioTypeWS,
  selectors as selectorWS,
} from '../../../features/websocket';
import CountdownTimer from '../../CountdownTimer';
import RoundLifeCycleEnum from '../../../components/enums/RoundLifeCycleEnum';
import ClubsIcon from '../../../components/MenuIcons/ClubsIcon';
import DiamondIcon from '../../../components/MenuIcons/DiamondIcon';

const AuctionHallRoomBidder = () => {
  // const dispatch = useDispatch();
  // const match = useRouteMatch('/:auctionId');
  // const {auctionId} = match.params;

  // const tokenConst = useSelector(selectorToken.getToken);
  const criteriaOffer = useSelector(selectorWS.getValues);
  const formatTwoDigits = digit => `0${digit}`.slice(-2);
  let date = null;
  let dateEnd = null;
  if (criteriaOffer != null) {
    const tempDate = new Date(criteriaOffer.auctionStartTime);
    date = `${formatTwoDigits(tempDate.getDate())}.${formatTwoDigits(
      tempDate.getMonth() + 1
    )}.${tempDate.getFullYear()} ${formatTwoDigits(
      tempDate.getHours()
    )}:${formatTwoDigits(tempDate.getMinutes())}`;
    const tempEndDate = new Date(criteriaOffer.auctionEndTime);
    dateEnd = `${formatTwoDigits(tempEndDate.getDate())}.${formatTwoDigits(
      tempEndDate.getMonth() + 1
    )}.${tempEndDate.getFullYear()} ${formatTwoDigits(
      tempEndDate.getHours()
    )}:${formatTwoDigits(tempEndDate.getMinutes())}`;
  }
  if (criteriaOffer != null) {
    switch (criteriaOffer.roundLifeCycle) {
      case RoundLifeCycleEnum.STARTED_PHASE:
        return (
          <>
            <div className="bg-white col-lg-12 mt-5 rounded pl-0 pr-0 pb-5 border-bottom">
              <div className="col-lg-12">
                <div className="col-lg-12 text-center pt-2">
                  <p className="h3 bold-wight pt-4">Aukcia začne</p>
                </div>
                <div className="justify-content-center align-middle pt-2 text-center">
                  <div className="col-lg-12 row m-0">
                    <div className="offset-lg-4 col-lg-2">
                      <p className="h6 bold-wight mb-2">Aukcia začne za</p>
                      <Table
                        responsive
                        className="p-5 overflow-hidden align-self-center pt-2 "
                      >
                        <thead className="bg-white border-0">
                          <tr className="bg-white text-blue2 border-0 text-center">
                            <CountdownTimer
                              className="bg-white text-black border-0 h4 time p-0"
                              criteria={criteriaOffer.auctionStartTime}
                            />
                          </tr>
                        </thead>
                        <tbody></tbody>
                      </Table>
                    </div>
                    <div className="col-lg-2 border-left">
                      <p className="h6 bold-wight mb-2">Dátum aukcie</p>
                      <p className="h4 m-0">{date}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white col-lg-12 mb-5 pb-2 rounded pl-0 pr-0">
              <div className="col-lg-12 row m-0">
                <div className="col-lg-12 align-middle text-center">
                  <p className="h4 pt-4">Vaša vstupná ponuka</p>
                </div>
                {criteriaOffer.criteriaWsResponse?.map(criteria => (
                  <div key={criteria.id} className="col-lg-6 pb-5 ">
                    <Table striped responsive className="table-border">
                      <thead className="table-border">
                        <tr className="text-blue">
                          <th className="bg-muted3 text-blue2 bold-wight pt-3 pb-3  border-0">
                            Názov položky/kritéria
                          </th>
                          <th className="bg-muted3 text-blue2 bold-wight pt-3 pb-3  border-0">
                            Hodnota
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {criteriaOffer.criteriaPriceOffers?.map(priceOffer => {
                          if (criteria.id === priceOffer.criteriaUuid) {
                            return (
                              <tr key={priceOffer.id}>
                                <td>
                                  {' '}
                                  <p className="h6 m-0">
                                    <DiamondIcon className="mr-2 mb-1" />{' '}
                                    {priceOffer.name}
                                  </p>
                                </td>
                                <td>
                                  {priceOffer.lastOfferValue +
                                    ' ' +
                                    priceOffer.mj}
                                </td>
                              </tr>
                            );
                          }
                          return null;
                        })}
                        <tr className="bg-primary">
                          <td className="text-white">
                            <ClubsIcon fill="white" className="mr-2 mb-1" />
                            {criteria.name}
                          </td>
                          <td>
                            {' '}
                            <p className="text-white col-lg-6 p-0 m-0">
                              {criteria.lastOfferValue}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                ))}
              </div>
            </div>
            <div className="bg-white col-lg-12 mt-5 mb-5 pb-2 rounded pl-0 pr-0">
              <div style={{zIndex: 2000, pointerEvents: 'none'}}>
                <div
                  style={{
                    background: 'rgba(0, 0, 0, 0.1)',
                    position: 'absolute',
                    height: '100%',
                    width: '100%',
                    zIndex: 2000,
                    pointerEvents: 'none',
                  }}
                ></div>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4 border-bottom">
                  <div className="col-lg-12 row">
                    <div className="col-lg-12 ">
                      <h1 className="h4 font-weight-bold pl-4 mb-4 ml-3">
                        Biddovanie na kritéria zakázky
                      </h1>
                    </div>
                    <div className="col-lg-3  text-center h5">
                      <p className="mt-2"></p>
                    </div>
                    <div
                      className={className({
                        'text-center h5': true,
                        'col-lg-2':
                          criteriaOffer != null &&
                          criteriaOffer.showWinningOffers,
                        'col-lg-3':
                          criteriaOffer != null &&
                          !criteriaOffer.showWinningOffers,
                      })}
                    >
                      <p className="mt-2">Počet bodov</p>
                    </div>
                    {criteriaOffer != null &&
                    criteriaOffer.showWinningOffers ? (
                      <div className="col-lg-2 text-center h5">
                        <p className="mt-2">Vyherná ponuka</p>
                      </div>
                    ) : null}
                    <div
                      className={className({
                        'text-center h5': true,
                        'col-lg-2':
                          criteriaOffer != null &&
                          criteriaOffer.showWinningOffers,
                        'col-lg-3':
                          criteriaOffer != null &&
                          !criteriaOffer.showWinningOffers,
                      })}
                    >
                      <p className="mt-2">Moja posledná ponuka</p>
                    </div>
                    <div className="col-lg-3 text-center h5">
                      <p className="mt-2">Nová ponuka</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  {' '}
                  <AuctionMulticriterialForm
                    initialValues={criteriaOffer}
                    criteria={criteriaOffer}
                  />
                </div>
              </div>
            </div>
          </>
        );
      case RoundLifeCycleEnum.ACTIVE_PHASE:
        return (
          <>
            <div className=" mt-5 mb-5 pb-2 rounded pl-0 pr-0">
              <div className="col-lg-12 p-0">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="row col-lg-12 m-0 pl-0 pr-0 bg-white">
                      <h1 className="h4 font-weight-bold pl-4 mb-4 ml-3 pt-5 pb-4 ">
                        Biddovanie na kritéria zakázky
                      </h1>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="row col-lg-12 m-0 pl-0 pr-0 bg-dark">
                      <div className="col-lg-3 h5">
                        <p className="mt-2"></p>
                      </div>
                      <div
                        className={className({
                          ' h5 text-white': true,
                          'col-lg-2':
                            criteriaOffer != null &&
                            criteriaOffer.showWinningOffers,
                          'col-lg-3':
                            criteriaOffer != null &&
                            !criteriaOffer.showWinningOffers,
                        })}
                      >
                        <p className="mt-4">Počet bodov</p>
                      </div>
                      {criteriaOffer != null &&
                      criteriaOffer.showWinningOffers ? (
                        <div className="col-lg-2 text-white h5">
                          <p className="mt-4">Vyherná ponuka</p>
                        </div>
                      ) : null}
                      <div
                        className={className({
                          'h5 text-white': true,
                          'col-lg-2':
                            criteriaOffer != null &&
                            criteriaOffer.showWinningOffers,
                          'col-lg-3':
                            criteriaOffer != null &&
                            !criteriaOffer.showWinningOffers,
                        })}
                      >
                        <p className="mt-4">Moja posledná ponuka</p>
                      </div>
                      <div className="col-lg-3 h5 text-white pl-0">
                        <p className="mt-4">Nová ponuka</p>
                      </div>
                    </div>
                  </div>
                </div>{' '}
                <AuctionMulticriterialForm
                  initialValues={criteriaOffer}
                  criteria={criteriaOffer}
                />
              </div>
            </div>
            {/* <div className="bg-white col-lg-12 mt-5 mb-5 pb-2 rounded pl-0 pr-0">
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4 pl-4 border-bottom">
                <h1 className="h4 font-weight-bold mb-4 ml-3">
                  Graf priebehu aukcie
                </h1>
              </div> */}

            {/* <div className="container pt-5 pb-5">
                <div className="col-lg-12 row">
                  <div className="col-lg-6 text-center pt-5 pb-5">
                    <p className="text-blue">Vyvolávacia cena</p>
                    <p className="h2">
                      <EuroIcon />
                      {criteriaOffer.estimatedValue}
                    </p>
                  </div>
                  <div className="col-lg-6 text-center rounded bg-blue4 pt-5 pb-5 ">
                    <p className="text-blue">Najnižšia ponuka</p>
                    <p className="text-success h2">
                      <EuroIcon />
                      {criteriaOffer.criteriaWsResponse[0].bestOfferValue}
                    </p>
                  </div>
                  <MyChart />
                </div>
              </div> */}
            {/* </div> */}
          </>
        );
      case RoundLifeCycleEnum.END_PHASE:
        return (
          <>
            <div className="bg-white col-lg-12 mt-5 rounded pl-0 pr-0 pb-5 border-bottom">
              <div className="col-lg-12">
                <div className="col-lg-12 text-center pt-2">
                  <p className="h3 bold-wight pt-4">Aukcia skončila</p>
                </div>
                <div className="justify-content-center align-middle pt-2 text-center">
                  <div className="col-lg-12 row m-0">
                    <div className="offset-lg-5 col-lg-2">
                      <p className="h6 bold-wight mb-3">
                        Dátum skončenia aukcie
                      </p>
                      <p className="h4 m-0">{dateEnd}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white col-lg-12 mb-5 pb-2 rounded pl-0 pr-0">
              <div className="col-lg-12 row m-0">
                <div className="col-lg-12 align-middle text-center">
                  <p className="h4 pt-4">Vaša posledná ponuka</p>
                </div>
                {criteriaOffer.criteriaWsResponse?.map(criteria => (
                  <div key={criteria.id} className="col-lg-6 pb-5 ">
                    <Table striped responsive className="table-border">
                      <thead className="table-border">
                        <tr className="text-blue">
                          <th className="bg-muted3 text-blue2 bold-wight pt-3 pb-3  border-0">
                            Názov položky/kritéria
                          </th>
                          <th className="bg-muted3 text-blue2 bold-wight pt-3 pb-3  border-0">
                            Hodnota
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {criteriaOffer.criteriaPriceOffers?.map(priceOffer => {
                          if (criteria.id === priceOffer.criteriaUuid) {
                            return (
                              <tr key={priceOffer.id}>
                                <td>
                                  {' '}
                                  <p className="h6 m-0">
                                    <DiamondIcon className="mr-2 mb-1" />{' '}
                                    {priceOffer.name}
                                  </p>
                                </td>
                                <td>
                                  {priceOffer.lastOfferValue +
                                    ' ' +
                                    priceOffer.mj}
                                </td>
                              </tr>
                            );
                          } else {
                            return null;
                          }
                        })}
                        <tr className="bg-primary">
                          <td className="text-white">
                            <ClubsIcon fill="white" className="mr-2 mb-1" />
                            {criteria.name}
                          </td>
                          <td>
                            {' '}
                            <p className="text-white col-lg-6 p-0 m-0">
                              {criteria.lastOfferValue}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                ))}
              </div>
            </div>
          </>
        );
      case RoundLifeCycleEnum.STOPPED_PHASE:
        return (
          <>
            <div className="bg-white col-lg-12 mt-5 rounded pl-0 pr-0 pb-5 border-bottom">
              <div className="col-lg-12">
                <div className="col-lg-12 text-center pt-2">
                  <p className="h3 bold-wight pt-4">Aukcia bola zastavená</p>
                </div>
                <div className="justify-content-center align-middle pt-2 text-center">
                  <div className="col-lg-12 row m-0">
                    <div className="offset-lg-5 col-lg-2">
                      <p className="h6 bold-wight mb-3">
                        Pre bližšie informácie kontaktujte majiteľa zakázky,
                        poprípade admina
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white col-lg-12 mb-5 pb-2 rounded pl-0 pr-0">
              <div className="col-lg-12 row m-0">
                <div className="col-lg-12 align-middle text-center">
                  <p className="h4 pt-4">Vaša posledná ponuka</p>
                </div>
                {criteriaOffer.criteriaWsResponse?.map(criteria => (
                  <div key={criteria.id} className="col-lg-6 pb-5 ">
                    <Table striped responsive className="table-border">
                      <thead className="table-border">
                        <tr className="text-blue">
                          <th className="bg-muted3 text-blue2 bold-wight pt-3 pb-3  border-0">
                            Názov položky/kritéria
                          </th>
                          <th className="bg-muted3 text-blue2 bold-wight pt-3 pb-3  border-0">
                            Hodnota
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {criteriaOffer.criteriaPriceOffers?.map(priceOffer => {
                          if (criteria.id === priceOffer.criteriaUuid) {
                            return (
                              <tr key={priceOffer.id}>
                                <td>
                                  {' '}
                                  <p className="h6 m-0">
                                    <DiamondIcon className="mr-2 mb-1" />{' '}
                                    {priceOffer.name}
                                  </p>
                                </td>
                                <td>
                                  {priceOffer.lastOfferValue +
                                    ' ' +
                                    priceOffer.mj}
                                </td>
                              </tr>
                            );
                          } else {
                            return null;
                          }
                        })}
                        <tr className="bg-primary">
                          <td className="text-white">
                            <ClubsIcon fill="white" className="mr-2 mb-1" />
                            {criteria.name}
                          </td>
                          <td>
                            {' '}
                            <p className="text-white col-lg-6 p-0 m-0">
                              {criteria.lastOfferValue}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                ))}
              </div>
            </div>
          </>
        );
      default:
        return (
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        );
    }
  } else {
    return (
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    );
  }
};

export default AuctionHallRoomBidder;
