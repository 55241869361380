/* eslint-disable no-underscore-dangle */
import React from 'react';

import Modal from 'react-bootstrap/Modal';
import {useSelector} from 'react-redux';
import {selectors} from '../../../features/auction';
import AddOrEditCriteriaForm, {TYPES} from './AddOrEditCriteriaForm';
import UnitEnum from '../../enums/UnitEnum';
import InfoPopover from '../../Popover';
import InfoPopoverTexts from '../../Popover/InfoPopoverTexts';

export {TYPES} from './AddOrEditCriteriaForm';

function AddOrEditCriteriaModal(props) {
  const {type, id} = props;
  // const dispatch = useDispatch();

  // const auction = useSelector(selectors.getAuction);
  // const isLoading = useSelector(selectors.getLoading);
  const editCriteriaInitialValues = useSelector(selectors.getAuction);
  let initialValues = {};
  initialValues.item = [];
  if (editCriteriaInitialValues != null) {
    editCriteriaInitialValues.criteria.forEach(criteria => {
      if (criteria.id === id) {
        initialValues = criteria;

        if (criteria._item != null) {
          initialValues.item = [...criteria._item];
        }
        if (initialValues.nature == null) {
          initialValues.nature = 'ASC';
        }
        if (
          Object.entries(UnitEnum).find(e => e[0] === criteria.unit) ===
          undefined
        ) {
          initialValues.custom_unit = criteria.unit;
          // eslint-disable-next-line prefer-destructuring
          initialValues.unit = Object.entries(UnitEnum).find(
            e => e[1] === UnitEnum.CUSTOM
          )[0];
        }
        if (initialValues.type == null) {
          initialValues.type = 'price';
        }
      } else if (id === undefined) {
        // nejake defaultne nastavenia
        initialValues.nature = 'ASC';
        initialValues.type = 'price';
        initialValues.unit = 'KS';
      }
    });
  }
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="bg-light pt-5">
        <div className="text-center container pr-1">
          <Modal.Title
            as="h4"
            id="contained-modal-title-vcenter"
            className="font-weight-bolder"
          >
            {(() => {
              switch (type) {
                case TYPES.EDIT:
                  return 'Editovať kritérium';
                case TYPES.ADD:
                  return 'Pridať kritérium';
                default:
                  return '';
              }
            })()}
            <InfoPopover
              placement="bottom"
              text={InfoPopoverTexts.CRITERIA.HEADER_NAME}
              className="text-primary ml-2"
            />
          </Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body className="bg-light">
        <AddOrEditCriteriaForm
          type={type}
          id={id}
          initialValues={initialValues}
          setModalShow={props.setModalShow}
        />
      </Modal.Body>
    </Modal>
  );
}

export default AddOrEditCriteriaModal;
