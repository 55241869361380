import {
  USERS_UPDATE_VALUES,
  USERS_SHOW_LOADING,
  USERS_HIDE_LOADING,
  USERS_SET_INITIAL_VALUES,
} from './actionTypes';

const initialState = {
  values: null,
  loading: false,
  initialValues: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case USERS_SET_INITIAL_VALUES:
      return {...state, initialValues: action.values};
    case USERS_UPDATE_VALUES:
      return {...state, values: action.values};
    case USERS_SHOW_LOADING:
      return {...state, loading: true};

    case USERS_HIDE_LOADING:
      return {...state, loading: false};

    default:
      return state;
  }
};
