import axios from 'axios';
import {toast} from 'react-toastify';
import settings from './settings';
import {actionTypes} from '../features/users';

import loadOrganisations from './loadOrganisations';
import ToastUtil from '../components/Toast/ToastUtil';
import {actionTypes as errorActionType} from '../features/modals';
import Text from '../components/Toast/ToastTexts';

const editOrganization = (
  auctionId,
  request,
  token,
  dispatch,
  thenCallback,
  catchCallback,
  finallyCallback
) => {
  axios
    .post(
      settings.SERVICE_URL + settings.SERVICES.EDIT_ORGANISATIONS,
      request,
      {
        responseType: 'json',
        headers: {
          'Ela-Elc-Auth': token,
        },
      }
    )
    .then(response => {
      // Volame reload roundov
      loadOrganisations(
        auctionId,
        token,
        dispatch,
        undefined,
        undefined,
        () => {
          dispatch({
            type: actionTypes.USERS_HIDE_LOADING,
            payload: response,
          });
          if (typeof thenCallback === 'function') {
            thenCallback(response);
          }
          toast.success(
            ToastUtil(
              Text.EDIT_ORGANIZATION.TITLE,
              Text.EDIT_ORGANIZATION.DESCRIPTION + request.name
            )
          );
        }
      );
    })
    .catch(error => {
      if (error.response != null && error.response.data != null) {
        const {data} = error.response;
        dispatch({
          type: errorActionType.MODAL_ERROR_SHOW,
          modal: {title: data.message, description: data.description},
        });
      }
      if (typeof catchCallback === 'function') {
        catchCallback(error);
      }
    })
    .finally(() => {
      if (typeof finallyCallback === 'function') {
        finallyCallback();
      }
    });
};

export default editOrganization;
