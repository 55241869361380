import React, {useEffect} from 'react';
import {useRouteMatch} from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import {useSelector, useDispatch} from 'react-redux';
import Logo from '../../../../images/logo192.png';
import {selectors} from '../../../../features/users';
import loadOrganisations from '../../../../services/loadOrganisations';
import {selectors as selectorToken} from '../../../../features/token';
import {selectors as selectorWS} from '../../../../features/websocket';

const AuctionHallUsers = () => {
  const dispatch = useDispatch();
  const match = useRouteMatch('/:auctionId');
  const {auctionId} = match.params;
  const token = useSelector(selectorToken.getToken);
  const participants = useSelector(selectors.getValues);
  const users = useSelector(selectorWS.getUsers);
  useEffect(() => {
    loadOrganisations(auctionId, token, dispatch);
  }, [auctionId, dispatch, token]);
  return (
    <div className="bg-white col-lg-12 mt-5 mb-5 pb-2 rounded pr-0 pl-0">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4 pl-4 border-bottom">
        <h1 className="h4 font-weight-bold mb-4 ml-3">Uchádzači</h1>
      </div>
      <div className="table-container">
        <Table striped hover responsive>
          <thead className="bg-white">
            <tr className="bg-white text-blue">
              <th>Firma</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {participants?.organizations?.map(d => (
              <tr key={d.id}>
                <td className="pt-2 pb-2 row">
                  {' '}
                  <img
                    src={Logo}
                    className="img-fluid align-self-center float-right img-thumbnail rounded-circle"
                    alt=""
                  />
                  <div className="card-body col-lg-8">
                    <p className="card-subtitle text-blue2 font-weight-bolder ">
                      {users != null &&
                      users.logedUsersBean.length > 0 &&
                      users.logedUsersBean.find(user => user.uuid === d.uuid)
                        ? users.logedUsersBean.find(
                            user => user.uuid === d.uuid
                          ).username
                        : ''}
                    </p>
                    <h6 className="card-text font-weight-lighter text-muted ">
                      {d.name}
                    </h6>
                  </div>
                </td>
                <td>
                  {users != null &&
                  users.logedUsersBean.length > 0 &&
                  users.logedUsersBean.find(user => user.uuid === d.uuid) ? (
                    <p className="text-success">Online</p>
                  ) : (
                    <p className="text-danger">Offline</p>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};
export default AuctionHallUsers;
