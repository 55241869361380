import {
  USER_AUCTION_DATA_UPDATE,
  // USER_AUCTION_DATA_VALUES,
  USER_AUCTION_START_STATE,
  USER_AUCTION_START_STATE_CLEAR,
} from './actionTypes';

const initialState = {
  value: null,
  roleString: null,
  auctionStartState: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_AUCTION_DATA_UPDATE:
      // eslint-disable-next-line no-case-declarations
      let roleAsString = null;
      switch (action.payload.roleEnum) {
        case 'OWNER':
          roleAsString = 'Administrátor aukcie';
          break;
        case 'BIDDER':
          roleAsString = 'Účastník aukcie';
          break;
        case 'WATCHER':
          roleAsString = 'Pozorovateľ aukcie';
          break;
        default:
          roleAsString = '';
      }
      return {...state, value: action.payload, roleString: roleAsString};
    case USER_AUCTION_START_STATE:
      return {...state, auctionStartState: action.payload};
    case USER_AUCTION_START_STATE_CLEAR:
      return {...state, auctionStartState: null};
    default:
      return state;
  }
};
