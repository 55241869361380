import {
  OFFERS_UPDATE,
  // OFFERS_VALUES,
  OFFERS_SHOW_LOADING,
  OFFERS_HIDE_LOADING,
  OFFERS_SET_INITIAL,
  OFFERS_SELECTED_OFFERS,
  OFFERS_SELECTED_OFFERS_NULL,
  OFFERS_ADD_SHOW_LOADING,
  OFFERS_ADD_HIDE_LOADING,
} from './actionTypes';

const initialState = {
  value: null,
  loading: true,
  addOfferLoading: false,
  offersFormInitial: {},
  selectedOffer: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case OFFERS_UPDATE:
      return {...state, value: action.payload};
    case OFFERS_SET_INITIAL:
      return {
        ...state,
        offersFormInitial: action.payload,
      };
    case OFFERS_SHOW_LOADING:
      return {...state, loading: true};

    case OFFERS_HIDE_LOADING:
      return {...state, loading: false};

    case OFFERS_ADD_SHOW_LOADING:
      return {...state, addOfferLoading: true};

    case OFFERS_ADD_HIDE_LOADING:
      return {...state, addOfferLoading: false};

    case OFFERS_SELECTED_OFFERS:
      return {...state, selectedOffer: action.payload};

    case OFFERS_SELECTED_OFFERS_NULL:
      return {...state, selectedOffer: null};
    default:
      return state;
  }
};
