import React from 'react';
import Form from 'react-bootstrap/Form';
import {reduxForm, Field} from 'redux-form';
import FIELD_NAMES from './OrdersFormFieldNames';
import {BootstrapFormControl} from '../../ReduxBootstrapFields';
import InfoPopoverText from '../../Popover/InfoPopoverTexts';
import {required} from '../../Utils/SubmitFormValidator';

export const FORM_NAME = 'ordersForm';

const warn = values => {
  const warnings = {};
  return warnings;
};

const OrdersForm = () => (
  <Form>
    <Form.Group controlId={FIELD_NAMES.NAME}>
      <Form.Label>Názov (slovensky)</Form.Label>
      <Field
        disabled
        name={FIELD_NAMES.NAME}
        component={BootstrapFormControl}
        text={InfoPopoverText.ORDER.NAME}
        type="string"
        placeholder="Názov"
        validate={[required]}
        maxLength="298"
      />
    </Form.Group>
    {/* <Form.Group controlId={FIELD_NAMES.PUBLISH_TYPE}>
      <Form.Label>Zobraziť v zozname eAukcií cez web publisher:</Form.Label>
      <Field
        disabled
        name={FIELD_NAMES.PUBLISH_TYPE}
        component={BootstrapDropDownControl}
        text={InfoPopoverText.ORDER.PUBLISH_TYPE}
        as="select">
        {Object.entries(PublishTypeEnum).map(pair => (
          <option key={pair[0]} value={pair[0]}>
            {pair[1]}
          </option>
        ))}
      </Field>
    </Form.Group>
    <Form.Group controlId={FIELD_NAMES.TEST_AUCTION}>
      <Form.Label></Form.Label>
      <Field
        disabled
        custom
        name={FIELD_NAMES.TEST_AUCTION}
        type="checkbox"
        label="Vzorová (testovacia) elektronická aukcia:"
        text={InfoPopoverText.ORDER.TEST_AUCTION}
        component={BootstrapFormCheck}
      />
    </Form.Group> */}
  </Form>
);

export default reduxForm({
  form: FORM_NAME,
  warn,
})(OrdersForm);
