import React, {useEffect} from 'react';
import {useRouteMatch} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import Table from 'react-bootstrap/Table';
// import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Logo from '../../../images/logo192.png';
import loadOrganisations from '../../../services/loadOrganisations';
import RoundLifeCycleEnum from '../../../components/enums/RoundLifeCycleEnum';
import {selectors as selectorToken} from '../../../features/token';
import {selectors as selectorWS} from '../../../features/websocket';
import ClubsIcon from '../../../components/MenuIcons/ClubsIcon';
import DiamondIcon from '../../../components/MenuIcons/DiamondIcon';

const AuctionHallRoomAdmin = () => {
  const dispatch = useDispatch();
  const match = useRouteMatch('/:auctionId');
  const {auctionId} = match.params;
  const criteriaOffer = useSelector(selectorWS.getValues);
  const users = useSelector(selectorWS.getUsers);
  const token = useSelector(selectorToken.getToken);

  useEffect(() => {
    loadOrganisations(auctionId, token, dispatch);
  }, [auctionId, dispatch, token]);

  const formatTwoDigits = digit => `0${digit}`.slice(-2);
  let dateEnd = null;
  if (criteriaOffer != null) {
    const tempEndDate = new Date(criteriaOffer.auctionEndTime);
    dateEnd = `${formatTwoDigits(tempEndDate.getDate())}.${formatTwoDigits(
      tempEndDate.getMonth() + 1
    )}.${tempEndDate.getFullYear()} ${formatTwoDigits(
      tempEndDate.getHours()
    )}:${formatTwoDigits(tempEndDate.getMinutes())}`;
  }
  return (
    <div className="col-lg-12 mt-5 mb-5 pb-2 rounded pl-0 pr-0">
      {/* <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4 pl-4 border-bottom">
          <h1 className="h4 font-weight-bold mb-4 ml-3">
            Graf priebehu aukcie
          </h1>
        </div> */}
      <div className="col-lg-12">
        {criteriaOffer != null &&
        criteriaOffer.roundLifeCycle === RoundLifeCycleEnum.END_PHASE ? (
          <div className="col-lg-12 text-center pt-2">
            <p className="h2 text-danger">Aukcia skončila</p>
            <p className="h4">{dateEnd}</p>
          </div>
        ) : null}
      </div>
      {criteriaOffer != null ? (
        <div className="col-lg-12 p-0 bg-white rounded-top">
          <h1 className="h4 font-weight-bold p-5 m-0">Dashboard</h1>
        </div>
      ) : (
        <div className="pl-3">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )}
      <div className="col-lg-12 bg-dark">
        <div className="row">
          <div className="col-lg-6">
            <h5 className=" m-0 text-white pt-4 pb-4 h6">
              Ponuky (
              {criteriaOffer != null &&
              criteriaOffer.criteriaOfferEvaluations != null
                ? criteriaOffer.criteriaOfferEvaluations.length
                : null}
              )
            </h5>
          </div>
          {/* <div className="col-lg-6">
            <Button
              className="float-right mt-3 mr-2 bold-wight"
              variant="success"
            >
              Zobraziť ďalšie kritéria a položky
            </Button>
          </div> */}
        </div>
      </div>
      {criteriaOffer != null && criteriaOffer.criteriaOfferEvaluations != null
        ? criteriaOffer.criteriaOfferEvaluations.map(d => (
            <React.Fragment key={`${d.criteriaId}-${d.offerOrder}`}>
              <div className="bg-white col-lg-12 pl-4 pr-4 mb-3 pb-5 rounded content-shadow">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="row pt-4">
                          <div className="col-lg-1 ">
                            {users != null &&
                            users.logedUsersBean.length > 0 &&
                            users.logedUsersBean.find(
                              user => user.uuid === d.organization.id
                            ) ? (
                              <FiberManualRecordIcon
                                fontSize="small"
                                className="text-success mt-3"
                              />
                            ) : (
                              <FiberManualRecordIcon
                                fontSize="small"
                                className="text-danger mt-3"
                              />
                            )}
                          </div>
                          <div className="col-lg-2 ">
                            <img
                              src={Logo}
                              className="img-fluid align-self-center float-right img-thumbnail rounded-circle"
                              alt=""
                            />
                          </div>
                          <div className="col-lg-9 p-0">
                            <h5 className="card-text bold-wight mt-3">
                              {d.organization.name}
                            </h5>
                          </div>
                        </div>
                      </div>
                      <div className="offset-lg-7 col-lg-1 ">
                        {d.offerOrder === 1 ? (
                          <div className="col-lg-12 bg-success pt-4 pb-3 rounded-bottom table-border">
                            <p className="text-center text-white mb-1">
                              Poradie
                            </p>
                            <h5 className="text-center text-white">
                              {d.offerOrder}.
                            </h5>
                          </div>
                        ) : (
                          <div className="col-lg-12 bg-muted3 pt-4 pb-3 rounded-bottom border-top-0 table-border">
                            <p className="text-center text-blue2 mb-2">
                              Poradie
                            </p>
                            <h5 className="text-center text-blue2">
                              {d.offerOrder}.
                            </h5>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="col-lg-6">
                        <h5 className="card-text bold-wight mt-3">
                          Vstupná ponuka
                        </h5>
                        <Table striped responsive className="table-border">
                          <thead className="table-border">
                            <tr className="text-blue">
                              <th className="bg-muted3 text-blue2 bold-wight p-3  border-0">
                                <ClubsIcon className="mr-2 mb-1" />
                                Kritérium
                              </th>
                              <th className="bg-muted3 text-blue2 bold-wight p-3 border-0">
                                Hodnota
                              </th>
                              <th className="bg-muted3 text-blue2 bold-wight p-3 border-0">
                                Skóre
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {d.auxiliaryCalculations.map(b => (
                              <React.Fragment key={b.criteriaId}>
                                <tr>
                                  <td className="p-3 ">
                                    <p className="h6 m-0">
                                      {' '}
                                      <DiamondIcon className="mr-2 mb-1" />
                                      {
                                        criteriaOffer.criteria.find(
                                          crit => crit.uuid === b.criteriaUuid
                                        ).name
                                      }
                                    </p>
                                  </td>
                                  <td className="p-3 ">
                                    <p className="text-muted col-lg-6 p-0 m-0">
                                      {b.value}
                                    </p>
                                  </td>
                                  <td className="p-3 ">
                                    <p className="text-muted col-lg-6 p-0 m-0">
                                      {Number.parseFloat(b.score).toFixed(2)}
                                    </p>
                                  </td>
                                </tr>
                              </React.Fragment>
                            ))}
                          </tbody>
                          <tfoot className="bg-primary">
                            <tr>
                              <td className="p-3 ">
                                <p className="h6 text-white m-0">
                                  <ClubsIcon
                                    fill="white"
                                    className="mr-2 mb-1"
                                  />
                                  Kritérium
                                </p>
                              </td>
                              <td className="p-3 ">
                                <p className="text-muted col-lg-6"></p>
                              </td>
                              <td className="p-3 ">
                                <p className="text-white col-lg-6 p-0 m-0">
                                  {Number.parseFloat(d.score).toFixed(2)}
                                </p>
                              </td>
                            </tr>
                          </tfoot>
                        </Table>
                      </div>

                      <div className="col-lg-6">
                        <h5 className="card-text bold-wight mt-3">
                          Aukčná ponuka
                        </h5>
                        <Table striped responsive className="table-border">
                          <thead className="table-border">
                            <tr className="text-blue">
                              <th className="bg-muted3 text-blue2 bold-wight p-3  border-0">
                                <ClubsIcon className="mr-2 mb-1" />
                                Kritérium
                              </th>
                              <th className="bg-muted3 text-blue2 bold-wight p-3 border-0">
                                Hodnota
                              </th>
                              <th className="bg-muted3 text-blue2 bold-wight p-3 border-0">
                                Skóre
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {d.auxiliaryCalculations.map(b => (
                              <tr key={b.criteriaId}>
                                <td className="p-3 ">
                                  <p className="h6 m-0">
                                    {' '}
                                    <DiamondIcon className="mr-2 mb-1" />
                                    {
                                      criteriaOffer.criteria.find(
                                        crit => crit.uuid === b.criteriaUuid
                                      ).name
                                    }
                                  </p>
                                </td>
                                <td className="p-3 ">
                                  <p className="text-muted col-lg-6 p-0 m-0">
                                    {b.value}
                                  </p>
                                </td>
                                <td className="p-3 ">
                                  <p className="text-muted col-lg-6 p-0 m-0">
                                    {Number.parseFloat(b.score).toFixed(2)}
                                  </p>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                          <tfoot className="bg-primary">
                            <tr>
                              <td className="p-3 ">
                                <p className="h6 text-white m-0">
                                  {' '}
                                  <ClubsIcon
                                    fill="white"
                                    className="mr-2 mb-1"
                                  />
                                  Kritérium
                                </p>
                              </td>
                              <td className="p-3 ">
                                <p className="text-muted col-lg-6"></p>
                              </td>
                              <td className="p-3 ">
                                <p className="text-white col-lg-6 p-0 m-0">
                                  {Number.parseFloat(d.score).toFixed(2)}
                                </p>
                              </td>
                            </tr>
                          </tfoot>
                        </Table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          ))
        : null}
    </div>
  );
};
export default AuctionHallRoomAdmin;
