import axios from 'axios';
import {toast} from 'react-toastify';
import settings from './settings';

import loadAuction from './loadAuction';
import ToastUtil from '../components/Toast/ToastUtil';
import {actionTypes as errorActionType} from '../features/modals';
import {actionTypes} from '../features/auction';
import Text from '../components/Toast/ToastTexts';

const editAuctionCriteria = (
  auctionId,
  request,
  token,
  dispatch,
  thenCallback,
  catchCallback,
  finallyCallback,
  editedCriterium,
  newCriterium
) => {
  axios
    .post(
      settings.SERVICE_URL + settings.SERVICES.EDIT_AUCTION_CRITERIA,
      request,
      {
        responseType: 'json',
        headers: {
          'Ela-Elc-Auth': token,
        },
      }
    )
    .then(response => {
      // console.log(response.data);
      // Volame reload roundov
      loadAuction(auctionId, token, dispatch, undefined, undefined, () => {
        if (typeof thenCallback === 'function') {
          thenCallback(response);
        }
        if (newCriterium) {
          toast.success(
            ToastUtil(
              Text.ADD_AUCTION_CRITERIA.TITLE,
              Text.ADD_AUCTION_CRITERIA.DESCRIPTION + editedCriterium.name
            )
          );
        } else {
          toast.success(
            ToastUtil(
              Text.EDIT_AUCTION_CRITERIA.TITLE,
              Text.EDIT_AUCTION_CRITERIA.DESCRIPTION + editedCriterium.name
            )
          );
        }
      });
    })
    .catch(error => {
      if (error.response != null && error.response.data != null) {
        const {data} = error.response;
        dispatch({
          type: errorActionType.MODAL_ERROR_SHOW,
          modal: {title: data.message, description: data.description},
        });
      }
      if (typeof catchCallback === 'function') {
        catchCallback(error);
      }
    })
    .finally(() => {
      dispatch({
        type: actionTypes.AUCTION_CRITERIA_HIDE_LOADING,
      });
      if (typeof finallyCallback === 'function') {
        finallyCallback();
      }
    });
};

export default editAuctionCriteria;
