import axios from 'axios';
import settings from './settings';
import {actionTypes} from '../features/token';
// import {actionTypes as errorActionType} from '../features/modals';

const login = (
  username,
  pass,
  dispatch,
  thenCallback,
  catchCallback,
  finallyCallback
) => {
  axios
    .post(
      settings.SERVICE_URL + settings.SERVICES.LOGIN,
      {
        login: username,
        password: pass,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    .then(response => {
      /* ulozit do cookies */
      dispatch({
        type: actionTypes.TOKEN_UPDATE,
        token: response.data.token,
      });

      if (typeof thenCallback === 'function') {
        thenCallback(response);
      }
    })
    .catch(error => {
      // handle error
      // console.log(error);
      dispatch({
        type: actionTypes.USER_ERROR,
      });
      // if (error.response.data) {
      //   const {data} = error.response;
      //   dispatch({
      //     type: errorActionType.MODAL_ERROR_SHOW,
      //     modal: {title: data.message, description: data.description},
      //   });
      // }
      if (typeof catchCallback === 'function') {
        catchCallback(error);
      }
    })
    .finally(() => {
      dispatch({
        type: actionTypes.TOKEN_HIDE_LOADING,
      });
      if (typeof finallyCallback === 'function') {
        finallyCallback();
      }
    });
};

export default login;
