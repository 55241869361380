/* eslint-disable no-underscore-dangle */
import React from 'react';

import Modal from 'react-bootstrap/Modal';
import {useSelector} from 'react-redux';
import {selectors} from '../../../features/offers';
import AuctionMulticriterialOffersForm from './AuctionMulticriterialOffersForm';
import InfoPopover from '../../Popover';
import InfoPopoverTexts from '../../Popover/InfoPopoverTexts';

function EditOffersModal(props) {
  const {id} = props;
  // const dispatch = useDispatch();

  // const isLoading = useSelector(selectors.getLoading);
  const editOffersInitialValues = useSelector(selectors.getSelectedOffers);
  return (
    <>
      {editOffersInitialValues == null ? null : (
        <Modal
          show={props.show}
          onHide={props.onHide}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton className="bg-light pt-5">
            <div className="text-center container pr-1">
              <Modal.Title
                as="h4"
                id="contained-modal-title-vcenter"
                className="font-weight-bolder"
              >
                Editovať ponuku
                <InfoPopover
                  placement="bottom"
                  text={InfoPopoverTexts.OFFERS.HEADER_NAME}
                  className="text-primary ml-2"
                />
              </Modal.Title>
            </div>
          </Modal.Header>
          <Modal.Body className="bg-light">
            {editOffersInitialValues != null ? (
              <AuctionMulticriterialOffersForm
                id={id}
                initialValues={editOffersInitialValues}
                offers={editOffersInitialValues}
                setModalShow={props.setModalShow}
              />
            ) : null}
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}

export default EditOffersModal;
