/* eslint-disable react/button-has-type */
import React, {useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import login from '../../../services/login';
import {selectors as selectorToken, actionTypes} from '../../../features/token';
import ALogo from '../../../images/logo.png';

const Login = () => {
  const dispatch = useDispatch();
  const usernameElement = useRef(null);
  const passwordElement = useRef(null);
  const errorMsg = useSelector(selectorToken.getErrorMessage);
  const isLoading = useSelector(selectorToken.isLoading);
  const handleClick = e => {
    e.preventDefault();
    dispatch({
      type: actionTypes.TOKEN_SHOW_LOADING,
    });
    // TODO: validacia

    const user = usernameElement.current.value;
    const pass = passwordElement.current.value;
    login(user, pass, dispatch);
  };

  return (
    <>
      <div className="authentication login-page">
        <div className="sign-in">
          <div className="row no-mrg-horizon">
            <div className="login-left col-md-6 col-xl-7 no-pdd-horizon d-none d-md-block">
              <div className="full-height"></div>
            </div>
            <div className="login-right col-md-6 col-xl-5 pl-0 pr-0 bg-white">
              <div>
                <div id="login" className="vertical-align">
                  <div className="pdd-top-60 pdd-horizon-50 font-size-15">
                    <img
                      src={ALogo}
                      alt="Logo"
                      width="210"
                      className="login-logo pdd-btm-30"
                    />
                    <h1>Vitajte v aukčnej sieni!</h1>
                    <p className="h1-desc pdd-btm-30">
                      Komplexný aukčný systém
                    </p>
                    <form className="p-0">
                      <div className="form-group">
                        <input
                          id="email"
                          ref={usernameElement}
                          type="text"
                          placeholder="Email"
                          maxLength="255"
                          className="form-control form-control-login "
                        />
                      </div>
                      <div className="form-group">
                        <input
                          id="password"
                          type="password"
                          ref={passwordElement}
                          maxLength="255"
                          placeholder="Heslo"
                          className="form-control form-control-login "
                        />
                      </div>
                      {errorMsg != null ? (
                        <p className="text-danger">{errorMsg}</p>
                      ) : (
                        ''
                      )}
                      <button
                        className="btn btn-lg btn-block btn-login"
                        onClick={handleClick}
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                          </Spinner>
                        ) : (
                          <span> Prihlásiť sa </span>
                        )}
                      </button>
                      <div className="pdd-top-15 font-size-18"></div>
                    </form>
                  </div>
                </div>
                <div className="login-footer font-size-18 pdd-horizon-30 pdd-top-60"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
