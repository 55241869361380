/* eslint-disable no-underscore-dangle */
import ADDOREDITROUND_FIELDNAMES from '../components/Modals/Round/AddOrEditRoundFormFieldNames';
import EDITORGANIZATION_FIELDNAMES from '../components/Modals/Users/EditOrganizationFormFieldNames';
import DEFINEAUCTIONSETTINGS_FIELDNAMES from '../components/Pages/Settings/Auction/AuctionFormFieldNames';
import ORDERS_FIELDNAMES from '../components/Pages/Orders/OrdersFormFieldNames';
import EvalEnum from '../components/enums/EvalEnum';
import UnitEnum from '../components/enums/UnitEnum';

export const createEditAuctionRoundRequest = data => ({
  auctionRoundId: data.id,
  auctionRoundName: data[ADDOREDITROUND_FIELDNAMES.AUCTION_ROUND_NAME],
  duration: data[ADDOREDITROUND_FIELDNAMES.DURATION],
  renewDuration: data[ADDOREDITROUND_FIELDNAMES.RENEWDURATION],
  renewType: data[ADDOREDITROUND_FIELDNAMES.RENEWTYPE],
  roundExtensionCaseEnum: data[ADDOREDITROUND_FIELDNAMES.ROUND_EXTENSION_CASE],
  setting: {
    admin_help_calc_show: data.auctionRoundSettings.admin_help_calc_show,
    allow_beat_first: data.auctionRoundSettings.allow_beat_first,
    allow_beat_other: data.auctionRoundSettings.allow_beat_other,
    allow_beat_top_item_val: data.auctionRoundSettings.allow_beat_top_item_val,
    allow_bigger_offer: data.auctionRoundSettings.allow_bigger_offer,
    auctionRoundStartTime: new Date(
      data.auctionRoundSettings.auctionRoundStartTime
    ),
    best_opt_offer_show: data.auctionRoundSettings.best_opt_offer_show,
    null_time_at_renewal: data.auctionRoundSettings.null_time_at_renewal,
    renewNullCurrentTime: data.auctionRoundSettings.renewNullCurrentTime,
    show_contractor_inf: data.auctionRoundSettings.show_contractor_inf,
    show_str_price_collname: data.auctionRoundSettings.show_str_price_collname,
    show_user_offer_end_prc: data.auctionRoundSettings.show_user_offer_end_prc,
    show_user_top_end_prc: data.auctionRoundSettings.show_user_top_end_prc,
    show_user_top_item_prc: data.auctionRoundSettings.show_user_top_item_prc,
    start_price_show: data.auctionRoundSettings.start_price_show,
    user_help_calc_show: data.auctionRoundSettings.user_help_calc_show,
    user_help_ord_calc_show: data.auctionRoundSettings.user_help_ord_calc_show,
    user_item_order_show: data.auctionRoundSettings.user_item_order_show,
    user_order_show: data.auctionRoundSettings.user_order_show,
    user_top_coll_off_show: data.auctionRoundSettings.user_top_coll_off_show,
    user_top_help_calc_show: data.auctionRoundSettings.user_top_help_calc_show,
  },
  timeForDurationRaise: data[ADDOREDITROUND_FIELDNAMES.TIME_FOR_DURATION_RAISE],
});

export const createAddAuctionRoundRequest = (data, AddAuctionId) => ({
  auctionId: AddAuctionId,
  auctionRoundName: data[ADDOREDITROUND_FIELDNAMES.AUCTION_ROUND_NAME],
  duration: data[ADDOREDITROUND_FIELDNAMES.DURATION],
  renewDuration: data[ADDOREDITROUND_FIELDNAMES.RENEWDURATION],
  renewType: data[ADDOREDITROUND_FIELDNAMES.RENEWTYPE],
  roundExtensionCaseEnum: data[ADDOREDITROUND_FIELDNAMES.ROUND_EXTENSION_CASE],
  setting: {
    admin_help_calc_show: data.auctionRoundSettings.admin_help_calc_show,
    allow_beat_first: data.auctionRoundSettings.allow_beat_first,
    allow_beat_other: data.auctionRoundSettings.allow_beat_other,
    allow_beat_top_item_val: data.auctionRoundSettings.allow_beat_top_item_val,
    allow_bigger_offer: data.auctionRoundSettings.allow_bigger_offer,
    auctionRoundStartTime: new Date(
      data.auctionRoundSettings.auctionRoundStartTime
    ),
    best_opt_offer_show: data.auctionRoundSettings.best_opt_offer_show,
    null_time_at_renewal: data.auctionRoundSettings.null_time_at_renewal,
    renewNullCurrentTime: data.auctionRoundSettings.renewNullCurrentTime,
    show_contractor_inf: data.auctionRoundSettings.show_contractor_inf,
    show_str_price_collname: data.auctionRoundSettings.show_str_price_collname,
    show_user_offer_end_prc: data.auctionRoundSettings.show_user_offer_end_prc,
    show_user_top_end_prc: data.auctionRoundSettings.show_user_top_end_prc,
    show_user_top_item_prc: data.auctionRoundSettings.show_user_top_item_prc,
    start_price_show: data.auctionRoundSettings.start_price_show,
    user_help_calc_show: data.auctionRoundSettings.user_help_calc_show,
    user_help_ord_calc_show: data.auctionRoundSettings.user_help_ord_calc_show,
    user_item_order_show: data.auctionRoundSettings.user_item_order_show,
    user_order_show: data.auctionRoundSettings.user_order_show,
    user_top_coll_off_show: data.auctionRoundSettings.user_top_coll_off_show,
    user_top_help_calc_show: data.auctionRoundSettings.user_top_help_calc_show,
  },
  timeForDurationRaise: data[ADDOREDITROUND_FIELDNAMES.TIME_FOR_DURATION_RAISE],
});

export const createEditAuctionItemRequest = (item, auction) => {
  const formItem = {...item};
  formItem._criteria = [];
  if (item.criteria != null) {
    item.criteria.forEach(criterium => {
      formItem._criteria.push(criterium);
    });
  }
  if (
    item.unit ===
    Object.entries(UnitEnum).find(e => e[1] === UnitEnum.CUSTOM)[0]
  ) {
    formItem.unit = item.custom_unit;
  }
  return {
    auctionId: auction.id,
    item: formItem,
  };
};

export const createAddAuctionItemRequest = (item, auction) => {
  const formItem = {...item};
  formItem._criteria = [];
  if (item.criteria != null) {
    item.criteria.forEach(criterium => {
      formItem._criteria.push(criterium);
    });
  }
  if (
    item.unit ===
    Object.entries(UnitEnum).find(e => e[1] === UnitEnum.CUSTOM)[0]
  ) {
    formItem.unit = item.custom_unit;
  }
  return {
    auctionId: auction.id,
    item: formItem,
  };
};

export const createDeleteAuctionCriteriaRequest = (criteriaId, auction) => {
  auction.criteria.forEach(criteriaIter => {
    if (criteriaIter.id === criteriaId) {
      auction.criteria.splice(auction.criteria.indexOf(criteriaIter), 1);
    }
  });
  return {
    auctionId: auction.id,
    criteria: auction.criteria,
  };
};

export const createAddAuctionCriteriaRequest = (criterium, auction) => {
  const formCriterium = {...criterium};
  formCriterium._item = [];
  if (criterium.item != null) {
    criterium.item.forEach(item => {
      formCriterium._item.push(item);
    });
  }
  if (
    criterium.unit ===
    Object.entries(UnitEnum).find(e => e[1] === UnitEnum.CUSTOM)[0]
  ) {
    formCriterium.unit = criterium.custom_unit;
  }
  return {
    auctionId: auction.id,
    criteria: formCriterium,
  };
};

export const createEditAuctionCriteriaRequest = (criterium, auction) => {
  const formCriterium = {...criterium};
  formCriterium._item = [];
  if (criterium.item != null) {
    criterium.item.forEach(item => {
      formCriterium._item.push(item);
    });
  }
  if (
    criterium.unit ===
    Object.entries(UnitEnum).find(e => e[1] === UnitEnum.CUSTOM)[0]
  ) {
    formCriterium.unit = criterium.custom_unit;
  }
  return {
    auctionId: auction.id,
    criteria: formCriterium,
  };
};

export const createDeleteAuctionItemRequest = (itemId, auction) => {
  auction.items.forEach(itemIter => {
    if (itemIter.id === itemId) {
      auction.items.splice(auction.items.indexOf(itemIter), 1);
    }
  });
  return {
    auctionId: auction.id,
    items: auction.items,
  };
};

export const createEditAuctionRequest = (data, auction) => ({
  auctionId: auction.id,
  commissionMembers: auction.commissionMembers,
  cpvCode: auction.cpvCode,
  cpvCodes: auction.cpvCodes,
  criteria: auction.criteria,
  currency: auction.currency,
  // decimalCount: auction.decimalCount,
  estimatedValue: auction.estimatedValue,
  evalCriterium: auction.evalCriterium,
  items: auction.items,
  name: data.name,
  organizations: [auction.organizations],
  type: auction.type,
});

export const createEditOrganizationRequest = (
  data,
  initialState,
  AddAuctionId
) => ({
  auctionId: AddAuctionId,
  city: data[EDITORGANIZATION_FIELDNAMES.CITY],
  companyId: data[EDITORGANIZATION_FIELDNAMES.COMPANYID],
  country: data[EDITORGANIZATION_FIELDNAMES.COUNTRY],
  uuid: initialState.uuid,
  name: data[EDITORGANIZATION_FIELDNAMES.NAME],
  id: initialState.id,
  street: data[EDITORGANIZATION_FIELDNAMES.STREET],
  streetNumber: data[EDITORGANIZATION_FIELDNAMES.STREETNUMBER],
  vatId: data[EDITORGANIZATION_FIELDNAMES.VATID],
  taxId: data[EDITORGANIZATION_FIELDNAMES.TAXID],
  participants: initialState.participants,
  postCode: data[EDITORGANIZATION_FIELDNAMES.POSTCODE],
});

export const createDefineAuctionSettingsRequest = (
  data,
  addAuctionId,
  auctionSettings
) => ({
  showWinningOffers: data[DEFINEAUCTIONSETTINGS_FIELDNAMES.SHOWWINNINGOFFERS],
  auctionId: addAuctionId,
  cpvCode: data[DEFINEAUCTIONSETTINGS_FIELDNAMES.CPVCODE],
  cpvCodes: data[DEFINEAUCTIONSETTINGS_FIELDNAMES.CPVCODES],
  currency: data[DEFINEAUCTIONSETTINGS_FIELDNAMES.CURRENCY],
  // decimalCount: auctionSettings.decimalCount,
  estimatedValue: data[DEFINEAUCTIONSETTINGS_FIELDNAMES.ESTIMATEDVALUE],
  evalCriterium: data[DEFINEAUCTIONSETTINGS_FIELDNAMES.EVALCRITERIUM],
  evalCriteriumText: EvalEnum[data.evalCriterium],
  // maxDecreaseValue_fiat:
  //   data[DEFINEAUCTIONSETTINGS_FIELDNAMES.DECREASEVALUE] ===
  //   Object.entries(DecreaseValueEnum).find(
  //     e => e[1] === DecreaseValueEnum.EUR
  //   )[0]
  //     ? data[DEFINEAUCTIONSETTINGS_FIELDNAMES.MAXDECREASEVALUE_FIAT]
  //     : 0,
  // maxDecreaseValue_percentage:
  //   data[DEFINEAUCTIONSETTINGS_FIELDNAMES.DECREASEVALUE] ===
  //   Object.entries(DecreaseValueEnum).find(
  //     e => e[1] === DecreaseValueEnum.PERCENTAGE
  //   )[0]
  //     ? data[DEFINEAUCTIONSETTINGS_FIELDNAMES.MAXDECREASEVALUE_PERCENTAGE]
  //     : 0,
  // minDecreaseValue_fiat:
  //   data[DEFINEAUCTIONSETTINGS_FIELDNAMES.DECREASEVALUE] ===
  //   Object.entries(DecreaseValueEnum).find(
  //     e => e[1] === DecreaseValueEnum.EUR
  //   )[0]
  //     ? data[DEFINEAUCTIONSETTINGS_FIELDNAMES.MINDECREASEVALUE_FIAT]
  //     : 0,
  // minDecreaseValue_percentage:
  //   data[DEFINEAUCTIONSETTINGS_FIELDNAMES.DECREASEVALUE] ===
  //   Object.entries(DecreaseValueEnum).find(
  //     e => e[1] === DecreaseValueEnum.PERCENTAGE
  //   )[0]
  //     ? data[DEFINEAUCTIONSETTINGS_FIELDNAMES.MINDECREASEVALUE_PERCENTAGE]
  //     : 0,
  name: auctionSettings.name,
  token: 'string',
});

export const createOrdersRequest = (data, addAuctionId, auctionSettings) => ({
  applyToAllItems: auctionSettings.applyToAllItems,
  auctionId: addAuctionId,
  cpvCode: auctionSettings.cpvCode,
  cpvCodes: auctionSettings.cpvCodes,
  currency: auctionSettings.currency,
  // decimalCount: auctionSettings.decimalCount,
  estimatedValue: auctionSettings.estimatedValue,
  evalCriterium: auctionSettings.evalCriterium,
  evalCriteriumText: auctionSettings.evalCriterium_text,
  // maxDecreaseValue_fiat: auctionSettings.maxDecreaseValue_fiat,
  // maxDecreaseValue_percentage: auctionSettings.maxDecreaseValue_percentage,
  // minDecreaseValue_fiat: auctionSettings.minDecreaseValue_fiat,
  // minDecreaseValue_percentage: auctionSettings.minDecreaseValue_percentage,
  name: data[ORDERS_FIELDNAMES.NAME],
  token: 'string',
});

export const addOfferRequest = (data, addAuctionId, addOrganizationId) => {
  const crieriaItemValues = [];
  const criteriaOfferValues = [];
  data.criteriaWsResponse.forEach(value => {
    crieriaItemValues.push({id: value.id, value: value.value});
  });

  data.criteriaPriceOffers.forEach(value => {
    criteriaOfferValues.push({id: value.id, price: Number(value.value)});
  });
  return {
    auctionId: addAuctionId,
    criteriaValues: crieriaItemValues,
    prices: criteriaOfferValues,
    organizationId: addOrganizationId,
  };
};

export const editOfferRequest = (data, addAuctionId, addOrganizationId) => {
  const crieriaItemValues = [];
  const criteriaOfferValues = [];
  data.criteriaOffers.forEach(value => {
    crieriaItemValues.push({id: value.criteriaUuid, value: value.value});
  });

  data.priceOffers.forEach(value => {
    criteriaOfferValues.push({
      id: Number(value.id),
      price: Number(value.value),
    });
  });
  return {
    auctionId: addAuctionId,
    criteriaValues: crieriaItemValues,
    prices: criteriaOfferValues,
    organizationId: addOrganizationId,
  };
};

export const editMenuStateRequest = (data, addAuctionId, keyId) => {
  data.forEach(element => {
    if (element.key === keyId) {
      element.menuStateEnum = 'VISITED';
    }
  });

  return {
    auctionId: addAuctionId,
    utMenuStateBean: data,
  };
};
