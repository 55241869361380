import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Table from 'react-bootstrap/Table';
// import ButtonGroup from 'react-bootstrap/ButtonGroup';
import {useParams} from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import loadOrganisations from '../../../services/loadOrganisations';
import loadOffers from '../../../services/loadOffers';
import {selectors as selectorUAD} from '../../../features/userAuctionData';
import InfoPopover from '../../Popover';
import InfoPopoverTexts from '../../Popover/InfoPopoverTexts';
import {selectors} from '../../../features/users';
import {selectors as selectorToken} from '../../../features/token';
// import {selectors as selectorOffers} from '../../../features/offers';
import {EditOrganizationButton} from '../../Modals/Users';
import {EditOffersButton} from '../../Modals/Offers';
import AuctioStarted from '../AuctionStarted';
import RoundLifeCycleEnum from '../../enums/RoundLifeCycleEnum';

const Users = () => {
  const dispatch = useDispatch();
  const auctionData = useSelector(selectorUAD.getValues);
  const values = useSelector(selectors.getValues);
  // const offers = useSelector(state => state.offers);
  // const loading = useSelector(selectors.getLoading);
  /* TODO zmenit potom */
  const token = useSelector(selectorToken.getToken);
  const {auctionId} = useParams();
  useEffect(() => {
    loadOrganisations(auctionId, token, dispatch);
    loadOffers(auctionId, token, dispatch);
  }, [auctionId, dispatch, token]);
  return (
    <>
      <div className="bg-white col-lg-12 mt-5 mb-5 pb-5 rounded pl-0 pr-0">
        <div className="border-bottom">
          <div className="col-lg-12 p-0">
            <h1 className="h4 font-weight-bold p-5 m-0">
              Uchádzači{' '}
              <InfoPopover
                placement="bottom"
                text={InfoPopoverTexts.ORGANIZATION.HEADER_NAME}
              />
            </h1>
          </div>
        </div>
        <AuctioStarted className="pb-4" />
        {auctionData != null &&
        auctionData.roundEnum !== RoundLifeCycleEnum.PREPARATION_PHASE ? (
          <div className="col-lg-12 pb-4"></div>
        ) : null}
        {values != null ? (
          <div className="table-container">
            <Table striped hover responsive>
              <thead className="bg-white">
                <tr className="bg-white text-blue">
                  <th>Obchodné meno</th>
                  <th>IČO</th>
                  <th>Sídlo</th>
                  <th>Krajina</th>
                  <th>{'\u00A0'}</th>
                </tr>
              </thead>
              <tbody>
                {values != null
                  ? values.organizations.map(d => {
                      return (
                        <tr key={d.id}>
                          <td>{d.name}</td>
                          <td>{d.companyId}</td>
                          <td>{`${d.street} ${d.streetNumber} ,${d.postCode} ${d.city}`}</td>
                          <td>{d.country}</td>
                          <td>
                            <div className="row text-blue2 float-right">
                              {/* <ButtonGroup> */}
                              {auctionData != null &&
                              auctionData.roundEnum ===
                                RoundLifeCycleEnum.PREPARATION_PHASE ? (
                                <EditOffersButton
                                  id={d.uuid}
                                  className="col-lg-6"
                                />
                              ) : null}
                              <EditOrganizationButton
                                id={d.id}
                                className="col-lg-6 "
                              />
                              {/* </ButtonGroup> */}
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </Table>
          </div>
        ) : (
          <div className="p-5">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        )}
      </div>
    </>
  );
};

export default Users;
