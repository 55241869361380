import React from 'react';
import {useSelector} from 'react-redux';
import {selectors as selectorUAD} from '../../../features/userAuctionData';
import RoundLifeCycleEnum from '../../enums/RoundLifeCycleEnum';

function AuctionStarted() {
  const auctionData = useSelector(selectorUAD.getValues);
  if (auctionData != null) {
    switch (auctionData.roundEnum) {
      case RoundLifeCycleEnum.STARTED_PHASE:
        return (
          <div className="col-lg-12 p-0">
            <h5 className="h6 text-danger font-weight-bold pl-5 pt-3 pr-5 m-0">
              Aukcia spustená, je možné aukciu zastaviť a upraviť.
            </h5>
          </div>
        );
      case RoundLifeCycleEnum.ACTIVE_PHASE:
        return (
          <div className="col-lg-12 p-0">
            <h5 className="h6 text-danger font-weight-bold pl-5 pt-3 pr-5 m-0">
              Aukcia práve prebieha, nie je možné aukciu upraviť.
            </h5>
          </div>
        );
      case RoundLifeCycleEnum.END_PHASE:
        return (
          <div className="col-lg-12 p-0">
            <h5 className="h6 text-danger font-weight-bold pl-5 pt-3 pr-5 m-0">
              Aukcia je ukončená, nie je možné robiť žiadne zmeny.
            </h5>
          </div>
        );
      case RoundLifeCycleEnum.STOPPED_PHASE:
        return (
          <div className="col-lg-12 p-0">
            <h5 className="h6 text-danger font-weight-bold pl-5 pt-3 pr-5 m-0">
              Aukcia bola zastavená, nie je možné robiť žiadne zmeny.
            </h5>
          </div>
        );
      default:
        return null;
    }
  } else {
    return null;
  }
}

export default AuctionStarted;
