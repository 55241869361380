import React from 'react';

function HammerIcon(props) {
  return (
    <svg
      {...props}
      version="1.1"
      id="IconsRepoEditor"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 31 31"
      xmlSpace="preserve"
      width={props.width != null ? props.width : '31px'}
      height={props.height != null ? props.height : '31px'}
      fill="none"
      strokeWidth="0"
    >
      <path
        d="M17.0728 21.9177H1.81625V25.5502H0V30.9989H18.889V25.5502H17.0728V21.9177ZM3.63251 23.7339H15.2565V25.5502H3.63251V23.7339ZM17.0728 29.1827H1.81625V27.3664H17.0728V29.1827Z"
        fill={props.fill != null ? props.fill : '#222831'}
      />
      <path
        d="M19.3516 11.5586L23.2045 7.7057L15.4988 0L3.94019 11.5586L11.6459 19.2643L15.4988 15.4115L27.1472 27.0599L31.0001 23.207L19.3516 11.5586ZM14.2144 14.1272L9.07728 8.99003L12.9302 5.13715L18.0673 10.2743L14.2144 14.1272ZM20.6359 7.70576L19.3516 8.99003L14.2145 3.85288L15.4988 2.56861L20.6359 7.70576ZM6.50879 11.5586L7.79306 10.2743L12.9302 15.4115L11.6459 16.6957L6.50879 11.5586ZM16.783 14.1272L18.0673 12.8429L28.4314 23.2071L27.1471 24.4913L16.783 14.1272Z"
        fill={props.fill != null ? props.fill : '#222831'}
      />
    </svg>
  );
}
export default HammerIcon;
