import React from 'react';
import Form from 'react-bootstrap/Form';
import {useSelector} from 'react-redux';
import {DateTimePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import dateUtils from '@date-io/date-fns';
import TextField from '@material-ui/core/TextField';
import skLocale from 'date-fns/locale/sk';
import Autocomplete, {
  createFilterOptions as autocompleteCreateFilterOptions,
} from '@material-ui/lab/Autocomplete';
import className from 'classnames';
import {selectors as selectorUAD} from '../../features/userAuctionData';
import InfoPopover from '../Popover';
import RoundLifeCycleEnum from '../enums/RoundLifeCycleEnum';
/**
 * Helper components to combine React Bootstrap form fields with redux-form
 *
 * https://stackoverflow.com/questions/43924077/how-to-use-redux-form-with-react-bootstrap
 */
export const BootstrapFormControl = props => {
  const auctionData = useSelector(selectorUAD.getValues);
  const {
    input,
    type,
    disabled,
    meta: {touched, error, warning},
  } = props;
  const started = !!(
    auctionData != null &&
    auctionData.roundEnum !== RoundLifeCycleEnum.PREPARATION_PHASE
  );
  return (
    <>
      <InfoPopover
        text={props.text}
        placement="top"
        style={{marginTop: '-0.25em'}}
      />
      <Form.Control
        {...input}
        disabled={auctionData != null && !disabled ? started : disabled}
        type={type}
        className={className({
          'p-4 form-shadow': true,
          'border-danger': touched && error,
          'border-warning': touched && warning,
        })}
      />
      {touched &&
        ((error && <p className="pt-2 text-danger">{error}</p>) ||
          (warning && <p className="pt-2 text-warning">{warning}</p>))}
    </>
  );
};
export const BootstrapFormControlWithoutPopover = props => {
  const auctionData = useSelector(selectorUAD.getValues);
  const {
    input,
    type,
    disabled,
    meta: {touched, error, warning},
  } = props;
  const started = !!(
    auctionData != null &&
    auctionData.roundEnum !== RoundLifeCycleEnum.PREPARATION_PHASE
  );
  return (
    <>
      <Form.Control
        {...input}
        disabled={auctionData != null && !disabled ? started : disabled}
        type={type}
        className={className({
          'p-4 form-shadow': true,
          'border-danger': touched && error,
          'border-warning': touched && warning,
        })}
      />
      {touched &&
        ((error && <p className="pt-2 text-danger">{error}</p>) ||
          (warning && <p className="pt-2 text-warning">{warning}</p>))}
    </>
  );
};
export const BootstrapFormInput = props => {
  const auctionData = useSelector(selectorUAD.getValues);
  const started = !!(
    auctionData != null &&
    auctionData.roundEnum !== RoundLifeCycleEnum.PREPARATION_PHASE
  );
  const otherProps = {...props};
  delete otherProps.InputProps;
  delete otherProps.helperText;
  delete otherProps.error;

  return (
    <Form.Control
      disabled={
        auctionData != null && !props.disabled ? started : props.disabled
      }
      {...otherProps}
      onChange={() => {}}
      className="p-4 pl-3 form-shadow"
    />
  );
};

export const BootstrapDropDownControl = props => {
  const auctionData = useSelector(selectorUAD.getValues);
  const started = !!(
    auctionData != null &&
    auctionData.roundEnum !== RoundLifeCycleEnum.PREPARATION_PHASE
  );
  return (
    <>
      <InfoPopover
        text={props.text}
        placement="top"
        style={{marginTop: '-0.25em'}}
      />
      <Form.Control
        {...props}
        disabled={
          auctionData != null && !props.disabled ? started : props.disabled
        }
        value={props.input.value}
        onChange={props.input.onChange}
        className={className({
          'p-2 pl-3 form-shadow': true,
          'border-danger': props.meta.touched && props.meta.error,
          'border-warning': props.meta.touched && props.meta.warning,
        })}
      />
      {props.meta.touched &&
        ((props.meta.error && (
          <p className="pt-2 text-danger">{props.meta.error}</p>
        )) ||
          (props.meta.warning && (
            <p className="pt-2 text-warning">{props.meta.warning}</p>
          )))}
    </>
  );
};

export const BootstrapFormCheck = props => {
  const auctionData = useSelector(selectorUAD.getValues);
  const started = !!(
    auctionData != null &&
    auctionData.roundEnum !== RoundLifeCycleEnum.PREPARATION_PHASE
  );
  return (
    <>
      <Form.Check
        className="col-lg-10"
        {...props}
        disabled={
          auctionData != null && !props.disabled ? started : props.disabled
        }
        checked={props.input.value}
        onChange={props.input.onChange}
      >
        {props.children}
      </Form.Check>
      <InfoPopover
        className="float-right"
        text={props.text}
        placement="top"
        style={{marginTop: '-1.85em'}}
      />
    </>
  );
};

const CPVfilterOptions = autocompleteCreateFilterOptions({
  limit: 100,
});
export const CPVcodeSingleMaterialAutocomplete = props => {
  const auctionData = useSelector(selectorUAD.getValues);
  const started = !!(
    auctionData != null &&
    auctionData.roundEnum !== RoundLifeCycleEnum.PREPARATION_PHASE
  );
  return (
    <>
      <InfoPopover
        text={props.text}
        placement="top"
        style={{marginTop: '-0.25em'}}
      />
      <Autocomplete
        {...props}
        disabled={
          auctionData != null && !props.disabled ? started : props.disabled
        }
        filterOptions={CPVfilterOptions}
        value={props.options.find(opt =>
          opt ? opt.cpv === props.input.value : false
        )}
        className="form-shadow"
        onChange={(event, value) => {
          props.input.onChange(value ? value.cpv : null);
        }}
        renderInput={params => <TextField {...params} variant="outlined" />}
      />
    </>
  );
};

export const CPVcodeMultipleMaterialAutocomplete = props => {
  const auctionData = useSelector(selectorUAD.getValues);
  const started = !!(
    auctionData != null &&
    auctionData.roundEnum !== RoundLifeCycleEnum.PREPARATION_PHASE
  );
  return (
    <>
      <InfoPopover
        text={props.text}
        placement="top"
        style={{marginTop: '-0.25em'}}
      />
      <Autocomplete
        {...props}
        disabled={
          auctionData != null && !props.disabled ? started : props.disabled
        }
        filterOptions={CPVfilterOptions}
        value={props.options.filter(opt =>
          opt ? props.input.value.find(v => v === opt.cpv) : false
        )}
        className="form-shadow"
        onChange={(event, array) => {
          props.input.onChange(array.map(v => v.cpv));
        }}
        renderInput={params => <TextField {...params} variant="outlined" />}
      />
    </>
  );
};

export const ItemsMultipleMaterialAutocomplete = props => {
  const auctionData = useSelector(selectorUAD.getValues);
  const started = !!(
    auctionData != null &&
    auctionData.roundEnum !== RoundLifeCycleEnum.PREPARATION_PHASE
  );
  return (
    <>
      <InfoPopover
        text={props.text}
        placement="top"
        style={{marginTop: '-0.25em'}}
      />
      <Autocomplete
        {...props}
        disabled={
          auctionData != null && !props.disabled ? started : props.disabled
        }
        filterOptions={CPVfilterOptions}
        value={props.options.filter(opt =>
          opt ? props.input.value.find(v => v === opt.id) : false
        )}
        className="form-shadow"
        onChange={(event, array) => {
          props.input.onChange(array.map(v => v.id));
        }}
        renderInput={params => <TextField {...params} variant="outlined" />}
      />
    </>
  );
};

export const CriteriaMultipleMaterialAutocomplete = props => {
  const auctionData = useSelector(selectorUAD.getValues);
  const started = !!(
    auctionData != null &&
    auctionData.roundEnum !== RoundLifeCycleEnum.PREPARATION_PHASE
  );
  return (
    <>
      <InfoPopover
        text={props.text}
        placement="top"
        style={{marginTop: '-0.25em'}}
      />
      <Autocomplete
        {...props}
        disabled={
          auctionData != null && !props.disabled ? started : props.disabled
        }
        filterOptions={CPVfilterOptions}
        value={props.options.filter(opt =>
          opt ? props.input.value.find(v => v === opt.id) : false
        )}
        className="form-shadow"
        onChange={(event, array) => {
          props.input.onChange(array.map(v => v.id));
        }}
        renderInput={params => <TextField {...params} variant="outlined" />}
      />
    </>
  );
};

export const BootstrapFormDateTimePicker = props => {
  const auctionData = useSelector(selectorUAD.getValues);
  const started = !!(
    auctionData != null &&
    auctionData.roundEnum !== RoundLifeCycleEnum.PREPARATION_PHASE
  );
  return (
    <MuiPickersUtilsProvider utils={dateUtils} locale={skLocale}>
      <DateTimePicker
        disabled={
          auctionData != null && !props.disabled ? started : props.disabled
        }
        variant="dialog"
        inputVariant="outlined"
        minDate={new Date()}
        ampm={false}
        value={new Date(props.input.value)}
        onChange={props.input.onChange}
        autoOk
        TextFieldComponent={BootstrapFormInput}
        format="dd.MM.yyyy HH:mm"
        className="col-lg-12"
      />
    </MuiPickersUtilsProvider>
  );
};
