import React from 'react';
import {Link, useRouteMatch, useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import BookmarksOutlinedIcon from '@material-ui/icons/BookmarksOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import {selectors as selectorWS} from '../../features/websocket';
// import settings from '../../services/settings';

const SideBarAHAdmin = () => {
  const match = useRouteMatch('/:auctionId');
  const {auctionId} = match.params;
  const criteriaOffer = useSelector(selectorWS.getValues);
  const location = useLocation();
  return (
    <Navbar variant="transparent" bg="transparent" className="p-0">
      <div className="col-md-12 no-gutters pl-0 pr-0">
        {criteriaOffer != null ? (
          <>
            <Nav as="div" className="flex-column" activeKey={location.pathname}>
              <Nav className="bg-blue2 text-center">
                <Nav.Item className="col-lg-12 col-md-12  pt-3 pb-3">
                  <span className="text-nowrap text-white h6">
                    <SettingsOutlinedIcon className="mr-2" />
                    Aukčná sieň
                  </span>
                </Nav.Item>
              </Nav>
              <Nav.Item>
                {/* eventKey kvôli visited */}
                <Nav.Link
                  as={Link}
                  eventKey={`/${auctionId}/auction_hall/owner`}
                  to={`/${auctionId}/auction_hall/owner`}
                  className="text-blue col-lg-12 col-md-12 pr-3"
                >
                  <div className="pb-4">
                    <p className="pl-5 mb-0 navbar-fix-width float-right">
                      <ListAltOutlinedIcon className="text-white mr-2" />
                      Dashboard
                    </p>
                  </div>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                {/* eventKey kvôli visited */}
                <Nav.Link
                  as={Link}
                  eventKey={`/${auctionId}/auction_hall/bids`}
                  to={`/${auctionId}/auction_hall/bids`}
                  className="text-blue col-lg-12 col-md-12 pr-3"
                >
                  <div className="pb-4">
                    <p className="pl-5 mb-0 navbar-fix-width float-right">
                      <BookmarksOutlinedIcon className="text-white mr-2" />
                      Historia bidovania
                    </p>
                  </div>
                </Nav.Link>
              </Nav.Item>
              {/* <Nav.Item>
                <Nav.Link
                  as={Link}
                  eventKey={`/${auctionId}/auction_hall/users`}
                  to={`/${auctionId}/auction_hall/users`}
                  className="text-blue col-lg-12 col-md-12 pr-3"
                >
                  <div className="pb-4">
                    <p className="pl-5 mb-0 navbar-fix-width float-right">
                      <PeopleAltOutlinedIcon className="text-white mr-2" />
                      Uchádzači
                    </p>
                  </div>
                </Nav.Link>
              </Nav.Item> */}
              <div
                fixed="bottom"
                as="ul"
                className="flex-column mb-5 mt-3 menu-div version-border pt-3 pb-3"
              >
                <div className="bg-blue2 text-center">
                  <Nav.Item className="col-lg-12 col-md-12  pt-3 pb-3">
                    <span className="text-nowrap text-white h6">
                      <SettingsOutlinedIcon className="mr-2" />
                      Administračné funkcie
                    </span>
                  </Nav.Item>
                </div>
                <Nav.Item>
                  <Nav.Link
                    as={Link}
                    eventKey={`/${auctionId}/auction_hall/audit_log`}
                    to={`/${auctionId}/auction_hall/audit_log`}
                    className="text-blue col-lg-12 col-md-12 pr-3"
                  >
                    <div className="pb-4">
                      <p className="pl-5 mb-0 navbar-fix-width float-right">
                        <FormatListBulletedIcon className="text-white mr-2" />
                        Audit log
                      </p>
                    </div>
                  </Nav.Link>
                </Nav.Item>
              </div>
            </Nav>
          </>
        ) : (
          ''
        )}
      </div>
    </Navbar>
  );
};
export default SideBarAHAdmin;
