/* eslint-disable no-param-reassign */
import React, {useState} from 'react';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import {useParams} from 'react-router-dom';
import {reduxForm, Field, setSubmitFailed} from 'redux-form';
import {useSelector, useDispatch} from 'react-redux';
import RenewTypeEnum from '../../../enums/RenewTypeEnum';
import RoundLifeCycleEnum from '../../../enums/RoundLifeCycleEnum';
import ErrorFormText from '../../../Modals/Error/ErrorFormText';
import {
  BootstrapFormControl,
  BootstrapFormCheck,
  BootstrapFormDateTimePicker,
  BootstrapDropDownControl,
} from '../../../ReduxBootstrapFields';
import {actionTypes} from '../../../../features/auctionRounds';
import editAuctionRound from '../../../../services/editAuctionRound';
import {requestDTO} from '../../../../services';
import {selectors as selectorToken} from '../../../../features/token';

import FIELD_NAMES from '../../../Modals/Round/AddOrEditRoundFormFieldNames';
import InfoPopoverText from '../../../Popover/InfoPopoverTexts';
import SubmitFormValidator, {
  required,
  normalizeLength,
  normalizePositiveOrZeroIntegerKeepEmpty,
  normalizePositiveFloatKeepEmpty,
} from '../../../Utils/SubmitFormValidator';
import {selectors as selectorUAD} from '../../../../features/userAuctionData';

export const TYPES = {
  EDIT: 'EDIT',
  ADD: 'ADD',
};
export const FORM_NAME = 'AddOrEditRoundForm';

const warn = values => {
  const warnings = {};

  return warnings;
};

function AddOrEditRoundForm(props) {
  const {auctionId} = useParams();
  // const {id} = props;
  const dispatch = useDispatch();

  // const isLoading = useSelector(selectors.getLoading);
  const form = useSelector(state => state.form[FORM_NAME]);
  const token = useSelector(selectorToken.getToken);
  // const [isDivVisible, setDivVisible] = useState(false);
  const [isDivVisible] = useState(false);
  const auctionData = useSelector(selectorUAD.getValues);
  const [isBadDateVisible, setBadDateVisible] = useState(false);

  // const clickhandler = () => {
  //   setDivVisible(state => !state);
  // };

  // const dispatchFormErrors = errors => {
  //   dispatch(updateSyncErrors(FORM_NAME, errors));
  // };

  const handleClick = e => {
    e.preventDefault();

    if (SubmitFormValidator(form)) {
      dispatch(setSubmitFailed(FORM_NAME, ...Object.keys(form.syncErrors)));
      return;
    }
    if (form.values.auctionRoundSettings.auctionRoundStartTime < new Date()) {
      setBadDateVisible(true);
      return;
    }
    dispatch({
      type: actionTypes.ROUNDS_SHOW_LOADING,
    });
    editAuctionRound(
      auctionId,
      requestDTO.createEditAuctionRoundRequest(form.values),
      token,
      dispatch
    );
  };

  return (
    <>
      <Form>
        <div>
          <Form.Group controlId={FIELD_NAMES.AUCTION_ROUND_NAME}>
            <Form.Label>Názov aukčného kola</Form.Label>
            <Field
              name={FIELD_NAMES.AUCTION_ROUND_NAME}
              type="text"
              component={BootstrapFormControl}
              text={InfoPopoverText.AUCTION_ROUND.AUCTION_ROUND_NAME}
              maxLength="300"
              disabled
              normalize={normalizeLength(300)}
              validate={[required]}
            />
          </Form.Group>
          {/* <Form.Group controlId={FIELD_NAMES.ROUNDTYPE}>
            <Form.Label>Typ kola</Form.Label>
            <Field
              name={FIELD_NAMES.ROUNDTYPE}
              as="select"
              component={BootstrapDropDownControl}
              text={InfoPopoverText.AUCTION_ROUND.ROUNDTYPE}>
              {Object.entries(RoundTypeEnum).map(pair => (
                <option key={pair[0]} value={pair[0]}>
                  {pair[1]}
                </option>
              ))}
            </Field> 
          </Form.Group> */}
          <Form.Group controlId={FIELD_NAMES.RENEWTYPE}>
            <Form.Label>Podmienka predĺženia kola</Form.Label>
            <Field
              name={FIELD_NAMES.RENEWTYPE}
              as="select"
              component={BootstrapDropDownControl}
              text={InfoPopoverText.AUCTION_ROUND.RENEWTYPE}
            >
              {Object.entries(RenewTypeEnum).map(pair => (
                <option key={pair[0]} value={pair[0]}>
                  {pair[1]}
                </option>
              ))}
            </Field>
          </Form.Group>
          <Form.Group controlId={FIELD_NAMES.DURATION}>
            <Form.Label>
              Dĺžka trvania kola [min]<i className="text-danger">*</i>
            </Form.Label>
            <Field
              name={FIELD_NAMES.DURATION}
              type="text"
              component={BootstrapFormControl}
              text={InfoPopoverText.AUCTION_ROUND.DURATION}
              normalize={normalizePositiveFloatKeepEmpty}
              maxLength="254"
              validate={required}
            />
          </Form.Group>
          <Form.Group controlId={FIELD_NAMES.RENEWDURATION}>
            <Form.Label>Predĺženie kola o [min]</Form.Label>
            <Field
              name={FIELD_NAMES.RENEWDURATION}
              type="text"
              component={BootstrapFormControl}
              text={InfoPopoverText.AUCTION_ROUND.RENEWDURATION}
              maxLength="254"
              normalize={normalizePositiveOrZeroIntegerKeepEmpty}
            />
          </Form.Group>
          <Form.Group controlId={FIELD_NAMES.TIME_FOR_DURATION_RAISE}>
            <Form.Label>Predĺženie v posledných minútach [min]</Form.Label>
            <Field
              name={FIELD_NAMES.TIME_FOR_DURATION_RAISE}
              type="text"
              component={BootstrapFormControl}
              text={InfoPopoverText.AUCTION_ROUND.TIME_FOR_DURATION_RAISE}
              maxLength="254"
              normalize={normalizePositiveOrZeroIntegerKeepEmpty}
            />
          </Form.Group>
          <Form.Group controlId={FIELD_NAMES.SETTING.AUCTIONROUNDSTARTTIME}>
            <Form.Label>Začiatok aukcie</Form.Label>
            <br />
            <Field
              name={FIELD_NAMES.SETTING.AUCTIONROUNDSTARTTIME}
              component={BootstrapFormDateTimePicker}
              text={InfoPopoverText.AUCTION_ROUND.AUCTIONROUNDSTARTTIME}
            />
          </Form.Group>
          {isBadDateVisible ? (
            <p className="text-danger">Zadaný dátum už uplynul!</p>
          ) : (
            ''
          )}
          {/* <div className="col-lg-12 p-0 m-0 row">
            <Nav.Item as="li" className="list-unstyled col-lg-12 p-0">
              <Nav.Link
                onClick={clickhandler}
                className="border-0 pl-0 pr-0 col-lg-12">
                <u>Rozšírené nastavenia</u>
                {isDivVisible ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                <InfoPopover
                  text={InfoPopoverText.AUCTION_ROUND.SETTINGS}
                  className="float-right"
                  style={{marginTop: '-0.25em'}}
                />
              </Nav.Link>
            </Nav.Item>
          </div> */}
          {isDivVisible ? (
            <>
              <Form.Group
                controlId={FIELD_NAMES.SETTING.RENEWNULLCURRENTTIME}
                className="pt-2"
              >
                <Field
                  custom
                  name={FIELD_NAMES.SETTING.RENEWNULLCURRENTTIME}
                  type="checkbox"
                  label="Nulovať aktuálny čas pri predlžovaní"
                  text={InfoPopoverText.AUCTION_ROUND.RENEWNULLCURRENTTIME}
                  component={BootstrapFormCheck}
                />
              </Form.Group>
              <Form.Group controlId={FIELD_NAMES.SETTING.BEST_OPT_OFFER_SHOW}>
                <Field
                  custom
                  name={FIELD_NAMES.SETTING.BEST_OPT_OFFER_SHOW}
                  type="checkbox"
                  label="Zobraziť najlepšie ponuky voliteľných podmienok"
                  text={InfoPopoverText.AUCTION_ROUND.BEST_OPT_OFFER_SHOW}
                  component={BootstrapFormCheck}
                />
              </Form.Group>
              <Form.Group
                controlId={FIELD_NAMES.SETTING.USER_TOP_HELP_CALC_SHOW}
              >
                <Field
                  custom
                  name={FIELD_NAMES.SETTING.USER_TOP_HELP_CALC_SHOW}
                  type="checkbox"
                  label="Zobrazovať účastníkom najlepšie hodnoty pomocných výpočtov"
                  text={InfoPopoverText.AUCTION_ROUND.USER_TOP_HELP_CALC_SHOW}
                  component={BootstrapFormCheck}
                />
              </Form.Group>
              <Form.Group controlId={FIELD_NAMES.SETTING.USER_ORDER_SHOW}>
                <Field
                  custom
                  name={FIELD_NAMES.SETTING.USER_ORDER_SHOW}
                  type="checkbox"
                  label="Zobrazovať uchádzačom ich poradie"
                  text={InfoPopoverText.AUCTION_ROUND.USER_ORDER_SHOW}
                  component={BootstrapFormCheck}
                />
              </Form.Group>
              <Form.Group controlId={FIELD_NAMES.SETTING.USER_ITEM_ORDER_SHOW}>
                <Field
                  custom
                  name={FIELD_NAMES.SETTING.USER_ITEM_ORDER_SHOW}
                  type="checkbox"
                  label="Zobrazovať uchádzačom poradie pri každej položke"
                  text={InfoPopoverText.AUCTION_ROUND.USER_ITEM_ORDER_SHOW}
                  component={BootstrapFormCheck}
                />
              </Form.Group>
              <Form.Group controlId={FIELD_NAMES.SETTING.ADMIN_HELP_CALC_SHOW}>
                <Field
                  custom
                  name={FIELD_NAMES.SETTING.ADMIN_HELP_CALC_SHOW}
                  type="checkbox"
                  label="Zobrazovať pomocné výpočty u administrátora"
                  text={InfoPopoverText.AUCTION_ROUND.ADMIN_HELP_CALC_SHOW}
                  component={BootstrapFormCheck}
                />
              </Form.Group>
              <Form.Group controlId={FIELD_NAMES.SETTING.USER_HELP_CALC_SHOW}>
                <Field
                  custom
                  name={FIELD_NAMES.SETTING.USER_HELP_CALC_SHOW}
                  type="checkbox"
                  label="Zobrazovať pomocné výpočty u uchádzačov"
                  text={InfoPopoverText.AUCTION_ROUND.USER_HELP_CALC_SHOW}
                  component={BootstrapFormCheck}
                />
              </Form.Group>
              <Form.Group
                controlId={FIELD_NAMES.SETTING.USER_HELP_ORD_CALC_SHOW}
              >
                <Field
                  custom
                  name={FIELD_NAMES.SETTING.USER_HELP_ORD_CALC_SHOW}
                  type="checkbox"
                  label="Zobrazovať uchádzačom poradie pomocných výpočtov"
                  text={InfoPopoverText.AUCTION_ROUND.USER_HELP_ORD_CALC_SHOW}
                  component={BootstrapFormCheck}
                />
              </Form.Group>
              <Form.Group
                controlId={FIELD_NAMES.SETTING.USER_TOP_COLL_OFF_SHOW}
              >
                <Field
                  custom
                  name={FIELD_NAMES.SETTING.USER_TOP_COLL_OFF_SHOW}
                  type="checkbox"
                  label="Zobraziť uchádzačom stĺpec najlepšej ponuk"
                  text={InfoPopoverText.AUCTION_ROUND.USER_TOP_COLL_OFF_SHOW}
                  component={BootstrapFormCheck}
                />
              </Form.Group>
              <Form.Group controlId={FIELD_NAMES.SETTING.START_PRICE_SHOW}>
                <Field
                  custom
                  name={FIELD_NAMES.SETTING.START_PRICE_SHOW}
                  type="checkbox"
                  label="Zobraziť vyvolávacie ceny (pokiaľ sú zadané)"
                  text={InfoPopoverText.AUCTION_ROUND.START_PRICE_SHOW}
                  component={BootstrapFormCheck}
                />
              </Form.Group>
              <Form.Group controlId={FIELD_NAMES.SETTING.ALLOW_BIGGER_OFFER}>
                <Field
                  custom
                  name={FIELD_NAMES.SETTING.ALLOW_BIGGER_OFFER}
                  type="checkbox"
                  label="Znemožniť zadanie vyššej ponuky než vyvolávacej"
                  text={InfoPopoverText.AUCTION_ROUND.ALLOW_BIGGER_OFFER}
                  component={BootstrapFormCheck}
                />
              </Form.Group>
              <Form.Group
                controlId={FIELD_NAMES.SETTING.SHOW_STR_PRICE_COLLNAME}
              >
                <Field
                  custom
                  name={FIELD_NAMES.SETTING.SHOW_STR_PRICE_COLLNAME}
                  type="checkbox"
                  label="Zobraziť v názve stĺpca Vyv. cena"
                  text={InfoPopoverText.AUCTION_ROUND.SHOW_STR_PRICE_COLLNAME}
                  component={BootstrapFormCheck}
                />
              </Form.Group>
              <Form.Group controlId={FIELD_NAMES.SETTING.SHOW_USER_TOP_END_PRC}>
                <Field
                  custom
                  name={FIELD_NAMES.SETTING.SHOW_USER_TOP_END_PRC}
                  type="checkbox"
                  label="Zobrazovať uchádzačom najlepšiu celkovú cenu"
                  text={InfoPopoverText.AUCTION_ROUND.SHOW_USER_TOP_END_PRC}
                  component={BootstrapFormCheck}
                />
              </Form.Group>
              <Form.Group
                controlId={FIELD_NAMES.SETTING.SHOW_USER_OFFER_END_PRC}
              >
                <Field
                  custom
                  name={FIELD_NAMES.SETTING.SHOW_USER_OFFER_END_PRC}
                  type="checkbox"
                  label="Zobrazovať uchádzačom celkovú cenu ich ponuky"
                  text={InfoPopoverText.AUCTION_ROUND.SHOW_USER_OFFER_END_PRC}
                  component={BootstrapFormCheck}
                />
              </Form.Group>
              <Form.Group controlId={FIELD_NAMES.SETTING.ALLOW_BEAT_FIRST}>
                <Field
                  custom
                  name={FIELD_NAMES.SETTING.ALLOW_BEAT_FIRST}
                  type="checkbox"
                  label="Znemožniť dorovnať najvýhodnejšiu ponuku podľa typu hodnotenia
                (tj. na 1. mieste)"
                  text={InfoPopoverText.AUCTION_ROUND.ALLOW_BEAT_FIRST}
                  component={BootstrapFormCheck}
                />
              </Form.Group>
              <Form.Group controlId={FIELD_NAMES.SETTING.ALLOW_BEAT_OTHER}>
                <Field
                  custom
                  name={FIELD_NAMES.SETTING.ALLOW_BEAT_OTHER}
                  type="checkbox"
                  label="Znemožniť dorovnať ostatné ponuky (tj. na druhom a ďalšom mieste
                  v poradí)"
                  text={InfoPopoverText.AUCTION_ROUND.ALLOW_BEAT_OTHER}
                  component={BootstrapFormCheck}
                />
              </Form.Group>
              <Form.Group
                controlId={FIELD_NAMES.SETTING.ALLOW_BEAT_TOP_ITEM_VAL}
              >
                <Field
                  custom
                  name={FIELD_NAMES.SETTING.ALLOW_BEAT_TOP_ITEM_VAL}
                  type="checkbox"
                  label="Znemožniť dorovnať najlepšiu hodnotu pri položke"
                  text={InfoPopoverText.AUCTION_ROUND.ALLOW_BEAT_TOP_ITEM_VAL}
                  component={BootstrapFormCheck}
                />
              </Form.Group>
              <Form.Group controlId={FIELD_NAMES.SETTING.SHOW_CONTRACTOR_INF}>
                <Field
                  custom
                  name={FIELD_NAMES.SETTING.SHOW_CONTRACTOR_INF}
                  type="checkbox"
                  label="Zobrazovať info dodávateľovi"
                  text={InfoPopoverText.AUCTION_ROUND.SHOW_CONTRACTOR_INF}
                  component={BootstrapFormCheck}
                />
              </Form.Group>
              <Form.Group
                controlId={FIELD_NAMES.SETTING.SHOW_USER_TOP_ITEM_PRC}
              >
                <Field
                  custom
                  name={FIELD_NAMES.SETTING.SHOW_USER_TOP_ITEM_PRC}
                  type="checkbox"
                  label="Zobrazovať uchádzačom najlepšie ceny pri položkách"
                  text={InfoPopoverText.AUCTION_ROUND.SHOW_USER_TOP_ITEM_PRC}
                  component={BootstrapFormCheck}
                />
              </Form.Group>
            </>
          ) : null}
          {auctionData != null &&
          auctionData.roundEnum === RoundLifeCycleEnum.PREPARATION_PHASE ? (
            <div className="text-right container">
              <ErrorFormText formName={FORM_NAME} />
              <Button
                variant="success"
                type="submit"
                className="ml-5 mr-0 mb-5 mt-0 pt-3 pb-3 pl-5 pr-5"
                onClick={handleClick}
              >
                Uložiť zmeny
              </Button>
            </div>
          ) : null}
        </div>
      </Form>
    </>
  );
}

export default reduxForm({
  form: FORM_NAME,
  warn,
})(AddOrEditRoundForm);
