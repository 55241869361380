/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {useState} from 'react';

import {Button} from 'react-bootstrap';
import DeleteIcon from '@material-ui/icons/Delete';

import DeleteCriteriaModal from './DeleteCriteriaModal';

function DeleteCriteriaButton(props) {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <Button
        className="ml-2"
        variant="danger"
        // className="text-danger mouse-hover-delete p-1 pr-3 text-nowrap"
        onClick={() => setModalShow(true)}
      >
        <DeleteIcon /> Vymazať
      </Button>
      <DeleteCriteriaModal
        id={props.id}
        name={props.name}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}
export default DeleteCriteriaButton;
