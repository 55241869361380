const TEXT = {
  ADD_AUCTION_ROUND: {
    TITLE: 'Pridaly ste aukčné kolo!',
    DESCRIPTION: 'Bolo pridané aukčné kolo',
  },
  ADD_OFFER: {
    TITLE: 'Pridali ste ponuku!',
    DESCRIPTION: 'Pridali ste ponuku pre túto aukciu!',
  },
  ADD_AUCTION_ITEMS: {
    TITLE: 'Pridanie položky do aukcie prebehlo úspešne!',
    DESCRIPTION: 'Podarilo sa Vám pridať položku ',
  },
  ADD_AUCTION_CRITERIA: {
    TITLE: 'Pridanie kritéria do aukcie prebehlo úspešne!',
    DESCRIPTION: 'Podarilo sa Vám pridať kritérium ',
  },
  DEFINE_AUCTION_SETTINGS: {
    TITLE: 'Aukčné údaje boli zmenené!',
    DESCRIPTION: 'Zmenily ste aukčné údaje pre aukciu č.',
  },
  DELETE_AUCTION_ROUND: {
    TITLE: 'Vymazali ste aukčné kolo',
    DESCRIPTION: 'Vymazali ste aukčné kolo č.',
  },
  DELETE_AUCTION_ITEMS: {
    TITLE: 'Vymazali ste položku',
    DESCRIPTION: 'Vymazali ste položku: ',
  },
  DELETE_AUCTION_CRITERIA: {
    TITLE: 'Vymazali ste kritérium',
    DESCRIPTION: 'Vymazali ste kritérium: ',
  },
  EDIT_AUCTION: {
    TITLE: 'Editácia aukcie prebehla úspešne!',
    DESCRIPTION: 'Podarilo sa Vám editovať aukciu č.',
  },
  EDIT_AUCTION_ITEMS: {
    TITLE: 'Editácia položky aukcie prebehla úspešne!',
    DESCRIPTION: 'Podarilo sa Vám editovať položku ',
  },
  EDIT_AUCTION_CRITERIA: {
    TITLE: 'Editácia kritéria aukcie prebehla úspešne!',
    DESCRIPTION: 'Podarilo sa Vám editovať kritérium ',
  },
  EDIT_AUCTION_ROUNDS: {
    TITLE: 'Editácia kôl aukcie prebehla úspešne!',
    DESCRIPTION: 'Podarilo sa Vám editovať kolo ',
  },
  EDIT_ORGANIZATION: {
    TITLE: 'Editácia organizácie aukcie prebehla úspešne!',
    DESCRIPTION: 'Podarilo sa Vám editovať organizáciu ',
  },
  LOGED_USER: {
    TITLE: 'Do aukcie sa prihlásil uživaťeľ',
    DESCRIPTION: '',
  },
};

export default TEXT;
