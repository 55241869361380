import React from 'react';
// import {useParams} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';
import Spinner from 'react-bootstrap/Spinner';
import startAuction from '../../../services/startAuction';
import {selectors as selectorToken} from '../../../features/token';
import {selectors as selectorAuctionData} from '../../../features/userAuctionData';
import {selectors} from '../../../features/users';

import {actionTypes} from '../../../features/auction';

function StartAuctionModal(props) {
  const {id} = props;
  const dispatch = useDispatch();
  // const {auctionId} = useParams();
  const token = useSelector(selectorToken.getToken);
  const auctionData = useSelector(selectorAuctionData.getStartAuctionData);
  const auctionDataInfo = useSelector(selectorAuctionData.getValues);
  const participants = useSelector(selectors.getValues);
  const formatTwoDigits = digit => `0${digit}`.slice(-2);
  let date = null;

  const tempDate = auctionDataInfo
    ? new Date(auctionDataInfo.auctionStartTime)
    : null;
  date = tempDate
    ? `${formatTwoDigits(tempDate.getDate())}.${formatTwoDigits(
        tempDate.getMonth() + 1
      )}.${tempDate.getFullYear()} ${formatTwoDigits(
        tempDate.getHours()
      )}:${formatTwoDigits(tempDate.getMinutes())}`
    : null;

  const handleClick = e => {
    e.preventDefault();
    dispatch({
      type: actionTypes.AUCTION_START_SHOW_LOADING,
    });
    startAuction(id, token, dispatch);
    props.onHide();
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <div className="text-center container">
          <Modal.Title
            as="h4"
            id="contained-modal-title-vcenter"
            className="font-weight-bolder"
          >
            Spustiť aukciu
          </Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body className="p-3">
        {auctionData != null ? (
          auctionData.response.map(d => {
            return (
              <React.Fragment key={d}>
                <div className="col-lg-12 row mb-2">
                  <p className="h6 text-blue2 col-lg-6 ">
                    Názov elektronickej aukcie
                  </p>
                  <p className="h6 text-muted col-lg-6">
                    {auctionDataInfo.auctionName}
                  </p>
                </div>
                <div className="col-lg-12 row mb-2">
                  <p className="h6 text-blue2 col-lg-6 ">Čas začiatku aukcie</p>
                  <p className="h6 text-muted col-lg-6">{date}</p>
                </div>
                <div className="col-lg-12 row mb-2">
                  <p className="h6 text-blue2 col-lg-6 ">Účastníci</p>
                  <p className="h6 text-muted col-lg-6">
                    {participants?.organizations?.map(o => (
                      <React.Fragment key={o.id}>{o.name}</React.Fragment>
                    ))}
                  </p>
                </div>
                {auctionData.auctionReady ? (
                  <>
                    <div className="col-lg-12 row mb-2">
                      <p className="h6 text-blue2 col-lg-6 ">Stav aukcie</p>
                      <p className="h6 text-success col-lg-6">{d}</p>
                    </div>
                  </>
                ) : (
                  <div className="col-lg-12 row mb-2">
                    <p className="h6 text-blue2 col-lg-6 ">Stav aukcie</p>
                    <p className="h6 text-danger col-lg-6">{d}</p>
                  </div>
                )}
              </React.Fragment>
            );
          })
        ) : (
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        )}
      </Modal.Body>
      <Modal.Footer className="border-top-0">
        {auctionData != null && auctionData.auctionReady ? (
          <Button
            disabled={!auctionData.auctionReady}
            variant="success"
            onClick={handleClick}
          >
            Rozoslať prihlášky uchádzačom a spustiť aukciu
          </Button>
        ) : (
          <Button variant="danger" onClick={props.onHide}>
            Nie
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}
StartAuctionModal.propTypes = {
  onHide: PropTypes.func.isRequired,
};

export default StartAuctionModal;
