import React from 'react';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import {reduxForm, Field, setSubmitFailed} from 'redux-form';
import {useParams} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import UnitEnum from '../../enums/UnitEnum';
import NatureEnum from '../../enums/NatureEnum';
import {
  BootstrapFormControl,
  BootstrapFormCheck,
  BootstrapDropDownControl,
  ItemsMultipleMaterialAutocomplete,
} from '../../ReduxBootstrapFields';
import {actionTypes, selectors} from '../../../features/auction';
import {requestDTO} from '../../../services';
import editAuctionCriteria from '../../../services/editAuctionCriteria';
import {selectors as selectorToken} from '../../../features/token';
import ErrorFormText from '../Error/ErrorFormText';
import FIELD_NAMES from './AddOrEditCriteriaFormFieldNames';
import InfoPopoverText from '../../Popover/InfoPopoverTexts';
import RoundLifeCycleEnum from '../../enums/RoundLifeCycleEnum';
import SubmitFormValidator, {
  required,
  maxValue1000,
  normalizePositiveInteger,
  normalizeLength,
  normalizePositiveOrZeroFloat,
  normalizePositiveFloat,
} from '../../Utils/SubmitFormValidator';
import {selectors as selectorUAD} from '../../../features/userAuctionData';

export const TYPES = {
  EDIT: 'EDIT',
  ADD: 'ADD',
};
export const FORM_NAME = 'AddOrEditCriteriaForm';

const warn = values => {
  const warnings = {};

  return warnings;
};

function AddOrEditCriteriaForm(props) {
  const {type} = props;
  const dispatch = useDispatch();
  const auction = useSelector(selectors.getAuction);
  const form = useSelector(state => state.form[FORM_NAME]);
  const {auctionId} = useParams();
  const isLoading = useSelector(selectors.getAuctionCriteriaLoading);
  /* TODO zmenit potom */
  const token = useSelector(selectorToken.getToken);
  const auctionData = useSelector(selectorUAD.getValues);
  const started = !!(
    auctionData != null &&
    auctionData.roundEnum !== RoundLifeCycleEnum.PREPARATION_PHASE
  );
  const handleClick = e => {
    e.preventDefault();
    switch (type) {
      case TYPES.ADD:
        if (SubmitFormValidator(form)) {
          dispatch(setSubmitFailed(FORM_NAME, ...Object.keys(form.syncErrors)));
          return;
        }
        dispatch({
          type: actionTypes.AUCTION_SHOW_LOADING,
        });
        dispatch({
          type: actionTypes.AUCTION_CRITERIA_SHOW_LOADING,
        });
        editAuctionCriteria(
          auctionId,
          requestDTO.createAddAuctionCriteriaRequest(form.values, auction),
          token,
          dispatch,
          () => {
            props.setModalShow(false);
          },
          undefined,
          undefined,
          form.values,
          true
        );
        break;
      case TYPES.EDIT:
        if (SubmitFormValidator(form)) {
          dispatch(setSubmitFailed(FORM_NAME, ...Object.keys(form.syncErrors)));
          return;
        }
        dispatch({
          type: actionTypes.AUCTION_SHOW_LOADING,
        });
        dispatch({
          type: actionTypes.AUCTION_CRITERIA_SHOW_LOADING,
        });
        editAuctionCriteria(
          auctionId,
          requestDTO.createEditAuctionCriteriaRequest(form.values, auction),
          token,
          dispatch,
          () => {
            props.setModalShow(false);
          },
          undefined,
          undefined,
          form.values,
          false
        );
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Form>
        <div className="container pl-4 pr-4">
          <Form.Group className="pl-4 pr-4" controlId={FIELD_NAMES.NAME}>
            <Form.Label>
              Názov<i className="text-danger">*</i>
            </Form.Label>
            <Field
              name={FIELD_NAMES.NAME}
              type="text"
              label="Názov"
              normalize={normalizeLength(300)}
              component={BootstrapFormControl}
              text={InfoPopoverText.CRITERIA.NAME}
              validate={[required]}
              maxLength="300"
            />
          </Form.Group>

          {/* <Form.Group className="pl-4 pr-4" controlId={FIELD_NAMES.TYPE}>
            <Form.Label>Typ kritéria</Form.Label>
            <Field
              name={FIELD_NAMES.TYPE}
              as="select"
              label="Typ kritéria"
              component={BootstrapDropDownControl}
              text={InfoPopoverText.CRITERIA.TYPE}
            >
              <option key="Common" value="Common">
                Common
              </option>
            </Field>
          </Form.Group> */}
          <Form.Group className="pl-4 pr-4" controlId={FIELD_NAMES.NATURE}>
            <Form.Label>Typ kritéria</Form.Label>
            <Field
              name={FIELD_NAMES.NATURE}
              as="select"
              label="Zoradenie"
              component={BootstrapDropDownControl}
              text={InfoPopoverText.CRITERIA.TYPE}
            >
              {Object.entries(NatureEnum).map(pair => (
                <option key={pair[0]} value={pair[0]}>
                  {pair[1]}
                </option>
              ))}
            </Field>
          </Form.Group>
          <Form.Group
            className="pl-4 pr-4"
            controlId={FIELD_NAMES.TAXINCLUSION}
          >
            <Field
              custom
              name={FIELD_NAMES.TAXINCLUSION}
              type="checkbox"
              label="Vstupuje do aukcie ako pevný počet bodov"
              text={InfoPopoverText.CRITERIA.TAXINCLUSION}
              component={BootstrapFormCheck}
            />
          </Form.Group>
          <div
            className={
              form.values !== undefined &&
              form.values[FIELD_NAMES.UNIT] ===
                Object.entries(UnitEnum).find(e => e[1] === UnitEnum.CUSTOM)[0]
                ? 'rounded-lg bg-blue5'
                : null
            }
          >
            {/* <Form.Group className="pl-4 pr-4" controlId={FIELD_NAMES.UNIT}>
              <Form.Label>Merná jednotka</Form.Label>
              <Field
                name={FIELD_NAMES.UNIT}
                as="select"
                label="Merná jednotka"
                component={BootstrapDropDownControl}
                text={InfoPopoverText.CRITERIA.UNIT}
              >
                {Object.entries(UnitEnum).map(pair => (
                  <option key={pair[0]} value={pair[0]}>
                    {pair[1]}
                  </option>
                ))}
              </Field>
              {form.values != undefined &&
              form.values[FIELD_NAMES.UNIT] ===
                Object.entries(UnitEnum).find(
                  e => e[1] === UnitEnum.CUSTOM
                )[0] ? (
                <Form.Group controlId={FIELD_NAMES.CUSTOM_UNIT}>
                  <Form.Label>Vlastná merná jednotka</Form.Label>
                  <Field
                    name={FIELD_NAMES.CUSTOM_UNIT}
                    type="text"
                    label="Vlastná merná jednotka"
                    validate={maxLength298}
                    component={BootstrapFormControl}
                    normalize={normalizeLength(254)}
                    text={InfoPopoverText.CRITERIA.CUSTOM_UNIT}
                  />
                </Form.Group>
              ) : null}
            </Form.Group> */}
          </div>
          {/* <Form.Group
            className="pl-4 pr-4"
            controlId={FIELD_NAMES.DECIMALCOUNT}>
            <Form.Label>Decimálna hodnota</Form.Label>
            <Field
              name={FIELD_NAMES.DECIMALCOUNT}
              type="number"
              label="Decimálna hodnota"
              component={BootstrapFormControl}
              text={InfoPopoverText.CRITERIA.DECIMALCOUNT}
              normalize={normalizePositiveOrZeroInteger}
            />
          </Form.Group> */}
          {/* <div className="col-lg-12">
            <div className="row">
              <Form.Group
                className="pl-4 pr-4 col-lg-6"
                controlId={FIELD_NAMES.MINIMALVALUE}
              >
                <Form.Label>Minimálna hodnota</Form.Label>
                <Field
                  name={FIELD_NAMES.MINIMALVALUE}
                  type="number"
                  label="Minimálna hodnota"
                  component={BootstrapFormControl}
                  text={InfoPopoverText.CRITERIA.MINIMALVALUE}
                  normalize={normalizePositiveOrZeroFloat}
                  validate={required}
                />
              </Form.Group>
              <Form.Group
                className="pl-4 pr-4 col-lg-6"
                controlId={FIELD_NAMES.MAXIMALVALUE}
              >
                <Form.Label>Maximálna hodnota</Form.Label>
                <Field
                  name={FIELD_NAMES.MAXIMALVALUE}
                  type="number"
                  label="Maximálna hodnota"
                  component={BootstrapFormControl}
                  text={InfoPopoverText.CRITERIA.MAXIMALVALUE}
                  normalize={normalizePositiveOrZeroFloat}
                  validate={[required]}
                />
              </Form.Group>
            </div>
          </div> */}
          {form.values !== undefined &&
          form.values[FIELD_NAMES.TAXINCLUSION] ? null : (
            <div className="col-lg-12">
              <div className="row">
                <Form.Group
                  className="pl-4 pr-4 col-lg-6"
                  controlId={FIELD_NAMES.MINVAULECHANGE}
                >
                  <Form.Label>Minimálna zmena hodnoty</Form.Label>
                  <Field
                    name={FIELD_NAMES.MINVAULECHANGE}
                    type="text"
                    label="Minimálna zmena hodnoty"
                    normalize={normalizePositiveOrZeroFloat}
                    component={BootstrapFormControl}
                    text={InfoPopoverText.CRITERIA.MINVAULECHANGE}
                  />
                </Form.Group>
                <Form.Group
                  className="pl-4 pr-4 col-lg-6"
                  controlId={FIELD_NAMES.MAXVAULECHANGE}
                >
                  <Form.Label>Maximálna zmena hodnoty</Form.Label>
                  <Field
                    name={FIELD_NAMES.MAXVAULECHANGE}
                    type="text"
                    label="Maximálna zmena hodnoty"
                    normalize={normalizePositiveFloat}
                    component={BootstrapFormControl}
                    text={InfoPopoverText.CRITERIA.MAXVAULECHANGE}
                  />
                </Form.Group>
              </div>
            </div>
          )}

          <Form.Group
            className="pl-4 pr-4"
            controlId={FIELD_NAMES.MAXIMUMPOINTS}
          >
            <Form.Label>
              Maximálna hodnota bodov<i className="text-danger">*</i>
            </Form.Label>
            <Field
              name={FIELD_NAMES.MAXIMUMPOINTS}
              type="text"
              label="Maximálna hodnota bodov"
              component={BootstrapFormControl}
              text={InfoPopoverText.CRITERIA.MAXIMUMPOINTS}
              normalize={normalizePositiveInteger}
              validate={[required, maxValue1000]}
            />
          </Form.Group>
          <Form.Group className="pl-4 pr-4" controlId={FIELD_NAMES.ITEM}>
            <Form.Label>Položky pre kritérium</Form.Label>
            <Field
              multiple
              filterSelectedOptions
              id="tags-standart"
              options={auction.items}
              getOptionLabel={option => `${option.name}`}
              name={FIELD_NAMES.ITEM}
              style={{width: '100%'}}
              type="text"
              component={ItemsMultipleMaterialAutocomplete}
              text={InfoPopoverText.AUCTION.ITEM}
            />
          </Form.Group>
          <div className="text-center container">
            {started !== null && !started ? (
              <>
                <ErrorFormText formName={FORM_NAME} />
                <Button
                  onClick={handleClick}
                  variant="success"
                  disabled={isLoading}
                  type="submit"
                  className="ml-5 mr-5 mb-5 mt-0 pt-3 pb-3 pl-5 pr-5"
                >
                  {isLoading ? (
                    <Spinner animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  ) : (
                    'Ulož'
                  )}
                </Button>
              </>
            ) : null}
          </div>
        </div>
      </Form>
    </>
  );
}

export default reduxForm({
  form: FORM_NAME,
  warn,
})(AddOrEditCriteriaForm);
