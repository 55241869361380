/* eslint-disable no-nested-ternary */
import React from 'react';
import Form from 'react-bootstrap/Form';
import {useSelector, useDispatch} from 'react-redux';
import {
  reduxForm,
  FieldArray,
  change,
  updateSyncErrors,
  touch,
  setSubmitFailed,
} from 'redux-form';
import {useParams} from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import addOffer from '../../../services/addOffer';
import {requestDTO} from '../../../services';
import ErrorFormText from '../../../components/Modals/Error/ErrorFormText';
import {selectors as selectorToken} from '../../../features/token';
import SubmitFormValidator from '../../../components/Utils/SubmitFormValidator';
import {
  actionTypes as actionTypesOffers,
  selectors as selectorOffer,
} from '../../../features/offers';
import DiamondIcon from '../../../components/MenuIcons/DiamondIcon';
import ClubsIcon from '../../../components/MenuIcons/ClubsIcon';
import renderMembers from './renderMembers';

export const FORM_NAME = 'auctionMulticriterialForm';

const AuctionMulticriterialForm = props => {
  const form = useSelector(state => state.form[FORM_NAME]);
  const dispatch = useDispatch();
  // const {handleSubmit, pristine, reset, submitting} = props;
  const {handleSubmit} = props;
  const token = useSelector(selectorToken.getToken);
  const {auctionId} = useParams();
  const organizationId = useSelector(selectorToken.getOrganizationId);
  const addOfferLoading = useSelector(selectorOffer.getAddOfferLoading);
  const showOffers = props.criteria.showWinningOffers;
  const points = props.criteria.yourPoints;

  const handleClick = e => {
    e.preventDefault();
    if (SubmitFormValidator(form)) {
      dispatch(setSubmitFailed(FORM_NAME, ...Object.keys(form.syncErrors)));
      return;
    }
    let sameValue = true;
    // props.criteria.criteriaPriceOffers.forEach(critPrice => {
    //   const crit = form.values.criteriaPriceOffers.find(
    //     formPriceOffer => formPriceOffer.id === critPrice.id
    //   );
    //   if (crit.value !== critPrice.value) {
    //     sameValue = false;
    //   }
    // });
    props.criteria.criteriaWsResponse.forEach(critPrice => {
      const crit = form.values.criteriaWsResponse.find(
        formPriceOffer => formPriceOffer.id === critPrice.id
      );
      if (crit.value !== critPrice.value) {
        sameValue = false;
      }
    });
    if (sameValue) {
      return;
    }
    dispatch({
      type: actionTypesOffers.OFFERS_ADD_SHOW_LOADING,
    });
    addOffer(
      requestDTO.addOfferRequest(form.values, auctionId, organizationId),
      token,
      dispatch
    );
  };

  const changeFieldValue = (field, value) => {
    dispatch(change(FORM_NAME, field, value));
  };

  const dispatchFormErrors = errors => {
    dispatch(updateSyncErrors(FORM_NAME, errors));
  };

  const dispatchTouch = touched => {
    dispatch(touch(FORM_NAME, touched));
  };
  return (
    <Form className="mb-5 p-0" onSubmit={handleSubmit}>
      <FieldArray
        name="criteriaWsResponse"
        criteria={props.criteria.criteriaWsResponse}
        criteriaOffersValues={props.criteria.criteriaPriceOffers}
        showOffers={showOffers}
        points={points}
        component={renderMembers}
        changeFieldValue={changeFieldValue}
        dispatchFormErrors={dispatchFormErrors}
        dispatchTouch={dispatchTouch}
        criteriaPriceOffersFormValues={
          form.values !== undefined &&
          form.values.criteriaPriceOffers !== undefined
            ? form.values.criteriaPriceOffers
            : null
        }
        formErrors={form.syncErrors}
      />
      <div className="row col-lg-12 m-0 pl-0 pr-0 pt-3 pb-3 bg-white rounded">
        <div className="col-lg-3 align-self-center">
          <p className="m-0">
            <DiamondIcon className="mr-1 mb-1 ml-4" />
            Položka
            <ClubsIcon className="ml-3 mr-1 mb-1" fill="#3E97EF" />
            Kritérium
          </p>
        </div>
        <div className="offset-lg-6 col-lg-3 pr-0">
          <div className="col-lg-12 ">
            <ErrorFormText formName={FORM_NAME} />
            <Button
              onClick={handleClick}
              className="text-white pt-4 pb-4 pl-5 pr-5 float-right"
              variant="success"
              type="submit"
              disabled={addOfferLoading}
            >
              {addOfferLoading ? (
                <div className="pl-5 pr-5 pt-0 pb-0">
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </div>
              ) : (
                'Odoslať ponuky'
              )}
            </Button>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default reduxForm({
  form: FORM_NAME,
})(AuctionMulticriterialForm);
