import {MODAL_ERROR_HIDE, MODAL_ERROR_SHOW} from './actionTypes';

const initialState = {
  errorModal: false,
  error: {title: null, description: null},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MODAL_ERROR_SHOW:
      return {
        ...state,
        errorModal: true,
        error: action.modal,
      };
    case MODAL_ERROR_HIDE:
      return {
        ...state,
        errorModal: false,
        error: {title: null, description: null},
      };
    default:
      return state;
  }
};
