import axios from 'axios';
import settings from './settings';
import {actionTypes} from '../features/token';
import {actionTypes as errorActionType} from '../features/modals';

const tokenValidation = (
  token,
  dispatch,
  thenCallback,
  catchCallback,
  finallyCallback
) => {
  axios
    .post(
      settings.SERVICE_URL + settings.SERVICES.TOKEN_VALIDATION,
      {},
      {
        responseType: 'json',
        headers: {
          'Ela-Elc-Auth': token,
        },
      }
    )
    .then(response => {
      /* ulozit do cookies */
      if (!response.data.valid) {
        dispatch({
          type: actionTypes.TOKEN_LOGOUT,
        });
      }
      if (typeof thenCallback === 'function') {
        thenCallback(response);
      }
    })
    .catch(error => {
      // handle error
      // console.log(error);
      if (error.response != null && error.response.data != null) {
        const {data} = error.response;
        dispatch({
          type: errorActionType.MODAL_ERROR_SHOW,
          modal: {title: data.message, description: data.description},
        });
      }
      dispatch({
        type: actionTypes.TOKEN_LOGOUT,
      });
      if (typeof catchCallback === 'function') {
        catchCallback(error);
      }
    })
    .finally(() => {
      if (typeof finallyCallback === 'function') {
        finallyCallback();
      }
    });
};

export default tokenValidation;
