export const getAuction = state => state.auction.value;
export const getItems = state => state.auction.value.items;
export const getLoading = state => state.auction.loading;
export const getOrdersFormInitial = state => state.auction.ordersFormInitial;
export const getStartAuctionLoading = state =>
  state.auction.loadingStartAuction;
export const getStopAuctionLoading = state => state.auction.loadingStopAuction;
export const getMaxPoints = state => state.auction.maxPoints;
export const getAuctionCriteriaLoading = state =>
  state.auction.loadingEditOrAddCriteria;
export const getAuctionItemLoading = state =>
  state.auction.loadingEditOrAddItems;
