import React from 'react';

function ClubsIcon(props) {
  return (
    <svg
      {...props}
      version="1.1"
      id="IconsRepoEditor"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 11 13"
      xmlSpace="preserve"
      width={props.width != null ? props.width : '11px'}
      height={props.height != null ? props.height : '13px'}
      className={props.className != null ? props.className : null}
      fill="none"
      strokeWidth="0"
    >
      <path
        d="M5.50007 10.2935L4.45947 12.2915C5.13701 12.0795 5.86313 12.0795 6.54066 12.2915L5.50007 10.2935Z"
        fill={props.fill != null ? props.fill : '#12406D'}
      />
      <path
        d="M8.06672 5.67697C8.04136 5.6703 8.01932 5.65458 8.00476 5.63277C7.99019 5.61097 7.9841 5.5846 7.98763 5.55862C8.03574 5.20372 8.00735 4.84263 7.90438 4.49961C7.8014 4.15658 7.62621 3.83956 7.39059 3.56984C7.15496 3.30012 6.86435 3.08394 6.53827 2.93582C6.21219 2.78769 5.85819 2.71106 5.50004 2.71106C5.1419 2.71106 4.78789 2.78769 4.46181 2.93582C4.13573 3.08394 3.84512 3.30012 3.6095 3.56984C3.37387 3.83956 3.19869 4.15658 3.09571 4.49961C2.99273 4.84263 2.96434 5.20372 3.01245 5.55862C3.01599 5.5846 3.0099 5.61097 2.99533 5.63277C2.98076 5.65458 2.95872 5.6703 2.93337 5.67697C2.55642 5.77648 2.20782 5.96241 1.91519 6.22002C1.62256 6.47763 1.39395 6.79985 1.24746 7.16114C1.10097 7.52244 1.04063 7.91289 1.07122 8.30155C1.10181 8.69021 1.22249 9.06641 1.4237 9.40034C1.62491 9.73427 1.90112 10.0168 2.23045 10.2254C2.55977 10.4341 2.93317 10.5632 3.32105 10.6025C3.70892 10.6418 4.10063 10.5902 4.46512 10.4519C4.82961 10.3135 5.15688 10.0922 5.421 9.80546C5.43106 9.79453 5.44328 9.7858 5.45689 9.77983C5.47049 9.77386 5.48519 9.77078 5.50004 9.77078C5.5149 9.77078 5.52959 9.77386 5.5432 9.77983C5.5568 9.7858 5.56902 9.79453 5.57908 9.80546C5.84321 10.0921 6.17047 10.3133 6.53492 10.4516C6.89937 10.5899 7.29101 10.6414 7.67882 10.602C8.06663 10.5627 8.43995 10.4336 8.76921 10.2249C9.09846 10.0163 9.37462 9.73386 9.5758 9.39999C9.77697 9.06611 9.89764 8.68998 9.92825 8.30139C9.95885 7.9128 9.89855 7.52241 9.75212 7.16116C9.6057 6.79991 9.37716 6.47772 9.08462 6.22011C8.79209 5.9625 8.44358 5.77654 8.06672 5.67697Z"
        fill={props.fill != null ? props.fill : '#12406D'}
      />
    </svg>
  );
}
export default ClubsIcon;
