import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner';
import className from 'classnames';
import {selectors} from '../../../features/auditLog';
import loadAuditLog from '../../../services/loadAuditLog';
import {selectors as selectorToken} from '../../../features/token';
import InfoPopoverTexts from '../../../components/Popover/InfoPopoverTexts';
import InfoPopover from '../../../components/Popover';

const AuditLog = () => {
  const dispatch = useDispatch();
  const token = useSelector(selectorToken.getToken);
  const isLoading = useSelector(selectors.getLoading);
  const {auctionId} = useParams();
  const auditLog = useSelector(selectors.getAuditLog);
  const formatTwoDigits = digit => `0${digit}`.slice(-2);
  let date = null;

  const tempDate = new Date();
  date = `${formatTwoDigits(tempDate.getDate())}.${formatTwoDigits(
    tempDate.getMonth() + 1
  )}.${tempDate.getFullYear()} ${formatTwoDigits(
    tempDate.getHours()
  )}:${formatTwoDigits(tempDate.getMinutes())}`;

  useEffect(() => {
    loadAuditLog(auctionId, token, dispatch);
  }, [auctionId, dispatch, token]);

  return (
    <>
      <div className="bg-white col-lg-12 mt-5 mb-5 rounded pl-0 pr-0">
        <div className="border-bottom">
          <div className="col-lg-12 p-0">
            <h1 className="h4  pl-5 pt-5 pr-5 pb-4 m-0">
              Audit log
              <InfoPopover
                placement="bottom"
                text={InfoPopoverTexts.AUDIT_LOG.HEADER_NAME}
              />
            </h1>
          </div>
        </div>
        <div className="col-lg-12 pt-5 pb-5 ml-3 row">
          {!isLoading && auditLog && (
            <>
              <div className="col-lg-12 row mb-2">
                <p className="h6 text-blue2 col-lg-6 ">
                  Názov elektronickej aukcie
                </p>
                <p className="h6 text-muted col-lg-6">{auditLog.auctionName}</p>
              </div>
              {/* <div className="col-lg-12 row mb-2">
                <p className="h6 text-blue2 col-lg-6 ">
                  Typ elektronickej aukcie
                </p>
                <p className="h6 text-muted col-lg-6">
                  {auditLog.auctionType}{' '}
                </p>
              </div> */}
              <div className="col-lg-12 row mb-2">
                <p className="h6 text-blue2 col-lg-6 ">
                  Dátum vytvorenia eAukčného prípadu v systéme
                </p>
                <p className="h6 text-muted col-lg-6">
                  {auditLog.createAuctionDate}
                </p>
              </div>
              <div className="col-lg-12 row mb-2">
                <p className="h6 text-blue2 col-lg-6 ">
                  Dátum odoslania Výzvy na účasť vo výberovom konaní
                </p>
                <p className="h6 text-muted col-lg-6">
                  {auditLog.sendAuctionDate}{' '}
                </p>
              </div>
              <div className="col-lg-12 row mb-2">
                <p className="h6 text-blue2 col-lg-6 ">Začiatok Aukčné kolo</p>
                <p className="h6 text-muted col-lg-6">
                  {auditLog.startAuction}{' '}
                </p>
              </div>
              <div className="col-lg-12 row mb-2">
                <p className="h6 text-blue2 col-lg-6 ">Ukončenie Aukčné kolo</p>
                <p className="h6 text-muted col-lg-6">{auditLog.endAuction}</p>
              </div>
              <div className="col-lg-12 row mb-2">
                <p className="h6 text-blue2 col-lg-6 ">
                  Názov elektronickej aukcie
                </p>
                <p className="h6 text-muted col-lg-6">{auditLog.auctionName}</p>
              </div>
              <div className="col-lg-12 row mb-2">
                <p className="h6 text-blue2 col-lg-6 ">Mena</p>
                <p className="h6 text-muted col-lg-6">
                  {' '}
                  {auditLog.currencyEnum}
                </p>
              </div>
              {/* <div className="col-lg-12 row mb-2">
                <p className="h6 text-blue2 col-lg-6 ">Hodnotiace kritérium</p>
                <p className="h6 text-muted col-lg-6">
                  {' '}
                  {auditLog.roundExtensionCaseEnum}
                </p>
              </div> */}
              {/* <div className="col-lg-12 row mb-2">
                <p className="h6 text-blue2 col-lg-6 ">Protokol je platný k</p>
                <p className="h6 text-muted col-lg-6">
                  {auditLog.protocolValidDate}
                </p>
              </div> */}
              <div className="col-lg-12 row mb-2">
                <p className="h6 text-blue2 col-lg-6 ">
                  Protokol vygenerovaný v
                </p>
                <p className="h6 text-muted col-lg-6">{date}</p>
              </div>
            </>
          )}{' '}
          {isLoading && (
            <div className="pl-3">
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          )}
        </div>
      </div>
      {auditLog ? (
        <div className="col-lg-12 row ml-0 pl-0 pr-0 bg-white">
          <Table striped hover className="mt-4 mb-5">
            <thead className="bg-white">
              <tr className="bg-white text-blue">
                <th>Dátum</th>
                <th>Uchádzač</th>
                <th>Položka</th>
                <th>Popis</th>
                <th>{}</th>
              </tr>
            </thead>
            <tbody>
              {auditLog.auditLog.map(d => (
                <tr
                  key={d.id}
                  className={
                    d.auditLogRequestTypeEnum === 'EXCEPTION'
                      ? 'text-danger'
                      : ''
                  }
                >
                  <td
                    className={className({
                      'pt-4 pb-4': true,
                      'text-danger': d.auditLogRequestTypeEnum === 'EXCEPTION',
                    })}
                  >
                    {d.createDateTime}
                  </td>
                  <td
                    className={className({
                      'pt-4 pb-4': true,
                      'text-danger': d.auditLogRequestTypeEnum === 'EXCEPTION',
                    })}
                  >
                    {d.username}
                  </td>
                  <td
                    className={className({
                      'pt-4 pb-4': true,
                      'text-danger': d.auditLogRequestTypeEnum === 'EXCEPTION',
                    })}
                  >
                    {d.shortDescription}
                  </td>
                  <td
                    className={className({
                      'text-primary pt-4 pb-4': true,
                      'text-danger': d.auditLogRequestTypeEnum === 'EXCEPTION',
                    })}
                  >
                    {d.description}
                  </td>
                  <td
                    className={className({
                      'pt-4 pb-4': true,
                      'text-danger': d.auditLogRequestTypeEnum === 'EXCEPTION',
                    })}
                  >
                    {d.auditLogRequestTypeEnum === 'EXCEPTION' ? (
                      <p className="text-danger">neúspech</p>
                    ) : (
                      <p className="text-success">úspech</p>
                    )}
                    {/* <AuditLogInfoButton id={d.id} /> */}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : null}
    </>
  );
};
export default AuditLog;
