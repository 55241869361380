import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import {selectors} from '../../../../features/auctionRounds';
import {selectors as selectorToken} from '../../../../features/token';
import loadActiveAuctionRound from '../../../../services/loadActiveAuctionRound';
// import {
//   AddRoundButton as AddRoundBtn,
//   EditRoundButton as EditRoundBtn,
//   DeleteRoundButton as DeleteRoundBtn,
// } from '../../../Modals/Round';
import InfoPopover from '../../../Popover';
import InfoPopoverTexts from '../../../Popover/InfoPopoverTexts';
import EditRoundForm from './EditRoundForm';
import AuctioStarted from '../../AuctionStarted';
import RenewTypeEnum from '../../../enums/RenewTypeEnum';

const Rounds = () => {
  const dispatch = useDispatch();

  const rounds = useSelector(selectors.getAuctionRounds);
  // const editRoundInitialValues = useSelector(selectors.getEditRoundFormInitial);
  const isLoading = useSelector(selectors.getLoading);
  /* TODO zmenit potom */
  const token = useSelector(selectorToken.getToken);
  const {auctionId} = useParams();
  useEffect(() => {
    loadActiveAuctionRound(auctionId, token, dispatch);
  }, [auctionId, dispatch, token]);
  let initValue;
  if (rounds != null) {
    initValue = {...rounds.auctionRound};
    if (initValue.renewType == null) {
      // eslint-disable-next-line prefer-destructuring
      initValue.renewType = Object.entries(RenewTypeEnum).find(
        e => e[1] === RenewTypeEnum.PRICE_CHANGE
      )[0];
    }
  }
  return (
    <>
      <div className="bg-white col-lg-9 mt-5 mb-5 rounded pl-0 pr-0">
        <div className="border-bottom">
          <div className="col-lg-12 p-0">
            <h1 className="h4 font-weight-bold p-5 m-0">
              Aukčné kolo
              <InfoPopover
                placement="bottom"
                text={InfoPopoverTexts.AUCTION_ROUND.HEADER_NAME}
              />
            </h1>
          </div>
        </div>
        <AuctioStarted />
        {isLoading ? (
          <div className="p-5">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <EditRoundForm id={auctionId} initialValues={initValue} />
        )}
      </div>
    </>
  );
};

export default Rounds;
