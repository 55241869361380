import React, {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
// import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Button from 'react-bootstrap/Button';
import EditPenIcon from '../../MenuIcons/EditPenIcon';
import EditOffersModal from './EditOffersModal';
import {actionTypes} from '../../../features/offers';
import {selectors as selectorToken} from '../../../features/token';
import loadOffersByOrganization from '../../../services/loadOffersByOrganization';

function EditOffersButton(props) {
  const {id} = props;
  const [modalShow, setModalShow] = useState(false);
  const dispatch = useDispatch();
  const {auctionId} = useParams();
  const token = useSelector(selectorToken.getToken);
  // const selectedOffers = useSelector(selectors.getSelectedOffers);
  const handleEdit = e => {
    e.preventDefault();
    loadOffersByOrganization(auctionId, props.id, token, dispatch);
    setModalShow(true);
  };
  return (
    <>
      <Button
        className="mr-2 text-nowrap"
        onClick={handleEdit}
        // onKeyDown={handleEdit}
        // role="button"
        // tabIndex={0}
      >
        <EditPenIcon className="mr-2 icon-white" />
        Upraviť ponuku
      </Button>
      <EditOffersModal
        id={id}
        show={modalShow}
        onHide={() => {
          dispatch({
            type: actionTypes.OFFERS_SELECTED_OFFERS_NULL,
          });
          setModalShow(false);
        }}
        setModalShow={setModalShow}
      />
    </>
  );
}
export default EditOffersButton;
