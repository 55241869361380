import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Form from 'react-bootstrap/Form';
import {reduxForm, Field, setSubmitFailed} from 'redux-form';
import {useParams} from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import FIELD_NAMES from './AuctionFormFieldNames';
import ErrorFormText from '../../../Modals/Error/ErrorFormText';
import {
  BootstrapFormControl,
  BootstrapFormCheck,
  CPVcodeSingleMaterialAutocomplete,
  CPVcodeMultipleMaterialAutocomplete,
} from '../../../ReduxBootstrapFields';
import {selectors} from '../../../../features/cpvcodes';
import {selectors as selectorToken} from '../../../../features/token';
import loadAllCPVCodes from '../../../../services/loadAllCPVCodes';
import InfoPopoverText from '../../../Popover/InfoPopoverTexts';
import SubmitFormValidator, {
  normalizePositiveOrZeroFloat,
  required,
} from '../../../Utils/SubmitFormValidator';
import {selectors as auctionSettingsSelectors} from '../../../../features/auctionSettings';
import {requestDTO} from '../../../../services';
import defineAuctionSettings from '../../../../services/defineAuctionSettings';
import {selectors as selectorUAD} from '../../../../features/userAuctionData';
import RoundLifeCycleEnum from '../../../enums/RoundLifeCycleEnum';

export const FORM_NAME = 'auctionForm';

const warn = values => {
  const warnings = {};

  return warnings;
};

const AuctionForm = () => {
  const dispatch = useDispatch();
  const cpvCodes = useSelector(selectors.getValues);
  const token = useSelector(selectorToken.getToken);
  const form = useSelector(state => state.form[FORM_NAME]);
  const auctionSettings = useSelector(
    auctionSettingsSelectors.getAuctionSettings
  );
  const auctionData = useSelector(selectorUAD.getValues);
  const {auctionId} = useParams();
  useEffect(() => {
    loadAllCPVCodes(token, dispatch, undefined, undefined, undefined);
  }, [dispatch, token]);

  const handleClick = e => {
    e.preventDefault();
    if (SubmitFormValidator(form)) {
      dispatch(setSubmitFailed(FORM_NAME, Object.keys(form.syncErrors)));
      return;
    }
    defineAuctionSettings(
      auctionId,
      requestDTO.createDefineAuctionSettingsRequest(
        form.values,
        auctionId,
        auctionSettings
      ),
      token,
      dispatch,
      undefined,
      undefined,
      undefined
    );
  };

  return (
    <Form className="pl-4 pr-4 pb-5">
      <Form.Group className="pl-4 pr-4" controlId={FIELD_NAMES.NAME}>
        <Form.Label>Názov zakázky</Form.Label>
        <Field
          disabled
          name={FIELD_NAMES.NAME}
          component={BootstrapFormControl}
          text={InfoPopoverText.ORDER.NAME}
          type="string"
          placeholder="Názov"
          validate={[required]}
          maxLength="298"
        />
      </Form.Group>
      {/* <Form.Group className="pl-4 pr-4" controlId={FIELD_NAMES.EVALCRITERIUM}>
        <Form.Label>Hodnotiace kritérium</Form.Label>
        <Field
          name={FIELD_NAMES.EVALCRITERIUM}
          as="select"
          component={BootstrapDropDownControl}
          text={InfoPopoverText.AUCTION.EVALCRITERIUM}
        >
          {Object.entries(EvalEnum).map(pair => (
            <option key={pair[0]} value={pair[0]}>
              {pair[1]}
            </option>
          ))}
        </Field>
      </Form.Group> */}
      {/* <div className="rounded-lg bg-blue5">
        <Form.Group className="pl-4 pr-4" controlId={FIELD_NAMES.DECREASEVALUE}>
          <Form.Label>Krok zniženia</Form.Label>
          <Field
            name={FIELD_NAMES.DECREASEVALUE}
            as="select"
            label="Krok zniženia"
            component={BootstrapDropDownControl}
            text={InfoPopoverText.AUCTION.DECREASEVALUE}>
            {Object.entries(DecreaseValueEnum).map(pair => (
              <option key={pair[0]} value={pair[0]}>
                {pair[1]}
              </option>
            ))}
          </Field>
        </Form.Group>
        {form.values != undefined &&
        form.values[FIELD_NAMES.DECREASEVALUE] ===
          Object.entries(DecreaseValueEnum).find(
            e => e[1] === DecreaseValueEnum.PERCENTAGE
          )[0] ? (
          <>
            <Form.Group
              className="pl-4 pr-4"
              controlId={FIELD_NAMES.MINDECREASEVALUE_PERCENTAGE}>
              <Form.Label>Minimálny krok zníženia [%]</Form.Label>
              <Field
                name={FIELD_NAMES.MINDECREASEVALUE_PERCENTAGE}
                type="number"
                component={BootstrapFormControl}
                text={InfoPopoverText.AUCTION.MINDECREASEVALUE_PERCENTAGE}
                validate={maxValue100}
                normalize={normalizePositiveOrZeroFloat}
              />
            </Form.Group>
            <Form.Group
              className="pl-4 pr-4"
              controlId={FIELD_NAMES.MAXDECREASEVALUE_PERCENTAGE}>
              <Form.Label>Maximálny krok zníženia [%]</Form.Label>
              <Field
                name={FIELD_NAMES.MAXDECREASEVALUE_PERCENTAGE}
                type="number"
                component={BootstrapFormControl}
                text={InfoPopoverText.AUCTION.MAXDECREASEVALUE_PERCENTAGE}
                validate={maxValue100}
                normalize={normalizePositiveOrZeroFloat}
              />
            </Form.Group>
          </>
        ) : (
          <>
            <Form.Group
              className="pl-4 pr-4"
              controlId={FIELD_NAMES.MINDECREASEVALUE_FIAT}>
              <Form.Label>Minimálny krok zníženia ceny v mene [EUR]</Form.Label>
              <Field
                name={FIELD_NAMES.MINDECREASEVALUE_FIAT}
                type="number"
                component={BootstrapFormControl}
                text={InfoPopoverText.AUCTION.MINDECREASEVALUE_FIAT}
                normalize={normalizePositiveOrZeroFloat}
              />
            </Form.Group>
            <Form.Group
              className="pl-4 pr-4"
              controlId={FIELD_NAMES.MAXDECREASEVALUE_FIAT}>
              <Form.Label>Maximálny krok zníženia ceny v mene [EUR]</Form.Label>
              <Field
                name={FIELD_NAMES.MAXDECREASEVALUE_FIAT}
                type="number"
                component={BootstrapFormControl}
                text={InfoPopoverText.AUCTION.MAXDECREASEVALUE_FIAT}
                normalize={normalizePositiveOrZeroFloat}
              />
            </Form.Group>
          </>
        )}
      </div> */}
      <Form.Group className="pl-4 pr-4" controlId={FIELD_NAMES.ESTIMATEDVALUE}>
        <Form.Label>Predpokladaná hodnota zakázky</Form.Label>
        <Field
          name={FIELD_NAMES.ESTIMATEDVALUE}
          type="number"
          disabled
          component={BootstrapFormControl}
          text={InfoPopoverText.AUCTION.ESTIMATEDVALUE}
          normalize={normalizePositiveOrZeroFloat}
        />
      </Form.Group>
      <Form.Group
        className="pl-4 pr-4"
        controlId={FIELD_NAMES.SHOWWINNINGOFFERS}
      >
        <Field
          name={FIELD_NAMES.SHOWWINNINGOFFERS}
          type="checkbox"
          text={InfoPopoverText.AUCTION.SHOWWINNINGOFFERS}
          custom
          component={BootstrapFormCheck}
          label="Zobraziť víťazné ponuky"
        />
      </Form.Group>
      {cpvCodes != null ? (
        <>
          <Form.Group className="pl-4 pr-4" controlId={FIELD_NAMES.CPVCODE}>
            <Form.Label>Hlavný CPV kód</Form.Label>
            <Field
              id="combo-box-demo"
              options={cpvCodes}
              getOptionLabel={option => `${option.sk} - (${option.cpv})`}
              style={{width: '100%'}}
              name={FIELD_NAMES.CPVCODE}
              type="text"
              component={CPVcodeSingleMaterialAutocomplete}
              text={InfoPopoverText.AUCTION.CPVCODE}
            />
          </Form.Group>
          <Form.Group className="pl-4 pr-4" controlId={FIELD_NAMES.CPVCODES}>
            <Form.Label>Doplnkové CPV kódy</Form.Label>
            <Field
              multiple
              filterSelectedOptions
              id="tags-standart"
              options={cpvCodes}
              getOptionLabel={option => `${option.sk} - (${option.cpv})`}
              name={FIELD_NAMES.CPVCODES}
              style={{width: '100%'}}
              type="text"
              component={CPVcodeMultipleMaterialAutocomplete}
              text={InfoPopoverText.AUCTION.CPVCODES}
            />
          </Form.Group>
        </>
      ) : (
        ''
      )}
      {auctionData != null &&
      auctionData.roundEnum === RoundLifeCycleEnum.PREPARATION_PHASE ? (
        <div className="text-right">
          <ErrorFormText formName={FORM_NAME} />
          <Button
            onClick={handleClick}
            variant="success"
            type="submit"
            className="ml-5 mr-5 mb-5 mt-0 pt-3 pb-3 pl-5 pr-5"
          >
            Uložiť zmeny
          </Button>
        </div>
      ) : null}
    </Form>
  );
};

export default reduxForm({
  form: FORM_NAME,
  warn,
})(AuctionForm);
