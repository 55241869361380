import React from 'react';

function DiamondIcon(props) {
  return (
    <svg
      {...props}
      version="1.1"
      id="IconsRepoEditor"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 7 10"
      xmlSpace="preserve"
      width={props.width != null ? props.width : '7px'}
      height={props.height != null ? props.height : '10px'}
      fill="none"
      strokeWidth="0"
      className={props.className != null ? props.className : null}
    >
      <path
        d="M3.65162 2L0.671875 5.5L3.65162 9L6.63139 5.5L3.65162 2Z"
        fill={props.fill != null ? props.fill : '#3E97EF'}
      />
    </svg>
  );
}
export default DiamondIcon;
