export default {
  DECIMALCOUNT: 'decimalCount',
  MAXIMALVALUE: 'maximalValue',
  MINIMALVALUE: 'minimalValue',
  MINVAULECHANGE: 'minVauleChange',
  MAXVAULECHANGE: 'maxVauleChange',
  MAXIMUMPOINTS: 'maximumPoints',
  NATURE: 'nature',
  TAXINCLUSION: 'taxInclusion',
  ID: 'id',
  IPADRESS: 'ipAdress',
  NAME: 'name',
  TYPE: 'type',
  UNIT: 'unit',
  CUSTOM_UNIT: 'custom_unit',
  UUID: 'uuid',
  ITEM: 'item',
};
