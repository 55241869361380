import axios from 'axios';
import {toast} from 'react-toastify';
import settings from './settings';
// import {actionTypes as actionTypesToken} from '../features/token';
import ToastUtil from '../components/Toast/ToastUtil';
import Text from '../components/Toast/ToastTexts';
import {actionTypes as errorActionType} from '../features/modals';
import loadAuction from './loadAuction';

const deleteAuctionCriteria = (
  auctionId,
  criteriaId,
  criteriaName,
  token,
  dispatch,
  thenCallback,
  catchCallback,
  finallyCallback
) => {
  axios
    .post(
      settings.SERVICE_URL + settings.SERVICES.DELETE_AUCTION_CRITERIA,
      {
        auctionId,
        criteriaId,
      },
      {
        responseType: 'json',
        headers: {
          'Ela-Elc-Auth': token,
        },
      }
    )
    .then(response => {
      toast.success(
        ToastUtil(
          Text.DELETE_AUCTION_CRITERIA.TITLE,
          Text.DELETE_AUCTION_CRITERIA.DESCRIPTION + criteriaName
        )
      );
      loadAuction(auctionId, token, dispatch, undefined, undefined, () => {
        if (typeof thenCallback === 'function') {
          thenCallback(response);
        }
      });
    })
    .catch(error => {
      // handle error
      // console.log(error);
      if (error.response != null && error.response.data != null) {
        const {data} = error.response;
        dispatch({
          type: errorActionType.MODAL_ERROR_SHOW,
          modal: {title: data.message, description: data.description},
        });
      }
      if (typeof catchCallback === 'function') {
        catchCallback(error);
      }
    })
    .finally(() => {
      if (typeof finallyCallback === 'function') {
        finallyCallback();
      }
    });
};

export default deleteAuctionCriteria;
