import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Nav from 'react-bootstrap/Nav';
import {Link, useRouteMatch} from 'react-router-dom';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Table from 'react-bootstrap/Table';
import Logo from '../../images/face.png';
import logout from '../../services/logout';
import userAuctionData from '../../services/loadUserAuctionData';
// import {selectors as selectorAuction} from '../../features/auction';
// import {selectors as selectorWS} from '../../features/websocket';
import {selectors as selectorToken} from '../../features/token';
import {selectors as selectorUAD} from '../../features/userAuctionData';
import CountdownTimer from '../../auctionhall/CountdownTimer';
import tokenValidation from '../../services/tokenValidation';
import HammerIcon from '../MenuIcons/HammerIcon/HammerIcon';
import ALogo from '../../images/logo.png';
import RoundLifeCycleEnum from '../enums/RoundLifeCycleEnum';

const TopNavigation = () => {
  const dispatch = useDispatch();
  const match = useRouteMatch('/:auctionId');
  const token = useSelector(selectorToken.getToken);
  const roleString = useSelector(selectorUAD.getRoleAsString);
  // const username = useSelector(selectorToken.getUsername);
  const {auctionId} = match.params;
  // const criteriaOffer = useSelector(selectorWS.getValues);
  // const auction = useSelector(selectorAuction.getAuction);
  const auctionData = useSelector(selectorUAD.getValues);
  const formatTwoDigits = digit => `0${digit}`.slice(-2);
  let date = null;
  if (auctionData != null) {
    const tempDate = new Date(auctionData.auctionStartTime);
    date = `${formatTwoDigits(tempDate.getDate())}.${formatTwoDigits(
      tempDate.getMonth() + 1
    )}.${tempDate.getFullYear()} ${formatTwoDigits(
      tempDate.getHours()
    )}:${formatTwoDigits(tempDate.getMinutes())}`;
  }
  useEffect(() => {
    tokenValidation(token, dispatch);
  }, [dispatch, token]);

  useEffect(() => {
    userAuctionData(auctionId, token, dispatch);
  }, [auctionId, dispatch, token]);

  return (
    <>
      <div className="top-navbar">
        <div className="img-width bg-dark text-center">
          <a
            href="https://tenderia.sk"
            className="logo-auc"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={ALogo} className="p-2" alt="" />
          </a>
        </div>
        {auctionData != null &&
        auctionData.roundEnum !== RoundLifeCycleEnum.PREPARATION_PHASE ? (
          <>
            {auctionData != null &&
            auctionData.roundEnum !== RoundLifeCycleEnum.END_PHASE ? (
              <div className="card-body p-0 ml-1 mr-1 fixed-width">
                {auctionData != null &&
                auctionData.roundEnum === RoundLifeCycleEnum.STOPPED_PHASE ? (
                  <div className="col-lg-12 card-body p-1 m-0">
                    <p className="text-danger h6 mb-0">Aukcia bola zastavená</p>
                  </div>
                ) : (
                  <div className="col-lg-12 card-body p-1 m-0">
                    <div className="col-lg-12 pt-2 pl-3 pr-0 pb-0">
                      {auctionData.roundEnum ===
                      RoundLifeCycleEnum.STARTED_PHASE ? (
                        <p className="text-blue2 bold-wight h6 mb-0">
                          Aukcia začína za
                        </p>
                      ) : (
                        <p className="text-blue2 bold-wight h6 mb-0">
                          Aukcia končí za
                        </p>
                      )}
                    </div>
                    <Table
                      responsive
                      className="col-lg-12 overflow-hidden pt-2"
                    >
                      <thead className="bg-white border-0">
                        {auctionData != null ? (
                          <tr className="bg-white text-blue2 border-0 text-center">
                            {auctionData.roundEnum ===
                            RoundLifeCycleEnum.STARTED_PHASE ? (
                              <CountdownTimer
                                className="bg-white text-black border-0 h4 time p-0"
                                criteria={auctionData.auctionStartTime}
                              />
                            ) : (
                              <CountdownTimer
                                className="bg-white text-black border-0 h4 time p-0"
                                criteria={auctionData.auctionEndTime}
                              />
                            )}
                          </tr>
                        ) : null}
                      </thead>
                      <tbody></tbody>
                      {/* {criteriaOffer != null
                ? new Date(criteriaOffer.auctionStartTime).toLocaleString()
                : ''} */}
                    </Table>
                  </div>
                )}
              </div>
            ) : null}
          </>
        ) : null}
        <div className=" card-body p-0 border-left">
          <div className="col-lg-12 ml-1 mr-1 card-body p-1 m-0">
            <div className="col-lg-12 pl-3 pt-2 pr-0 pb-0">
              <p className="text-blue2 bold-wight h6 mb-0">Názov zakázky</p>
            </div>
            <p className="h4 pt-0 label-nazov pl-3">
              {auctionData != null ? auctionData.auctionName : null}
            </p>
          </div>
        </div>
        {auctionData != null &&
        auctionData.roundEnum !== RoundLifeCycleEnum.PREPARATION_PHASE ? (
          <div className=" card-body p-0 fixed-width">
            <Nav.Link
              className="card-body bg-success2 p-1 m-0"
              as={Link}
              to={`/${auctionId}/auction_hall/owner`}
            >
              <div className="col-lg-12 p-0 row m-0">
                <div className="col-lg-4 p-0 text-center">
                  <HammerIcon className="m-2" />
                </div>
                <div className="col-lg-8 p-0">
                  <p className="text-white bold-wight float-left h6 mb-1 mt-0">
                    Navštíviť aukčnú sieň
                  </p>
                  <p className="text-dark bold-wight float-left m-0 p-0">
                    Začiatok aukcie
                  </p>
                  <p className="card-text bold-wight float-left text-white col-lg-12 p-0 mt-1 text-nowrap">
                    {auctionData != null ? date : ''}
                    {/* {criteriaOffer != null ? (
                <CountdownTimer
                  className="text-dark h6"
                  criteria={criteriaOffer}
                />
              ) : (
                ''
              )}  */}
                  </p>
                </div>
              </div>
            </Nav.Link>
          </div>
        ) : null}
        <div className="no-gutters row border-left fixed-width">
          <div className="col-lg-3 card-body pr-0 pt-0 pb-0 align-self-center">
            <img
              src={Logo}
              className="img-fluid align-self-center float-right img-thumbnail rounded-circle float-right"
              alt=""
            />
          </div>
          <div className="card-body col-lg-9 p-0 align-self-center">
            <p className="text-blue2 font-weight-bolder mt-2 ml-3 mb-0 align-self-center">
              {auctionData != null ? auctionData.userName : null}
            </p>
            <h6 className="card-text font-weight-lighter text-muted text-nowrap ml-3 align-self-center">
              {roleString != null ? roleString : null}
            </h6>
          </div>
        </div>
        <div className="pr-0">
          <button
            onClick={e => {
              logout(token, dispatch);
            }}
            type="button"
            className="card-body btn btn-danger rounded-0 p-2"
            style={{height: '60px'}}
          >
            <ExitToAppIcon className="m-0" />
            <h6>odhlásiť</h6>
          </button>
        </div>
      </div>
    </>
  );
};

export default TopNavigation;
