import axios from 'axios';
import {toast} from 'react-toastify';
import settings from './settings';

import loadAuction from './loadAuction';
import ToastUtil from '../components/Toast/ToastUtil';
import {actionTypes as errorActionType} from '../features/modals';
import {actionTypes} from '../features/auction';
import Text from '../components/Toast/ToastTexts';

const editAuctionItems = (
  auctionId,
  request,
  token,
  dispatch,
  thenCallback,
  catchCallback,
  finallyCallback,
  editedItem,
  type
) => {
  axios
    .post(
      settings.SERVICE_URL + settings.SERVICES.EDIT_AUCTION_ITEMS,
      request,
      {
        responseType: 'json',
        headers: {
          'Ela-Elc-Auth': token,
        },
      }
    )
    .then(response => {
      // console.log(response.data);

      // Volame reload roundov
      loadAuction(auctionId, token, dispatch, undefined, undefined, () => {
        if (typeof thenCallback === 'function') {
          thenCallback(response);
        }
        switch (type) {
          case 'ADD':
            toast.success(
              ToastUtil(
                Text.ADD_AUCTION_ITEMS.TITLE,
                Text.ADD_AUCTION_ITEMS.DESCRIPTION + editedItem.name
              )
            );
            break;
          case 'EDIT':
            toast.success(
              ToastUtil(
                Text.EDIT_AUCTION_ITEMS.TITLE,
                Text.EDIT_AUCTION_ITEMS.DESCRIPTION + editedItem.name
              )
            );
            break;
          case 'DELETE':
            toast.success(
              ToastUtil(
                Text.DELETE_AUCTION_ITEMS.TITLE,
                Text.DELETE_AUCTION_ITEMS.DESCRIPTION + editedItem
              )
            );
            break;
          default:
            break;
        }
      });
    })
    .catch(error => {
      const {data} = error.response;
      dispatch({
        type: errorActionType.MODAL_ERROR_SHOW,
        modal: {title: data.statusCode, description: data.description},
      });
      if (typeof catchCallback === 'function') {
        catchCallback(error);
      }
    })
    .finally(() => {
      dispatch({
        type: actionTypes.AUCTION_ITEM_HIDE_LOADING,
      });
      if (typeof finallyCallback === 'function') {
        finallyCallback();
      }
    });
};

export default editAuctionItems;
