/* eslint-disable no-underscore-dangle */
import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import {useSelector, useDispatch} from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import loadAuction from '../../../services/loadAuction';
import {selectors as selectorToken} from '../../../features/token';
import UnitEnum from '../../enums/UnitEnum';
import {selectors as selectorUAD} from '../../../features/userAuctionData';
import {
  AddItemButton as AddBtn,
  EditItemButton as EditBtn,
  DeleteItemButton as DelBtn,
} from '../../Modals/Items';
import InfoPopover from '../../Popover';
import InfoPopoverTexts from '../../Popover/InfoPopoverTexts';
import {selectors} from '../../../features/auction';
import AuctionStarted from '../AuctionStarted';
import RoundLifeCycleEnum from '../../enums/RoundLifeCycleEnum';

const Items = () => {
  const dispatch = useDispatch();
  const auction = useSelector(selectors.getAuction);
  const token = useSelector(selectorToken.getToken);
  const {auctionId} = useParams();
  const auctionData = useSelector(selectorUAD.getValues);

  useEffect(() => {
    loadAuction(auctionId, token, dispatch);
  }, [auctionId, dispatch, token]);

  const unit = d => {
    const custom = Object.keys(UnitEnum).find(
      k => UnitEnum[k] === UnitEnum.CUSTOM
    );
    if (d.unit === null) {
      return '-';
    } else if (d.unit === custom) {
      return d.custom_unit;
    } else {
      return UnitEnum[d.unit] === undefined ? d.unit : UnitEnum[d.unit];
    }
  };
  return (
    <>
      <div className="bg-white col-lg-12 mt-5 mb-5 rounded pl-0 pr-0">
        <div className="border-bottom">
          <div className="col-lg-12 p-0">
            <h1 className="h4 font-weight-bold p-5 m-0">
              Položky{' '}
              <InfoPopover
                placement="bottom"
                text={InfoPopoverTexts.ITEMS.HEADER_NAME}
              />
            </h1>
          </div>
        </div>
        <AuctionStarted />
        {auction != null ? (
          <>
            <div className="col-lg-12 row">
              <div className="col-lg-12 pt-4 pb-2">
                {auctionData != null &&
                auctionData.roundEnum ===
                  RoundLifeCycleEnum.PREPARATION_PHASE ? (
                  <AddBtn />
                ) : null}
              </div>
            </div>
            <div className="table-container">
              <Table striped hover responsive>
                <thead className="bg-white">
                  <tr className="bg-white text-blue">
                    <th>Názov</th>
                    <th>Množstvo</th>
                    <th>Jednotka</th>
                    <th>Kritéria</th>
                    <th>Akcie</th>
                  </tr>
                </thead>
                <tbody>
                  {auction != null
                    ? auction.items.map(d => (
                        <tr key={d.id}>
                          <td>{d.name}</td>
                          <td>{d.amount}</td>
                          <td>{unit(d)}</td>
                          <td>
                            {d._criteria == null || d._criteria.length === 0
                              ? 'nezadané'
                              : d._criteria.map((item, i) => (
                                  <span key={item}>
                                    {
                                      auction.criteria.find(e => e.id === item)
                                        ?.name
                                    }
                                    {i !== d._criteria.length - 1 ? ', ' : null}
                                  </span>
                                ))}
                          </td>
                          <td>
                            <EditBtn id={d.id} />
                            {auctionData != null &&
                            auctionData.roundEnum ===
                              RoundLifeCycleEnum.PREPARATION_PHASE ? (
                              <DelBtn id={d.id} name={d.name} />
                            ) : null}
                          </td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </Table>
            </div>
          </>
        ) : (
          <div className="p-5">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        )}
      </div>
    </>
  );
};

export default Items;
