/* eslint-disable no-nested-ternary */
import React from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import {Field} from 'redux-form';
import Button from 'react-bootstrap/Button';
import ProgressBar from 'react-bootstrap/ProgressBar';
import className from 'classnames';
import {BootstrapFormControlWithoutPopover} from '../../../components/ReduxBootstrapFields';
import {normalizePositiveOrZeroFloat} from '../../../components/Utils/SubmitFormValidator';
import UnitEnum from '../../../components/enums/UnitEnum';
import NatureEnum from '../../../components/enums/NatureEnum';
import GraphUpIcon from '../../../components/MenuIcons/GraphUpIcon';
import GraphDownIcon from '../../../components/MenuIcons/GraphDownIcon';
import DiamondIcon from '../../../components/MenuIcons/DiamondIcon';
import ClubsIcon from '../../../components/MenuIcons/ClubsIcon';
import CollapseHidder from '../../../components/CollapseHidder';

const TEXT = 'Minimálna hodnota zmeny kritéria je ';
const TEXTMAX = 'Maximálna hodnota zmeny kritéria je ';

const RenderMembers = ({
  criteria,
  criteriaOffersValues,
  showOffers,
  fields,
  points,
  meta: {touched, error, submitFailed},
  changeFieldValue,
  dispatchFormErrors,
  dispatchTouch,
  criteriaPriceOffersFormValues,
  formErrors,
}) => {
  return (
    <div className="row col-lg-12 m-0 pl-0 pr-0">
      {fields.map((criteriaField, index) => (
        <div
          key={criteriaField}
          className="col-lg-12 p-0 mb-3 bg-white rounded"
        >
          {criteriaOffersValues != null
            ? criteriaOffersValues.map((criteriaOfferValue, indexCrit) =>
                criteriaOfferValue.criteriaUuid === criteria[index].id ? (
                  <div
                    key={criteriaOfferValue.id}
                    className={className({
                      'row col-lg-12 m-0 pl-0 pr-0 striped-div': true,
                      'border-win':
                        criteriaOfferValue.bestOfferValue >=
                        criteriaOfferValue.lastOfferValue,
                      'border-lose':
                        criteriaOfferValue.bestOfferValue <
                        criteriaOfferValue.lastOfferValue,
                    })}
                  >
                    <div className="col-lg-3">
                      <div className="col-lg-12 mb-5">
                        <h4 className="mt-4 mb-3 bold-wight">
                          <DiamondIcon className="mr-2 mb-1" />
                          {criteriaOfferValue.name}
                        </h4>
                        <ul className="text-muted list-group list-group-flush">
                          <li className="list-group-item">
                            - Množstvo: {criteriaOfferValue.ks}
                          </li>
                          <li className="list-group-item">
                            - Mj: {criteriaOfferValue.mj}
                          </li>
                          <li className="list-group-item">
                            - Popis:{' '}
                            <CollapseHidder
                              info={criteriaOfferValue.shortDescription}
                              className="show-more"
                            />
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div
                      className={className({
                        'col-lg-2': showOffers != null && showOffers,
                        'col-lg-3': showOffers != null && !showOffers,
                      })}
                    >
                      <p className="h5 col-lg-12 mt-4 mb-4 font-weight-bold">
                        {/* {criteriaOfferValue.points}{' '} */}
                      </p>
                    </div>
                    {showOffers != null && showOffers ? (
                      <div className="col-lg-2 ">
                        <p className="h5 col-lg-12 mt-4 mb-4 font-weight-bold">
                          {criteriaOfferValue.bestOfferValue}{' '}
                          {Object.entries(UnitEnum).find(
                            e => e[0] === criteriaOfferValue.mj
                          ) === undefined
                            ? criteriaOfferValue.mj
                            : Object.entries(UnitEnum).find(
                                e => e[0] === criteriaOfferValue.mj
                              )[1]}
                        </p>
                      </div>
                    ) : null}
                    <div
                      className={className({
                        'col-lg-2 align-self-center':
                          showOffers != null && showOffers,
                        'col-lg-3 align-self-center':
                          showOffers != null && !showOffers,
                      })}
                    >
                      <p
                        className={className({
                          'h5 col-lg-12 mt-4 mb-4 font-weight-bold p-0': true,
                          'text-danger':
                            criteriaOfferValue.bestOfferValue <
                            criteriaOfferValue.lastOfferValue,
                          'text-success':
                            criteriaOfferValue.bestOfferValue >=
                            criteriaOfferValue.lastOfferValue,
                        })}
                      >
                        {criteriaOfferValue.lastOfferValue}{' '}
                        {Object.entries(UnitEnum).find(
                          e => e[0] === criteriaOfferValue.mj
                        ) === undefined
                          ? criteriaOfferValue.mj
                          : Object.entries(UnitEnum).find(
                              e => e[0] === criteriaOfferValue.mj
                            )[1]}
                      </p>
                    </div>
                    <div className="col-lg-3 row">
                      <Form.Group
                        controlId={`${criteriaOfferValue}.value`}
                        className="p-0 col-lg-10 pt-4"
                      >
                        <Field
                          name={`criteriaPriceOffers[${indexCrit}].value`}
                          component={BootstrapFormControlWithoutPopover}
                          className="mt-5 mb-5 p-0 "
                          type="text"
                          normalize={normalizePositiveOrZeroFloat}
                          placeholder="Ponuka"
                          required
                          onChange={(event, newValue, previousValue, name) => {
                            const valueIndexMatch = name.match(/(\d+)/g);
                            if (!valueIndexMatch) {
                              return;
                            }
                            const valueIndex = Number(valueIndexMatch[0]);

                            //

                            // Scitavanie hodnot
                            const sum = criteriaPriceOffersFormValues.reduce(
                              (prev, current, reduceIndex) => {
                                if (
                                  current.criteriaUuid !== criteria[index].id ||
                                  valueIndex === reduceIndex
                                ) {
                                  return prev;
                                }
                                return prev + Number(current.value);
                              },
                              Number(newValue)
                            );

                            /**
                             * Validacia
                             */

                            let err = {};
                            if (formErrors) {
                              err = {...formErrors};
                            }
                            if (!err.criteriaWsResponse) {
                              err.criteriaWsResponse = [];
                            }
                            if (criteria[index].nature === 'ASC') {
                              if (
                                Number(sum) >
                                criteria[index].lastOfferValue +
                                  criteria[index].maxVauleChange
                              ) {
                                err.criteriaWsResponse[index] = {
                                  value:
                                    TEXTMAX + criteria[index].maxVauleChange,
                                };
                              } else if (
                                Number(sum) <
                                criteria[index].lastOfferValue +
                                  criteria[index].minVauleChange
                              ) {
                                err.criteriaWsResponse[index] = {
                                  value: TEXT + criteria[index].minVauleChange,
                                };
                              } else if (err.criteriaWsResponse[index]) {
                                if (err.criteriaWsResponse.length - 1 > index) {
                                  err.criteriaWsResponse[index].value = null;
                                } else {
                                  err.criteriaWsResponse.splice(index, 1);
                                }
                              }
                            } else if (
                              Number(sum) >
                              criteria[index].lastOfferValue -
                                criteria[index].minVauleChange
                            ) {
                              err.criteriaWsResponse[index] = {
                                value: TEXT + criteria[index].minVauleChange,
                              };
                            } else if (
                              Number(sum) <
                              criteria[index].lastOfferValue -
                                criteria[index].maxVauleChange
                            ) {
                              err.criteriaWsResponse[index] = {
                                value: TEXTMAX + criteria[index].maxVauleChange,
                              };
                            } else if (err.criteriaWsResponse[index]) {
                              if (err.criteriaWsResponse.length - 1 > index) {
                                err.criteriaWsResponse[index].value = null;
                              } else {
                                err.criteriaWsResponse.splice(index, 1);
                              }
                            }
                            if (err.criteriaWsResponse.length === 0) {
                              delete err.criteriaWsResponse;
                            }
                            let isEmptyArray = true;
                            if (err.criteriaWsResponse !== undefined) {
                              err.criteriaWsResponse.forEach(crit => {
                                if (crit.value) {
                                  isEmptyArray = false;
                                }
                              });
                            }
                            if (!isEmptyArray) {
                              dispatchFormErrors(err);
                            } else {
                              dispatchFormErrors({});
                            }
                            dispatchTouch(`${criteriaField}.value`);
                            changeFieldValue(`${criteriaField}.value`, sum);
                          }}
                        />
                      </Form.Group>
                      <p className="h4 col-lg-2 mt-4 mb-4 font-weight-bold">
                        {Object.entries(UnitEnum).find(
                          e => e[0] === criteriaOfferValue.mj
                        ) === undefined
                          ? criteriaOfferValue.mj
                          : Object.entries(UnitEnum).find(
                              e => e[0] === criteriaOfferValue.mj
                            )[1]}
                      </p>
                    </div>
                    {criteriaOfferValue.bestOfferValue >=
                    criteriaOfferValue.lastOfferValue ? (
                      <div className="win-div">
                        <p>Vyhráva</p>
                      </div>
                    ) : (
                      <div className="lose-div">
                        <p>Prehráva</p>
                      </div>
                    )}
                  </div>
                ) : null
              )
            : null}
          <div className="row col-lg-12 m-0 pl-0 pr-0 bg-primary">
            <div className="col-lg-3">
              <div className="col-lg-12">
                <h4 className="mt-4 mb-0 text-white">
                  <ClubsIcon fill="white" className="mr-1 mb-1" />
                  {criteria[index].name}
                  {criteria[index].nature !== null &&
                  Object.entries(NatureEnum).find(
                    e => e[0] === criteria[index].nature
                  ) !== undefined ? (
                    NatureEnum[criteria[index].nature] === NatureEnum.ASC ? (
                      <GraphUpIcon fill="#A8D3FF" className="ml-3 mb-1" />
                    ) : (
                      <GraphDownIcon fill="#A8D3FF" className="ml-3 mb-1" />
                    )
                  ) : null}
                </h4>
                <div className="row pt-2">
                  <div className="col-lg-4 pr-0">
                    <p className="mt-0 text-white pl-3">
                      {'Váha '}
                      {criteria[index].maximumPoints}/100
                      {/* |
              {criteria[index].isNonAuctionCriterium
                ? 'Nehodnotiace kritérium'
                : 'Hodnotiace kritérium'} */}
                    </p>
                  </div>
                  <div className="col-lg-2 p-0 mt-1">
                    <ProgressBar
                      variant="white"
                      className="rounded p-0"
                      height="5px"
                      now={criteria[index].maximumPoints}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              className={className({
                'align-self-center': true,
                'col-lg-2': showOffers != null && showOffers,
                'col-lg-3': showOffers != null && !showOffers,
              })}
            >
              <p className="h5 col-lg-12 pl-0 mt-4 mb-4 font-weight-bold text-white">
                {Number.parseFloat(criteria[index].points).toFixed(2)}{' '}
              </p>
            </div>
            {showOffers != null && showOffers ? (
              <div className="col-lg-2 bg-primary">
                <p className="h5 col-lg-12 mt-4 mb-4 font-weight-bold text-white">
                  {criteria[index].bestOfferValue}{' '}
                  {Object.entries(UnitEnum).find(
                    e => e[0] === criteria[index].mj
                  ) === undefined
                    ? criteria[index].mj
                    : Object.entries(UnitEnum).find(
                        e => e[0] === criteria[index].mj
                      )[1]}
                </p>
              </div>
            ) : null}
            <div
              className={className({
                'bg-primary align-self-center': true,
                'col-lg-2': showOffers != null && showOffers,
                'col-lg-3': showOffers != null && !showOffers,
              })}
            >
              <p
                className={className({
                  'h5 col-lg-12 mt-4 mb-4 font-weight-bold align-self-center pl-0': true,
                  'text-danger':
                    criteria[index].bestOfferValue <
                    criteria[index].lastOfferValue,
                  'text-success':
                    criteria[index].bestOfferValue >=
                    criteria[index].lastOfferValue,
                })}
              >
                {criteria[index].lastOfferValue}{' '}
                {Object.entries(UnitEnum).find(
                  e => e[0] === criteria[index].mj
                ) === undefined
                  ? criteria[index].mj
                  : Object.entries(UnitEnum).find(
                      e => e[0] === criteria[index].mj
                    )[1]}
              </p>
            </div>
            <div className="col-lg-3 pl-0 align-self-center">
              <Form.Group
                controlId={`${criteriaField}.value`}
                className="p-0 col-lg-10 pt-4"
              >
                <InputGroup className="mb-3">
                  <Field
                    name={`${criteriaField}.value`}
                    component={BootstrapFormControlWithoutPopover}
                    className="mt-5 mb-5 bg-primary"
                    type="text"
                    normalize={normalizePositiveOrZeroFloat}
                    placeholder="Ponuka"
                    disabled={
                      criteriaOffersValues.filter(
                        crit => crit.criteriaUuid === criteria[index].id
                      ).length > 0 || criteria[index].isNonAuctionCriterium
                    }
                    required
                    onChange={(event, newValue, previousValue, name) => {
                      const valueIndexMatch = name.match(/(\d+)/g);
                      if (!valueIndexMatch) {
                        return;
                      }
                      /**
                       * Validacia
                       */
                      let err = {};
                      if (formErrors) {
                        err = formErrors;
                      }
                      if (!err.criteriaWsResponse) {
                        err.criteriaWsResponse = [];
                      }
                      if (criteria[index].nature === 'ASC') {
                        if (
                          Number(newValue) >
                          criteria[index].lastOfferValue +
                            criteria[index].maxVauleChange
                        ) {
                          err.criteriaWsResponse[index] = {
                            value: TEXTMAX + criteria[index].maxVauleChange,
                          };
                        } else if (
                          Number(newValue) <
                          criteria[index].lastOfferValue +
                            criteria[index].minVauleChange
                        ) {
                          err.criteriaWsResponse[index] = {
                            value: TEXT + criteria[index].minVauleChange,
                          };
                        } else if (err.criteriaWsResponse[index]) {
                          if (err.criteriaWsResponse.length - 1 > index) {
                            err.criteriaWsResponse[index].value = null;
                          } else {
                            err.criteriaWsResponse.splice(index, 1);
                          }
                        }
                      } else if (
                        Number(newValue) >
                        criteria[index].lastOfferValue -
                          criteria[index].minVauleChange
                      ) {
                        err.criteriaWsResponse[index] = {
                          value: TEXT + criteria[index].minVauleChange,
                        };
                      } else if (
                        Number(newValue) <
                        criteria[index].lastOfferValue -
                          criteria[index].maxVauleChange
                      ) {
                        err.criteriaWsResponse[index] = {
                          value: TEXTMAX + criteria[index].maxVauleChange,
                        };
                      } else if (err.criteriaWsResponse[index]) {
                        if (err.criteriaWsResponse.length - 1 > index) {
                          err.criteriaWsResponse[index].value = null;
                        } else {
                          err.criteriaWsResponse.splice(index, 1);
                        }
                      }
                      if (err.criteriaWsResponse.length === 0) {
                        delete err.criteriaWsResponse;
                      }
                      let isEmptyArray = true;
                      if (err.criteriaWsResponse !== undefined) {
                        err.criteriaWsResponse.forEach(crit => {
                          if (crit.value) {
                            isEmptyArray = false;
                          }
                        });
                      }
                      if (!isEmptyArray) {
                        dispatchFormErrors(err);
                      } else {
                        dispatchFormErrors({});
                      }
                    }}
                  />
                  <InputGroup.Append>
                    <Button
                      onClick={e => {
                        changeFieldValue(
                          `${criteriaField}.value`,
                          criteria[index].nature === 'ASC'
                            ? criteria[index].lastOfferValue +
                                criteria[index].minVauleChange
                            : criteria[index].lastOfferValue -
                                criteria[index].minVauleChange
                        );
                      }}
                      variant="warning"
                    >
                      Nastaviť bid
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              </Form.Group>
              <p
                className="h4 col-lg-2 mt-4 mb-4 font-weight-bold"
                key={`mjOffer #${criteria[index].id + 1}`}
              ></p>
            </div>
          </div>
        </div>
      ))}
      <div className="row col-lg-12 m-0 pl-0 pr-0 mb-3 bg-blue2 rounded">
        <div className="col-lg-3 ">
          <div className="col-lg-12">
            <h4 className="mt-5 mb-5 text-white ml-3">Celkovo</h4>
            <p className="mt-0 text-white"></p>
          </div>
        </div>

        <div
          className={className({
            'col-lg-2': showOffers != null && showOffers,
            'col-lg-3': showOffers != null && !showOffers,
          })}
        >
          <div className="col-lg-12">
            <h5 className="mt-5 mb-5 text-white">
              {' '}
              {Number.parseFloat(points).toFixed(2)}/100.00
            </h5>
            <p className="mt-0 text-white"></p>
          </div>
        </div>
        {showOffers != null && showOffers ? (
          <div className="col-lg-2 ">
            <p className="h4 col-lg-12 mt-4 mb-4 font-weight-bold"></p>
          </div>
        ) : null}
        <div
          className={className({
            'text-center ': true,
            'col-lg-2': showOffers != null && showOffers,
            'col-lg-3': showOffers != null && !showOffers,
          })}
        >
          <p
            className={className({
              'h4 col-lg-12 mt-4 mb-4 font-weight-bold': true,
            })}
          ></p>
        </div>
        <div className="col-lg-3 row">
          <div className="p-0 col-lg-10"></div>
          <p className="h4 col-lg-2 mt-4 mb-4 font-weight-bold"></p>
        </div>
      </div>
    </div>
  );
};

export default RenderMembers;
