import React from 'react';
import {useParams} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';
import deleteAuctionItem from '../../../services/deleteAuctionItem';
import {selectors as selectorToken} from '../../../features/token';
import {actionTypes} from '../../../features/auctionRounds';
// import {selectors} from '../../../features/auction';

function DeleteItemModal(props) {
  const {id, name} = props;
  const dispatch = useDispatch();
  const {auctionId} = useParams();
  const token = useSelector(selectorToken.getToken);
  // const auction = useSelector(selectors.getAuction);

  const handleClick = e => {
    e.preventDefault();

    dispatch({
      type: actionTypes.ROUNDS_SHOW_LOADING,
    });
    deleteAuctionItem(
      auctionId,
      id,
      name,
      token,
      dispatch,
      undefined,
      undefined,
      undefined,
      id,
      'DELETE'
    );
    props.onHide();
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <div className="text-center text-danger container">
          <Modal.Title
            as="h4"
            id="contained-modal-title-vcenter"
            className="font-weight-bolder"
          >
            Vymazať záznam
          </Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body className="p-3">
        Naozaj chcete vymazať túto položku?
      </Modal.Body>
      <Modal.Footer className="border-top-0">
        <Button variant="danger" onClick={handleClick}>
          Áno
        </Button>
        <Button onClick={props.onHide}>Nie</Button>
      </Modal.Footer>
    </Modal>
  );
}
DeleteItemModal.propTypes = {
  onHide: PropTypes.func.isRequired,
};

export default DeleteItemModal;
