import axios from 'axios';
import settings from './settings';
import loadMenuState from './loadMenuState';
import {actionTypes as errorActionType} from '../features/modals';

const editStateMenu = (
  auctionId,
  request,
  token,
  dispatch,
  thenCallback,
  catchCallback,
  finallyCallback
) => {
  axios
    .post(settings.SERVICE_URL + settings.SERVICES.EDIT_MENU_STATE, request, {
      responseType: 'json',
      headers: {
        'Ela-Elc-Auth': token,
      },
    })
    .then(response => {
      // console.log(response.data);
      // Volame reload roundov
      loadMenuState(auctionId, token, dispatch, undefined, undefined, () => {
        if (typeof thenCallback === 'function') {
          thenCallback(response);
        }
      });
    })
    .catch(error => {
      // handle error
      // console.log(error);
      if (error.response != null && error.response.data != null) {
        const {data} = error.response;
        dispatch({
          type: errorActionType.MODAL_ERROR_SHOW,
          modal: {title: data.message, description: data.description},
        });
      }
      if (typeof catchCallback === 'function') {
        catchCallback(error);
      }
    })
    .finally(() => {
      if (typeof finallyCallback === 'function') {
        finallyCallback();
      }
    });
};

export default editStateMenu;
