/* eslint-disable prefer-destructuring */
import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import AuctionForm from './AuctionForm';
import {selectors} from '../../../../features/auctionSettings';
import {selectors as selectorToken} from '../../../../features/token';
import loadAuctionSettings from '../../../../services/loadAuctionSettings';
import InfoPopover from '../../../Popover';
import InfoPopoverTexts from '../../../Popover/InfoPopoverTexts';
import AuctioStarted from '../../AuctionStarted';
import DecreaseValueEnum from '../../../enums/DecreaseValueEnum';

const Auction = () => {
  const dispatch = useDispatch();

  const auctionSettings = useSelector(selectors.getAuctionSettings);
  const initialValues = useSelector(selectors.getAuctionSettingsFormInitial);
  const isLoading = useSelector(selectors.getLoading);
  const token = useSelector(selectorToken.getToken);
  const {auctionId} = useParams();
  const initValue = {...initialValues};
  if (
    initValue.minDecreaseValue_fiat === 0 &&
    initValue.maxDecreaseValue_fiat === 0
  ) {
    initValue.decreaseValue = Object.entries(DecreaseValueEnum).find(
      e => e[1] === DecreaseValueEnum.PERCENTAGE
    )[0];
  } else {
    initValue.decreaseValue = Object.entries(DecreaseValueEnum).find(
      e => e[1] === DecreaseValueEnum.EUR
    )[0];
  }

  useEffect(() => {
    loadAuctionSettings(auctionId, token, dispatch);
  }, [auctionId, dispatch, token]);
  return (
    <>
      <div className="bg-white col-lg-9 mt-5 mb-5 pb-0 rounded pl-0 pr-0 content-div">
        <div className="border-bottom">
          <div className="col-lg-12 p-0">
            <h1 className="h4 font-weight-bold p-5 m-0">
              Zakázka
              <InfoPopover
                placement="bottom"
                text={InfoPopoverTexts.AUCTION.HEADER_NAME}
              />
            </h1>
          </div>
        </div>
        <AuctioStarted />
        {isLoading ? (
          <div className="p-5">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        ) : (
          ''
        )}

        {auctionSettings != null ? (
          <AuctionForm initialValues={initValue} />
        ) : (
          ''
        )}
      </div>
    </>
  );
};

export default Auction;
