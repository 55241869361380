import {
  CPV_UPDATE_VALUES,
  CPV_SHOW_LOADING,
  CPV_HIDE_LOADING,
  CPV_SET_INITIAL_VALUES,
} from './actionTypes';

const initialState = {
  values: null,
  loading: false,
  initialValues: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CPV_SET_INITIAL_VALUES:
      return {...state, initialValues: action.payload};
    case CPV_UPDATE_VALUES:
      return {...state, values: action.payload.cpvCodes};
    case CPV_SHOW_LOADING:
      return {...state, loading: true};
    case CPV_HIDE_LOADING:
      return {...state, loading: false};
    default:
      return state;
  }
};
