/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner';
import {useSelector, useDispatch} from 'react-redux';
import loadAuction from '../../../services/loadAuction';
import {selectors as selectorToken} from '../../../features/token';
import NatureEnum from '../../enums/NatureEnum';
import InfoPopover from '../../Popover';
import InfoPopoverTexts from '../../Popover/InfoPopoverTexts';
import {selectors as selectorUAD} from '../../../features/userAuctionData';
import {
  AddCriteriaButton as AddBtn,
  EditCriteriaButton as EditBtn,
  DeleteCriteriaButton as DelBtn,
} from '../../Modals/Criteria';

import {selectors} from '../../../features/auction';
import AuctioStarted from '../AuctionStarted';
import RoundLifeCycleEnum from '../../enums/RoundLifeCycleEnum';
import CriteriaTypeEnum from 'components/enums/CriteriaTypeEnum';
// import GraphDownIcon from '../../MenuIcons/GraphDownIcon';
// import GraphUpIcon from '../../MenuIcons/GraphUpIcon';

const Criteria = () => {
  const dispatch = useDispatch();
  const auction = useSelector(selectors.getAuction);
  const token = useSelector(selectorToken.getToken);
  const {auctionId} = useParams();
  const auctionData = useSelector(selectorUAD.getValues);
  const maxPoints = useSelector(selectors.getMaxPoints);

  useEffect(() => {
    loadAuction(auctionId, token, dispatch);
  }, [auctionId, dispatch, token]);
  return (
    <>
      <div className="bg-white col-lg-12 mt-5 mb-5 rounded pl-0 pr-0">
        <div className="border-bottom">
          <div className="col-lg-12 p-0">
            <h1 className="h4 font-weight-bold p-5 m-0">
              Kritéria aukcie
              <InfoPopover
                placement="bottom"
                text={InfoPopoverTexts.CRITERIA.HEADER_NAME}
              />
            </h1>
          </div>
        </div>
        <AuctioStarted />
        {auction != null ? (
          <>
            <div className="col-lg-12 row">
              <div className="col-lg-12 pt-4 pb-2">
                {auctionData != null &&
                auctionData.roundEnum ===
                  RoundLifeCycleEnum.PREPARATION_PHASE ? (
                  <AddBtn />
                ) : null}
              </div>
            </div>
            <div className="table-container">
              <Table striped hover responsive>
                <thead className="bg-white">
                  <tr className="bg-white text-blue">
                    <th>Názov</th>
                    <th>Typ</th>
                    <th>Povaha</th>
                    <th>Interval</th>
                    <th>Interval zmeny</th>
                    <th>Maximálny počet bodov</th>
                    <th>Položky</th>
                    <th>Akcie</th>
                  </tr>
                </thead>
                <tbody>
                  {auction != null
                    ? auction.criteria.map(d => (
                        <tr key={d.id}>
                          <td>{d.name}</td>
                          <td>
                            {d.type !== null &&
                            Object.entries(CriteriaTypeEnum).find(
                              e => e[0] === d.type
                            ) !== undefined ? (
                              CriteriaTypeEnum[d.type] ===
                              CriteriaTypeEnum.price ? (
                                <>{CriteriaTypeEnum.price}</>
                              ) : (
                                <>{CriteriaTypeEnum.noprice}</>
                              )
                            ) : null}
                          </td>
                          <td>
                            {d.unit !== null &&
                            Object.entries(NatureEnum).find(
                              e => e[0] === d.nature
                            ) !== undefined ? (
                              NatureEnum[d.nature] === NatureEnum.ASC ? (
                                <>{NatureEnum.ASC}</>
                              ) : (
                                <>{NatureEnum.DESC}</>
                              )
                            ) : null}
                          </td>
                          <td>
                            &lt;
                            {d.minimalValue <= -9007199254740991 ||
                            d.minimalValue == null
                              ? '-∞'
                              : d.minimalValue}
                            ;
                            {d.maximalValue >= 9007199254740991 ||
                            d.maximalValue == null
                              ? '∞'
                              : d.maximalValue}
                            &gt;
                          </td>
                          <td>
                            &lt;
                            {d.minVauleChange <= -9007199254740991 ||
                            d.minVauleChange == null
                              ? '-∞'
                              : d.minVauleChange}
                            ;
                            {d.maxVauleChange >= 9007199254740991 ||
                            d.maxVauleChange == null
                              ? '∞'
                              : d.maxVauleChange}
                            &gt;
                          </td>
                          <td>{d.maximumPoints}</td>
                          <td>
                            {d._item == null || d._item.length === 0
                              ? 'nezadané'
                              : d._item.map((item, i) => (
                                  <span key={item}>
                                    {
                                      auction.items.find(e => e.id === item)
                                        ?.name
                                    }
                                    {i !== d._item.length - 1 ? ', ' : null}
                                  </span>
                                ))}
                          </td>
                          <td className="text-nowrap">
                            <EditBtn id={d.id} />
                            {auctionData != null &&
                            auctionData.roundEnum ===
                              RoundLifeCycleEnum.PREPARATION_PHASE ? (
                              <DelBtn id={d.id} name={d.name} />
                            ) : null}
                          </td>
                        </tr>
                      ))
                    : null}
                </tbody>
                <tfoot>
                  <tr>
                    <td></td>
                    <td></td>
                    <td>
                      {maxPoints === 100 ? (
                        <b className="text-success h6 font-weight-bold">
                          {maxPoints}/100
                        </b>
                      ) : (
                        <b className="text-danger h6 font-weight-bold">
                          {maxPoints}/100
                        </b>
                      )}
                    </td>
                    <td>
                      {' '}
                      {maxPoints === 100 ? (
                        <b className="text-success h6 text-bold">
                          kontrolný výpočet pre kritéria je správny
                        </b>
                      ) : (
                        <b className="text-danger h6 text-bold">
                          nesprávny kontrolný výpočet pre kritéria. Upravte body
                          v kritériách.
                        </b>
                      )}
                    </td>
                    <td></td>
                  </tr>
                </tfoot>
              </Table>
            </div>
          </>
        ) : (
          <div className="p-5">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        )}
      </div>
    </>
  );
};

export default Criteria;
