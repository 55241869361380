import axios from 'axios';
import settings from './settings';
import {actionTypes} from '../features/auditLog';
// import {actionTypes as actionTypesToken} from '../features/token';
import {actionTypes as errorActionType} from '../features/modals';

const loadAuditLogOffersByAuctionId = (
  id,
  token,
  dispatch,
  thenCallback,
  catchCallback,
  finallyCallback
) => {
  axios
    .post(
      settings.SERVICE_URL + settings.SERVICES.LOAD_AUDIT_LOG_OFFERS,
      {
        auctionId: id,
      },
      {
        responseType: 'json',
        headers: {
          'Ela-Elc-Auth': token,
        },
      }
    )
    .then(response => {
      // console.log(response.data);
      dispatch({
        type: actionTypes.HISTORY_OFFERS_UPDATE,
        payload: response.data,
      });
      /* loadAuction(id, token, dispatch, undefined, undefined, () => {
        if (typeof thenCallback === 'function') {
          thenCallback(response);
        }
      }); */
      if (typeof thenCallback === 'function') {
        thenCallback(response);
      }
    })
    .catch(error => {
      // handle error
      // console.log(error);
      if (error.response != null && error.response.data != null) {
        const {data} = error.response;
        dispatch({
          type: errorActionType.MODAL_ERROR_SHOW,
          modal: {title: data.message, description: data.description},
        });
      }
      if (typeof catchCallback === 'function') {
        catchCallback(error);
      }
    })
    .finally(() => {
      if (typeof finallyCallback === 'function') {
        finallyCallback();
      }
    });
};

export default loadAuditLogOffersByAuctionId;
