/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import checkAuctionState from '../../../services/checkAuctionState';
import StopAuctionModal from './StopAuctionModal';
import {selectors as selectorToken} from '../../../features/token';

function StopAuctionButton(props) {
  const [modalShow, setModalShow] = useState(false);
  const dispatch = useDispatch();
  const token = useSelector(selectorToken.getToken);

  const handleClick = e => {
    e.preventDefault();
    checkAuctionState(
      props.id,
      token,
      dispatch,
      undefined,
      undefined,
      setModalShow(true)
    );
  };
  return (
    <ButtonToolbar>
      <Button
        disabled={props.disabled}
        className="col-lg-12 p-3"
        variant="danger"
        onClick={handleClick}
      >
        {props.disabled ? (
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        ) : (
          'Zastaviť aukciu'
        )}
      </Button>
      <StopAuctionModal
        id={props.id}
        name={props.name}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </ButtonToolbar>
  );
}
export default StopAuctionButton;
