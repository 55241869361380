import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import OrdersForm from './OrdersForm';
import {selectors} from '../../../features/auction';
import loadAuction from '../../../services/loadAuction';
import {selectors as selectorToken} from '../../../features/token';
import InfoPopover from '../../Popover';
import InfoPopoverTexts from '../../Popover/InfoPopoverTexts';
import AuctioStarted from '../AuctionStarted';

const Orders = () => {
  const dispatch = useDispatch();

  const auction = useSelector(selectors.getAuction);
  const initialValues = useSelector(selectors.getOrdersFormInitial);
  const isLoading = useSelector(selectors.getLoading);
  /* TODO zmenit potom */
  const token = useSelector(selectorToken.getToken);
  const {auctionId} = useParams();

  useEffect(() => {
    loadAuction(auctionId, token, dispatch);
  }, [auctionId, dispatch, token]);

  return (
    <>
      <div className="bg-white col-lg-9 mt-5 mb-5 rounded pl-0 pr-0">
        <div className="border-bottom">
          <div className="col-lg-12 p-0">
            <h1 className="h4 font-weight-bold p-5 pr-5 m-0">
              Zakázka
              <InfoPopover
                placement="bottom"
                text={InfoPopoverTexts.ORDER.HEADER_NAME}
              />
            </h1>
          </div>
        </div>
        <AuctioStarted />
        {isLoading ? (
          <div className="p-5">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        ) : (
          ''
        )}
        {auction != null ? <OrdersForm initialValues={initialValues} /> : ''}
      </div>
    </>
  );
};

export default Orders;
