import React from 'react';

function EditPenIcon(props) {
  return (
    <svg
      {...props}
      version="1.1"
      id="IconsRepoEditor"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 13 13"
      xmlSpace="preserve"
      width="13px"
      height="13px"
      fill="none"
    >
      <path
        {...props}
        d="M2.36762 10.4C2.40013 10.4 2.43263 10.3967 2.46513 10.3919L5.19837 9.9125C5.23087 9.906 5.26175 9.89137 5.2845 9.867L12.1729 2.97862C12.1879 2.96359 12.1999 2.94573 12.208 2.92608C12.2162 2.90642 12.2204 2.88534 12.2204 2.86406C12.2204 2.84278 12.2162 2.82171 12.208 2.80205C12.1999 2.78239 12.1879 2.76453 12.1729 2.7495L9.47213 0.047125C9.44125 0.01625 9.40062 0 9.35675 0C9.31287 0 9.27225 0.01625 9.24137 0.047125L2.353 6.9355C2.32862 6.95987 2.314 6.98912 2.3075 7.02162L1.82812 9.75487C1.81232 9.84193 1.81797 9.93152 1.84458 10.0159C1.8712 10.1003 1.91798 10.1769 1.98087 10.2391C2.08812 10.3431 2.223 10.4 2.36762 10.4ZM3.46287 7.566L9.35675 1.67375L10.5479 2.86487L4.654 8.75712L3.20937 9.01225L3.46287 7.566ZM12.48 11.765H0.52C0.232375 11.765 0 11.9974 0 12.285V12.87C0 12.9415 0.0585 13 0.13 13H12.87C12.9415 13 13 12.9415 13 12.87V12.285C13 11.9974 12.7676 11.765 12.48 11.765Z"
        // fill="#3E97EF"
        // fill={props.className}
      />
    </svg>
  );
}

export default EditPenIcon;
