import React, {useState} from 'react';

import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Button from 'react-bootstrap/Button';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AddOrEditItemModal, {TYPES as MODAL_TYPES} from './AddOrEditItemModal';

function AddItemsButton() {
  const [modalShow, setModalShow] = useState(false);

  return (
    <ButtonToolbar
      variant="primary"
      className="float-right pb-2 pr-5 text-nowrap"
    >
      <Button variant="primary" onClick={() => setModalShow(true)}>
        <AddCircleIcon className="mr-1" variant="blue" />
        Pridať položku
      </Button>
      <AddOrEditItemModal
        type={MODAL_TYPES.ADD}
        show={modalShow}
        onHide={() => setModalShow(false)}
        setModalShow={setModalShow}
      />
    </ButtonToolbar>
  );
}
export default AddItemsButton;
