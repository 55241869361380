import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AddOrEditCriteriaModal, {
  TYPES as MODAL_TYPES,
} from './AddOrEditCriteriaModal';

function AddCriteriaButton() {
  const [modalShow, setModalShow] = useState(false);

  return (
    <ButtonToolbar
      variant="primary"
      className="float-right pb-2 pr-5 text-nowrap"
    >
      <Button variant="primary" onClick={() => setModalShow(true)}>
        <AddCircleIcon className="mr-1" variant="blue" />
        Pridať kritérium
      </Button>
      <AddOrEditCriteriaModal
        type={MODAL_TYPES.ADD}
        show={modalShow}
        onHide={() => setModalShow(false)}
        setModalShow={setModalShow}
      />
    </ButtonToolbar>
  );
}
export default AddCriteriaButton;
