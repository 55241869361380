import React, {useEffect} from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import Cookies from 'js-cookie';
import {useSelector, useDispatch} from 'react-redux';
import TopNavigation from './components/TopNavigation';
import SideBar from './components/SideNavigation';
import TopNavigationAH from './auctionhall/TopNavigation';
import SideBarAHAdmin from './auctionhall/SideNavigationAdmin';
import SideBarAHBidder from './auctionhall/SideNavigationBidder';
import Main from './components/Main';
import Login from './components/Pages/Login';
import {selectors, actionTypes} from './features/token';
import tokenValidation from './services/tokenValidation';
import settings from './services/settings';

const App = () => {
  const token = useSelector(selectors.getToken);
  const dispatch = useDispatch();

  /**
   * Call token validation service (logouts if token is invalid)
   */
  useEffect(() => {
    if (token) {
      const int = setInterval(() => {
        // console.info('Validating token with service');
        tokenValidation(token, dispatch);
      }, settings.TOKEN_VALIDATION_INTERVAL);
      return () => {
        clearInterval(int);
      };
    }
    return () => {};
  }, [token, dispatch]);

  /**
   * Check token presence in cookie, Logout otherwise
   */
  useEffect(() => {
    if (token) {
      const int = setInterval(() => {
        // console.info('Checking token presence in cookie');
        if (token && Cookies.get('token') == null) {
          // console.warn('Invalid token cookie, logging out');
          dispatch({
            type: actionTypes.TOKEN_LOGOUT,
          });
        }
      }, settings.TOKEN_COOKIE_VALIDATION_INTERVAL);
      return () => {
        clearInterval(int);
      };
    }
    return () => {};
  }, [dispatch, token]);
  return (
    <>
      <Switch>
        <Redirect exact from="/" to="/error" />
        <Route exact path="/error">
          undefined auction
        </Route>
        <Redirect
          exact
          from="/:auctionId"
          to="/:auctionId/auction_orders/orders"
        />
        <Route>
          {token != null ? (
            <>
              <header>
                <Switch>
                  <Route path="/:auctionId/auction_hall">
                    <TopNavigationAH />
                  </Route>
                  <Route>
                    <TopNavigation />
                  </Route>
                </Switch>
              </header>
              <main>
                <aside>
                  <Switch>
                    <Route path="/:auctionId/auction_hall/bidder">
                      <SideBarAHBidder />
                    </Route>
                    <Route path="/:auctionId/auction_hall">
                      <SideBarAHAdmin />
                    </Route>

                    <Route>
                      <SideBar />
                    </Route>
                  </Switch>
                  <div className="aside-bottom">
                    <p className="text-center text-white m-2">
                      Version:{settings.VERSION}
                    </p>
                  </div>
                </aside>
                <section className="content-area">
                  <div className="content">
                    <Main />
                  </div>
                  <footer></footer>
                </section>
              </main>
            </>
          ) : (
            <Login />
          )}
        </Route>
      </Switch>
    </>
  );
};
export default App;
