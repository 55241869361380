import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Switch, Route} from 'react-router-dom';
import {ToastContainer, cssTransition} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Criteria from '../Pages/Criteria';
import Items from '../Pages/Items';
import Orders from '../Pages/Orders';
import Users from '../Pages/Users';
import ExportPdf from '../Pages/Export/Pdf';
import ExportString from '../Pages/Export/String';
import SettingRounds from '../Pages/Settings/Rounds';
import SettingAuction from '../Pages/Settings/Auction';
import AuctionHallRoomAdmin from '../../auctionhall/Pages/AuctionHallAdmin';
import AuctionHallRoomBidder from '../../auctionhall/Pages/AuctionHallBidder';
import BidsHistory from '../../auctionhall/Pages/AuctionHallAdmin/BidsHistory';
import AuctionHallUsers from '../../auctionhall/Pages/AuctionHallAdmin/Users';
import AuditLog from '../../auctionhall/Pages/AuditLog';
import ErrorModal from '../Modals/Error/ErrorModal';
import {actionTypes, selectors} from '../../features/modals';

const Main = () => {
  const modalShow = useSelector(selectors.getErrorModal);
  const dispatch = useDispatch();
  const Roll = cssTransition({
    enter: 'rollIn',
    exit: 'rollOut',
    // default to 750ms, can be omitted
    duration: 250,
  });
  return (
    <main role="main" className="bg-light p-0">
      <ErrorModal
        show={modalShow}
        onHide={() =>
          dispatch({
            type: actionTypes.MODAL_ERROR_HIDE,
          })
        }
      />
      <ToastContainer
        autoClose={2500}
        className="toast-css"
        transition={Roll}
        position="top-center"
        hideProgressBar
      />
      <div className="pl-5 pr-5 mb-5 pb-0 content-div">
        <Switch>
          {/* Auction order menu */}
          <Route path="/:auctionId/auction_orders/criteria">
            <Criteria />
          </Route>
          <Route path="/:auctionId/auction_orders/items">
            <Items />
          </Route>
          <Route path="/:auctionId/auction_orders/orders">
            <Orders />
          </Route>
          <Route path="/:auctionId/auction_orders/users">
            <Users />
          </Route>
          {/* Export menu */}
          <Route path="/:auctionId/export/pdf">
            <ExportPdf />
          </Route>
          <Route path="/:auctionId/export/string">
            <ExportString />
          </Route>
          {/* Auction setting menu */}
          <Route path="/:auctionId/auction_setting/rounds">
            <SettingRounds />
          </Route>
          <Route path="/:auctionId/auction_setting/auction">
            <SettingAuction />
          </Route>
          <Route path="/:auctionId/auction_setting/audit_log">
            <AuditLog />
          </Route>
          {/* Auction hall menu */}
          <Route path="/:auctionId/auction_hall/owner">
            <AuctionHallRoomAdmin />
          </Route>
          <Route path="/:auctionId/auction_hall/bids">
            <BidsHistory />
          </Route>
          <Route path="/:auctionId/auction_hall/users">
            <AuctionHallUsers />
          </Route>
          <Route path="/:auctionId/auction_hall/bidder">
            <AuctionHallRoomBidder />
          </Route>
          <Route path="/:auctionId/auction_hall/audit_log">
            <AuditLog />
          </Route>
        </Switch>
      </div>
    </main>
  );
};
export default Main;
