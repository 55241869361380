export default {
  AMOUNT: 'amount',
  DESCRIPTION: 'description',
  ID: 'id',
  IPADRESS: 'ipAdress',
  MINVAULECHANGE: 'minVauleChange',
  NAME: 'name',
  PRICEAFFECT: 'priceAffect',
  TYPE: 'type',
  UNIT: 'unit',
  CUSTOM_UNIT: 'custom_unit',
  UUID: 'uuid',
  CRITERIA: 'criteria',
};
