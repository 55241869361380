import axios from 'axios';
import {toast} from 'react-toastify';
import settings from './settings';
import {actionTypes} from '../features/auctionRounds';

import loadActiveAuctionRound from './loadActiveAuctionRound';
import ToastUtil from '../components/Toast/ToastUtil';
import {actionTypes as errorActionType} from '../features/modals';
import Text from '../components/Toast/ToastTexts';
import loadUserAuctionData from './loadUserAuctionData';

const editAuctionRound = (
  auctionId,
  request,
  token,
  dispatch,
  thenCallback,
  catchCallback,
  finallyCallback
) => {
  axios
    .post(
      settings.SERVICE_URL + settings.SERVICES.EDIT_AUCTION_ROUND,
      request,
      {
        responseType: 'json',
        headers: {
          'Ela-Elc-Auth': token,
        },
      }
    )
    .then(response => {
      // console.log(response.data);
      toast.success(
        ToastUtil(
          Text.EDIT_AUCTION_ROUNDS.TITLE,
          Text.EDIT_AUCTION_ROUNDS.DESCRIPTION + request.auction_round_name
        )
      );
      // Volame reload roundov
      loadActiveAuctionRound(
        auctionId,
        token,
        dispatch,
        undefined,
        undefined,
        () => {
          dispatch({
            type: actionTypes.ROUNDS_EDIT_SUCCESS,
            payload: response,
          });
          loadUserAuctionData(auctionId, token, dispatch);
          if (typeof thenCallback === 'function') {
            thenCallback(response);
          }
        }
      );
    })
    .catch(error => {
      // handle error
      // console.log(error);
      dispatch({
        type: actionTypes.ROUNDS_EDIT_FAILED,
        payload: error,
      });
      dispatch({
        type: actionTypes.ROUNDS_HIDE_LOADING,
      });
      if (error.response != null && error.response.data != null) {
        const {data} = error.response;
        dispatch({
          type: errorActionType.MODAL_ERROR_SHOW,
          modal: {title: data.message, description: data.description},
        });
      }
      if (typeof catchCallback === 'function') {
        catchCallback(error);
      }
    })
    .finally(() => {
      if (typeof finallyCallback === 'function') {
        finallyCallback();
      }
    });
};

export default editAuctionRound;
