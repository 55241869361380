import {combineReducers, createStore} from 'redux';
import {reducer as reduxFormReducer} from 'redux-form';
import {CounterReducer} from './features/counter';
import {UsersReducer} from './features/users';
import {AuctionReducer} from './features/auction';
import {AuctionSettingsReducer} from './features/auctionSettings';
// Sere ma ako hnoj. nejak to cachuje akym nespravim v tomto subore zmenu, nemozem zmazat tento koment
import {AuctionRoundsReducer} from './features/auctionRounds';
import {TokenReducer} from './features/token';
import {WebsocketReducer} from './features/websocket';
import {OffersReducer} from './features/offers';
import {AuditLogReducer} from './features/auditLog';
import {CpvCodesReducer} from './features/cpvcodes';
import {ModalReducer} from './features/modals';
import {MenuStateReducer} from './features/menustate';
import {UserAuctionDataReducer} from './features/userAuctionData';

/* Create root reducer, containing all features of the application */
const rootReducer = combineReducers({
  form: reduxFormReducer, // mounted under "form"
  count: CounterReducer,
  users: UsersReducer,
  auction: AuctionReducer,
  auctionRounds: AuctionRoundsReducer,
  auctionSettings: AuctionSettingsReducer,
  tokenState: TokenReducer,
  ws: WebsocketReducer,
  offers: OffersReducer,
  auditLog: AuditLogReducer,
  cpv: CpvCodesReducer,
  modal: ModalReducer,
  menu: MenuStateReducer,
  userData: UserAuctionDataReducer,
});

/* eslint-disable no-underscore-dangle */
const reduxDevtoolsEnhancer =
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(); // initialize Redux Dev Tools, if they are installed in browser.
/* eslint-enable */

const store = createStore(rootReducer, reduxDevtoolsEnhancer);

export default store;
