import axios from 'axios';
import settings from './settings';
import {actionTypes} from '../features/token';
// import {actionTypes as errorActionType} from '../features/modals';

// request nemoze byt null ale prazdny objekt lebo servisa potom hadze error
const logout = (
  token,
  dispatch,
  thenCallback,
  catchCallback,
  finallyCallback
) => {
  axios
    .post(
      settings.SERVICE_URL + settings.SERVICES.LOGOUT,
      {},
      {
        responseType: 'json',
        headers: {
          'Ela-Elc-Auth': token,
        },
      }
    )
    .then(response => {
      /* ulozit do cookies */

      dispatch({
        type: actionTypes.TOKEN_LOGOUT,
      });

      if (typeof thenCallback === 'function') {
        thenCallback(response);
      }
    })
    .catch(error => {
      // handle error
      // console.log(error);
      /* const {data} = error.response;
      dispatch({
        type: errorActionType.MODAL_ERROR_SHOW,
        modal: {title: data.statusCode, description: data.description}, 
      }); */
      dispatch({
        type: actionTypes.TOKEN_LOGOUT,
      });
      if (typeof catchCallback === 'function') {
        catchCallback(error);
      }
    })
    .finally(() => {
      if (typeof finallyCallback === 'function') {
        finallyCallback();
      }
    });
};

export default logout;
