import React from 'react';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import {useParams} from 'react-router-dom';
import {reduxForm, Field, setSubmitFailed} from 'redux-form';
import {useSelector, useDispatch} from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import CountryEnum from '../../enums/CountryEnum';
import {
  BootstrapFormControl,
  BootstrapDropDownControl,
} from '../../ReduxBootstrapFields';
import {actionTypes, selectors} from '../../../features/users';
import {requestDTO} from '../../../services';
import editOrganization from '../../../services/editOrganization';
import {selectors as selectorToken} from '../../../features/token';

import FIELD_NAMES from './EditOrganizationFormFieldNames';
import InfoPopoverText from '../../Popover/InfoPopoverTexts';
import ErrorFormText from '../Error/ErrorFormText';
import SubmitFormValidator, {
  required,
  normalizeLength,
} from '../../Utils/SubmitFormValidator';
import RoundLifeCycleEnum from '../../enums/RoundLifeCycleEnum';
import {selectors as selectorUAD} from '../../../features/userAuctionData';

export const FORM_NAME = 'EditOrganizationForm';

const warn = values => {
  const warnings = {};
  return warnings;
};

function EditOrganizationForm(props) {
  // const {id} = props;
  const dispatch = useDispatch();
  const {auctionId} = useParams();
  const isLoading = useSelector(selectors.getLoading);
  const {initialValues} = props;
  const form = useSelector(state => state.form[FORM_NAME]);
  const token = useSelector(selectorToken.getToken);
  const auctionData = useSelector(selectorUAD.getValues);
  const started = !!(
    auctionData != null &&
    auctionData.roundEnum !== RoundLifeCycleEnum.PREPARATION_PHASE
  );
  const handleClick = e => {
    e.preventDefault();
    if (SubmitFormValidator(form)) {
      dispatch(setSubmitFailed(FORM_NAME, Object.keys(form.syncErrors)));
      return;
    }
    dispatch({
      type: actionTypes.USERS_SHOW_LOADING,
    });
    editOrganization(
      auctionId,
      requestDTO.createEditOrganizationRequest(
        form.values,
        initialValues,
        auctionId
      ),
      token,
      dispatch,
      () => {
        props.setModalShow(false);
      },
      undefined,
      undefined
    );
  };

  return (
    <>
      <Form>
        <div className="container pl-5 pr-5">
          <Form.Group controlId={FIELD_NAMES.NAME}>
            <Form.Label>
              Názov spoločnosti<i className="text-danger">*</i>
            </Form.Label>
            <Field
              name={FIELD_NAMES.NAME}
              type="text"
              component={BootstrapFormControl}
              normalize={normalizeLength(300)}
              maxLength="300"
              text={InfoPopoverText.ORGANIZATION.NAME}
              validate={[required]}
            />
          </Form.Group>
          <Form.Group controlId={FIELD_NAMES.COMPANYID}>
            <Form.Label>
              IČO<i className="text-danger">*</i>
            </Form.Label>
            <Field
              name={FIELD_NAMES.COMPANYID}
              type="text"
              maxLength="298"
              normalize={normalizeLength(255)}
              component={BootstrapFormControl}
              text={InfoPopoverText.ORGANIZATION.COMPANYID}
              validate={[required]}
            />
          </Form.Group>
          {/* <Form.Group controlId={FIELD_NAMES.TAXID}>
              <Form.Label>DIČ</Form.Label>
              <Field
                name={FIELD_NAMES.TAXID}
                type="text"
                maxLength="298"
                normalize={normalizeLength(255)}
                component={BootstrapFormControl}
                text={InfoPopoverText.ORGANIZATION.TAXID}
              />
            </Form.Group>
            <Form.Group controlId={FIELD_NAMES.VATID}>
              <Form.Label>IČ DPH</Form.Label>
              <Field
                name={FIELD_NAMES.VATID}
                type="text"
                component={BootstrapFormControl}
                normalize={normalizeLength(255)}
                maxLength="298"
                text={InfoPopoverText.ORGANIZATION.VATID}
              />
            </Form.Group> */}
          <Form.Group controlId={FIELD_NAMES.CITY}>
            <Form.Label>Mesto</Form.Label>
            <Field
              name={FIELD_NAMES.CITY}
              type="text"
              component={BootstrapFormControl}
              normalize={normalizeLength(255)}
              maxLength="298"
              text={InfoPopoverText.ORGANIZATION.CITY}
            />
          </Form.Group>
          <Form.Group controlId={FIELD_NAMES.COUNTRY}>
            <Form.Label>Štát</Form.Label>
            <Field
              name={FIELD_NAMES.COUNTRY}
              as="select"
              component={BootstrapDropDownControl}
            >
              text={InfoPopoverText.ORGANIZATION.COUNTRY}
              {Object.entries(CountryEnum).map(pair => (
                <option key={pair[0]} value={pair[0]}>
                  {pair[1]}
                </option>
              ))}
            </Field>
          </Form.Group>
          <Form.Group controlId={FIELD_NAMES.STREET}>
            <Form.Label>Ulica</Form.Label>
            <Field
              name={FIELD_NAMES.STREET}
              type="text"
              maxLength="255"
              normalize={normalizeLength(255)}
              component={BootstrapFormControl}
              text={InfoPopoverText.ORGANIZATION.STREET}
            />
          </Form.Group>
          <Form.Group controlId={FIELD_NAMES.STREETNUMBER}>
            <Form.Label>Čislo ulice/domu</Form.Label>
            <Field
              name={FIELD_NAMES.STREETNUMBER}
              type="text"
              maxLength="255"
              normalize={normalizeLength(255)}
              component={BootstrapFormControl}
              text={InfoPopoverText.ORGANIZATION.STREETNUMBER}
            />
          </Form.Group>
          <Form.Group controlId={FIELD_NAMES.POSTCODE}>
            <Form.Label>PSČ</Form.Label>
            <Field
              name={FIELD_NAMES.POSTCODE}
              type="text"
              maxLength="255"
              normalize={normalizeLength(255)}
              component={BootstrapFormControl}
              text={InfoPopoverText.ORGANIZATION.POSTCODE}
            />
          </Form.Group>
          <div className="text-center container">
            {started !== null && !started ? (
              <>
                <ErrorFormText formName={FORM_NAME} />
                <Button
                  variant="success"
                  type="submit"
                  disable={isLoading?.toString()}
                  className="ml-5 mr-5 mb-5 mt-0 pt-3 pb-3 pl-5 pr-5"
                  onClick={handleClick}
                >
                  {isLoading ? (
                    <Spinner animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  ) : (
                    'Ulož'
                  )}
                </Button>
              </>
            ) : null}
          </div>
        </div>
      </Form>
    </>
  );
}

export default reduxForm({
  form: FORM_NAME,
  warn,
})(EditOrganizationForm);
