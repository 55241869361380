/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {useState} from 'react';

// import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import DeleteIcon from '@material-ui/icons/Delete';

import DeleteItemModal from './DeleteItemModal';
import {Button} from 'react-bootstrap';

function DeleteItemsButton(props) {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <Button
        className="ml-1"
        variant="danger"
        onClick={() => setModalShow(true)}
      >
        <DeleteIcon /> Vymazať
      </Button>
      <DeleteItemModal
        id={props.id}
        name={props.name}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}
export default DeleteItemsButton;
