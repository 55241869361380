import React, {useEffect, useState} from 'react';

function CountdownTimerFunction(props) {
  const event1 = new Date(props.criteria.auctionCurrentTime);
  const event2 = new Date();
  event2.setTime(event1.getTime());
  const calculateTimeLeft = () => {
    const difference = +new Date(props.criteria) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timerId = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => {
      clearTimeout(timerId);
    };
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach(interval => {
    //   if (!timeLeft[interval]) {
    //     return;
    //   }
    timerComponents.push(
      <React.Fragment key={interval}>
        <td className={props.className}>
          {' '}
          {timeLeft[interval] < 10
            ? `0${timeLeft[interval]}`
            : timeLeft[interval]}
        </td>
        {interval === 'seconds' ? (
          <td className="p-0 border-0 h4 text-black time bg-white "></td>
        ) : (
          <td className="p-0 border-0 h4 text-black time bg-white ">:</td>
        )}
      </React.Fragment>
    );
  });

  return timerComponents.length ? (
    <React.Fragment>{timerComponents}</React.Fragment>
  ) : (
    <td>Time&apos;s up!</td>
  );
}

export default CountdownTimerFunction;
