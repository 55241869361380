import {
  AUCTION_SETTINGS_UPDATE,
  // AUCTION_SETTINGS_VALUES,
  AUCTION_SETTINGS_SHOW_LOADING,
  AUCTION_SETTINGS_HIDE_LOADING,
  AUCTION_SETTINGS_SET_INITIAL,
} from './actionTypes';

const initialState = {
  value: null,
  loading: true,
  auctionSettingsFormInitial: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case AUCTION_SETTINGS_UPDATE:
      return {...state, value: action.payload};
    case AUCTION_SETTINGS_SET_INITIAL:
      return {
        ...state,
        auctionSettingsFormInitial: action.payload,
      };
    case AUCTION_SETTINGS_SHOW_LOADING:
      return {...state, loading: true};

    case AUCTION_SETTINGS_HIDE_LOADING:
      return {...state, loading: false};

    default:
      return state;
  }
};
