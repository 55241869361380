import Cookies from 'js-cookie';
import {
  TOKEN_UPDATE,
  TOKEN_LOGOUT,
  USER_UPDATE,
  USER_ERROR,
  TOKEN_SHOW_LOADING,
  TOKEN_HIDE_LOADING,
} from './actionTypes';

const initialState = {
  token: Cookies.get('token') || null,
  role: Cookies.get('role') || null,
  roleString: null,
  username: Cookies.get('username') || null,
  organizationId: Cookies.get('organizationId') || null,
  errorMsg: null,
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TOKEN_UPDATE:
      Cookies.set('token', action.token);
      Cookies.set('role', action.role);
      return {...state, token: action.token, role: action.role, errorMsg: null};
    case TOKEN_LOGOUT: {
      Cookies.remove('token');
      Cookies.remove('role');
      Cookies.remove('organizationId');
      Cookies.remove('username');
      window.location.reload();
      return {...state, token: null, role: null};
    }
    case TOKEN_SHOW_LOADING:
      return {...state, loading: true};
    case TOKEN_HIDE_LOADING:
      return {...state, loading: false};
    case USER_ERROR: {
      return {...state, errorMsg: 'Zadaný nesprávny email alebo heslo!'};
    }
    case USER_UPDATE: {
      Cookies.set('role', action.payload.role);
      Cookies.set('organizationId', action.payload.organizationId);
      Cookies.set('username', action.payload.userName);
      let roleAsString = null;
      switch (action.payload.role) {
        case 'OWNER':
          roleAsString = 'Administrátor aukcie';
          break;
        case 'BIDDER':
          roleAsString = 'Účastník aukcie';
          break;
        case 'WATCHER':
          roleAsString = 'Pozorovateľ aukcie';
          break;
        default:
          roleAsString = '';
      }
      return {
        ...state,
        username: action.payload.userName,
        roleString: roleAsString,
        role: action.payload.role,
        organizationId: action.payload.organizationId,
      };
    }
    default:
      return state;
  }
};
