import React from 'react';

import Modal from 'react-bootstrap/Modal';
import {useSelector} from 'react-redux';
import {selectors} from '../../../features/users';
import EditOrganizationForm from './EditOrganizationForm';
import InfoPopover from '../../Popover';
import InfoPopoverTexts from '../../Popover/InfoPopoverTexts';

function EditOrganizationModal(props) {
  const {id} = props;

  // const isLoading = useSelector(selectors.getLoading);
  const organizations = useSelector(selectors.getValues);
  let initialValues;

  organizations.organizations.forEach(item => {
    if (item.id === id) {
      initialValues = item;
    }
  });

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="bg-light pt-5">
        <div className="text-center container pr-1">
          <Modal.Title
            as="h4"
            id="contained-modal-title-vcenter"
            className="font-weight-bolder"
          >
            Editovať organizáciu
            <InfoPopover
              placement="bottom"
              text={InfoPopoverTexts.ORGANIZATION.HEADER_NAME}
              className="text-primary ml-2"
            />
          </Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body className="bg-light">
        <EditOrganizationForm
          id={id}
          initialValues={initialValues}
          setModalShow={props.setModalShow}
        />
      </Modal.Body>
    </Modal>
  );
}
export default EditOrganizationModal;
