import React from 'react';

import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

function InfoPopover(props) {
  return (
    <OverlayTrigger
      trigger="hover"
      placement={props.placement}
      overlay={popover(props.text)}
    >
      <svg
        {...props}
        width="20"
        height="20"
        className={
          props.className != null
            ? props.className
            : 'float-right text-primary ml-2'
        }
        style={props.style != null ? props.style : null}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.99984 1.66666C5.40484 1.66666 1.6665 5.40499 1.6665 9.99999C1.6665 14.595 5.40484 18.3333 9.99984 18.3333C14.5948 18.3333 18.3332 14.595 18.3332 9.99999C18.3332 5.40499 14.5948 1.66666 9.99984 1.66666ZM9.99984 16.6667C6.32401 16.6667 3.33317 13.6758 3.33317 9.99999C3.33317 6.32416 6.32401 3.33332 9.99984 3.33332C13.6757 3.33332 16.6665 6.32416 16.6665 9.99999C16.6665 13.6758 13.6757 16.6667 9.99984 16.6667Z"
          fill="#3E97EF"
        />
        <path
          d="M9.1665 9.16668H10.8332V14.1667H9.1665V9.16668ZM9.1665 5.83334H10.8332V7.50001H9.1665V5.83334Z"
          fill="#3E97EF"
        />
      </svg>
    </OverlayTrigger>
  );
}
const popover = text => (
  <Popover id="popover-basic">
    <Popover.Content>{text}</Popover.Content>
  </Popover>
);

export default InfoPopover;
