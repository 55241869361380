export default {
  PUBLISHTYPE: 'publishType',
  EVALCRITERIUM: 'evalCriterium',
  EVALCRITERIUM_TEXT: 'evalCriteriumText',
  MINDECREASEVALUE_FIAT: 'minDecreaseValue_fiat',
  MAXDECREASEVALUE_FIAT: 'maxDecreaseValue_fiat',
  MINDECREASEVALUE_PERCENTAGE: 'minDecreaseValue_percentage',
  MAXDECREASEVALUE_PERCENTAGE: 'maxDecreaseValue_percentage',
  DECREASEVALUE: 'decreaseValue',
  SHOWWINNINGOFFERS: 'showWinningOffers',
  ESTIMATEDVALUE: 'estimatedValue',
  CPVCODE: 'cpvCode',
  CPVCODES: 'cpvCodes',
  CURRENCY: 'currency',
  NAME: 'name',
};
