import React from 'react';
// import {useParams} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';
import stopAuction from '../../../services/stopAuction';
import {selectors as selectorToken} from '../../../features/token';
// import {selectors as selectorAuctionData} from '../../../features/userAuctionData';
import {
  // selectors as selectorAuction,
  actionTypes,
} from '../../../features/auction';

function StopAuctionModal(props) {
  const {id} = props;
  const dispatch = useDispatch();
  // const {auctionId} = useParams();
  const token = useSelector(selectorToken.getToken);
  // const auctionData = useSelector(selectorAuctionData.getStartAuctionData);

  const handleClickStop = e => {
    e.preventDefault();
    dispatch({
      type: actionTypes.AUCTION_START_SHOW_LOADING,
    });
    stopAuction(id, token, dispatch);
    props.onHide();
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <div className="text-center container">
          <Modal.Title
            as="h4"
            id="contained-modal-title-vcenter"
            className="font-weight-bolder"
          >
            Zastaviť aukciu
          </Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body className="p-3">
        <p className="text-danger">
          Aukcia prebieha, zastavením aukcie bude aukcia zrušená a ukončená!
        </p>
      </Modal.Body>
      <Modal.Footer className="border-top-0">
        <Button variant="success" onClick={handleClickStop}>
          Áno
        </Button>
        <Button variant="danger" onClick={props.onHide}>
          Nie
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
StopAuctionModal.propTypes = {
  onHide: PropTypes.func.isRequired,
};

export default StopAuctionModal;
