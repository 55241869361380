export default {
  CITY: 'city',
  COUNTRY: 'country',
  NAME: 'name',
  POSTCODE: 'postCode',
  STREET: 'street',
  STREETNUMBER: 'streetNumber',
  VATID: 'vatId',
  COMPANYID: 'companyId',
  TAXID: 'taxId',
};
