import React, {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
// import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Button from 'react-bootstrap/Button';
import InfoIcon from '@material-ui/icons/Info';
import EditPenIcon from '../../MenuIcons/EditPenIcon';
import {actionTypes} from '../../../features/users';
import EditOrganizationModal from './EditOrganizationModal';
import {selectors as selectorUAD} from '../../../features/userAuctionData';
import RoundLifeCycleEnum from '../../enums/RoundLifeCycleEnum';

function EditOrganizationButton(props) {
  const [modalShow, setModalShow] = useState(false);
  const dispatch = useDispatch();
  const auctionData = useSelector(selectorUAD.getValues);
  const handleEdit = e => {
    e.preventDefault();
    const initialState = {};
    dispatch({
      type: actionTypes.USERS_SET_INITIAL_VALUES,
      payload: initialState,
    });
    setModalShow(true);
  };
  return (
    <>
      <Button
        // className="pr-3 text-nowrap"
        onClick={handleEdit}
        // onKeyDown={handleEdit}
        // role="button"
        // tabIndex={0}
      >
        {auctionData != null &&
        auctionData.roundEnum === RoundLifeCycleEnum.PREPARATION_PHASE ? (
          <>
            <EditPenIcon className="mr-2 icon-white" />
            Upraviť údaje
          </>
        ) : (
          <>
            <InfoIcon className="mr-2 icon-white" />
            Info
          </>
        )}
      </Button>
      <EditOrganizationModal
        id={props.id}
        show={modalShow}
        onHide={() => setModalShow(false)}
        setModalShow={setModalShow}
      />
    </>
  );
}
export default EditOrganizationButton;
