import React, {useEffect} from 'react';
import Nav from 'react-bootstrap/Nav';
import {useSelector, useDispatch} from 'react-redux';
import {Link, useRouteMatch} from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import {toast} from 'react-toastify';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from '../../components/MenuIcons/SettingsIcon';
import Logo from '../../images/logo192.png';
import logout from '../../services/logout';
import {
  selectors as selectorToken,
  actionTypes as actioTypeToken,
} from '../../features/token';
import tokenValidation from '../../services/tokenValidation';
import CountdownTimer from '../CountdownTimer';
import settings from '../../services/settings';
import responseTypeEnum from '../../components/enums/ResponseTypeEnum';
import {
  actionTypes as actioTypeWS,
  selectors as selectorWS,
} from '../../features/websocket';
import ALogo from '../../images/logo.png';
import RoundLifeCycleEnum from '../../components/enums/RoundLifeCycleEnum';
import ToastUtil from '../../components/Toast/ToastUtil';
import Text from '../../components/Toast/ToastTexts';

const TopNavigationAH = () => {
  const dispatch = useDispatch();
  const match = useRouteMatch('/:auctionId');
  const token = useSelector(selectorToken.getToken);
  const roleString = useSelector(selectorToken.getRoleAsString);
  const username = useSelector(selectorToken.getUsername);
  const {auctionId} = match.params;
  const criteriaOffer = useSelector(selectorWS.getValues);
  const tokenConst = useSelector(selectorToken.getToken);
  const role = useSelector(selectorToken.getRole);

  useEffect(() => {
    tokenValidation(token, dispatch);
  }, [dispatch, token]);
  useEffect(() => {
    const ws = new WebSocket(settings.WEBSOCKET_URL);
    ws.onopen = function onopen() {
      // eslint-disable-next-line no-console
      console.info('Connection established.');
      ws.send(JSON.stringify({type: 'LOGIN', token: tokenConst, auctionId}));
    };

    ws.onmessage = function onmessage(evt) {
      const receivedMsg = JSON.parse(evt.data);
      switch (receivedMsg.responseType) {
        case responseTypeEnum.DATA:
          // eslint-disable-next-line
          if (receivedMsg.auctionId == auctionId) {
            dispatch({
              type: actioTypeWS.WS_UPDATE_VALUES,
              payload: receivedMsg,
            });
          } else {
            // received data related to different auction
          }
          break;
        case responseTypeEnum.WS_CONNECTION_SUCCESS:
          dispatch({
            type: actioTypeToken.USER_UPDATE,
            payload: {
              role: receivedMsg.roleType,
              organizationId: receivedMsg.organizationUuid,
              userName: receivedMsg.userName,
            },
          });
          break;
        case responseTypeEnum.USERS:
          // eslint-disable-next-line
          if (receivedMsg.auctionId == auctionId) {
            dispatch({
              type: actioTypeWS.WS_USERS_VALUES,
              payload: receivedMsg,
            });
            if (receivedMsg.userName != null) {
              toast.success(
                ToastUtil(
                  Text.LOGED_USER.TITLE,
                  // eslint-disable-next-line prefer-template
                  'Do aukcie sa prihlásil uživateľ ' +
                    receivedMsg.userName +
                    ' zastupujúci spoločnosť ' +
                    receivedMsg.userOrganization
                )
              );
            }
          } else {
            // received data related to different auction
          }
          break;
        default:
          break;
      }
    };
    return () => {
      ws.close();
    };
  }, [auctionId, dispatch, tokenConst]);

  return (
    <>
      <div className="top-navbar">
        <div className="img-width bg-dark text-center">
          <a
            href="https://tenderia.sk"
            className="logo-auc"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={ALogo} className="p-2" alt="" />
          </a>
        </div>
        {role != null && role === 'OWNER' ? (
          <>
            {criteriaOffer != null &&
            criteriaOffer.roundLifeCycle ===
              RoundLifeCycleEnum.STARTED_PHASE ? (
              <div className="card-body p-0 ml-1 mr-1 fixed-width">
                <div className="col-lg-12 card-body p-1 m-0">
                  <div className="col-lg-12 pt-2 pl-3 pr-0 pb-0">
                    <p className="text-blue2 bold-wight h6 mb-0">
                      Aukcia začína za
                    </p>
                  </div>
                  <Table responsive className="col-lg-12 overflow-hidden pt-2">
                    <thead className="bg-white border-0">
                      {criteriaOffer != null ? (
                        <tr className="bg-white text-blue2 border-0 text-center">
                          {criteriaOffer != null ? (
                            <CountdownTimer
                              className="bg-white text-black border-0 h4 time p-0"
                              criteria={criteriaOffer.auctionStartTime}
                            />
                          ) : (
                            ''
                          )}
                        </tr>
                      ) : null}
                    </thead>
                    <tbody></tbody>
                  </Table>
                </div>
              </div>
            ) : null}
            {criteriaOffer != null &&
            criteriaOffer.roundLifeCycle === RoundLifeCycleEnum.ACTIVE_PHASE ? (
              <div className="card-body p-0 ml-1 mr-1 fixed-width">
                <div className="col-lg-12 card-body p-1 m-0">
                  <div className="col-lg-12 pt-2 pl-3 pr-0 pb-0">
                    <p className="text-blue2 bold-wight h6 mb-0">
                      Aukcia končí za
                    </p>
                  </div>
                  <Table responsive className="col-lg-12 overflow-hidden pt-2">
                    <thead className="bg-white border-0">
                      {criteriaOffer != null && (
                        <tr className="bg-white text-blue2 border-0 text-center">
                          <CountdownTimer
                            className="bg-white text-black border-0 h4 time p-0"
                            criteria={criteriaOffer.auctionEndTime}
                          />
                        </tr>
                      )}
                    </thead>
                    <tbody></tbody>
                  </Table>
                </div>
              </div>
            ) : null}
          </>
        ) : (
          <>
            {criteriaOffer != null &&
            criteriaOffer.roundLifeCycle === RoundLifeCycleEnum.ACTIVE_PHASE ? (
              <div className="card-body p-0 ml-1 mr-1 fixed-width">
                <div className="col-lg-12 card-body p-1 m-0">
                  <div className="col-lg-12 pt-2 pl-3 pr-0 pb-0">
                    <p className="text-blue2 bold-wight h6 mb-0">
                      Aukcia končí za
                    </p>
                  </div>
                  <Table responsive className="col-lg-12 overflow-hidden pt-2">
                    <thead className="bg-white border-0">
                      <tr className="bg-white text-blue2 border-0 text-center">
                        <CountdownTimer
                          className="bg-white text-black border-0 h4 time p-0"
                          criteria={criteriaOffer.auctionEndTime}
                        />
                      </tr>
                    </thead>
                    <tbody></tbody>
                  </Table>
                </div>
              </div>
            ) : null}
          </>
        )}
        <div className=" card-body p-0 border-left">
          <div className="col-lg-12 ml-1 mr-1 card-body p-1 m-0">
            <div className="col-lg-12 pl-3 pt-2 pr-0 pb-0">
              <p className="text-blue2 bold-wight h6 mb-0">Názov zakázky</p>
            </div>
            <p className="h4 pt-0 label-nazov pl-3">
              {criteriaOffer != null ? criteriaOffer.auctionName : null}
            </p>
          </div>
        </div>
        {role != null && role === 'OWNER' ? (
          <div className=" card-body p-0 fixed-width">
            <Nav.Link
              className="card-body bg-dark p-1 m-0"
              as={Link}
              to={`/${auctionId}/auction_orders/orders`}
            >
              <div className="col-lg-12 p-0 row m-0">
                <div className="col-lg-3 p-0 text-center">
                  <SettingsIcon
                    fontSize="medium"
                    className="text-white m-1 font-weight-light mt-2"
                    style={{fontSize: 45}}
                  />
                </div>
                <div className="col-lg-9 p-0">
                  <p className="text-white float-left h6 mb-1 mt-0 bold-wight">
                    Nastavenia aukčnej siene
                  </p>
                  <p className="text-muted float-left m-0 p-0 bold-wight">
                    Uchádzači{' '}
                  </p>
                  <p className="card-text font-weight-lighter float-left text-white h6 col-lg-12 p-0 text-left bold-wight">
                    {criteriaOffer != null
                      ? `${criteriaOffer.onlineUsers}/${criteriaOffer.userCount}`
                      : ''}
                  </p>
                </div>
              </div>
            </Nav.Link>
          </div>
        ) : null}
        <div className="no-gutters row border-left fixed-width">
          <div className="col-lg-3 card-body pr-0 pt-0 pb-0 align-self-center">
            <img
              src={Logo}
              className="img-fluid align-self-center float-right img-thumbnail rounded-circle float-right"
              alt=""
            />
          </div>
          <div className="card-body col-lg-9 p-0 align-self-center">
            <p className="text-blue2 font-weight-bolder mt-2 ml-3 mb-0 align-self-center">
              {username != null ? username : null}
            </p>
            <h6 className="card-text font-weight-lighter text-muted text-nowrap ml-3 align-self-center">
              {roleString != null ? roleString : null}
            </h6>
          </div>
        </div>
        <div className="pr-0">
          <button
            onClick={e => {
              logout(token, dispatch);
            }}
            type="button"
            className="card-body btn btn-danger rounded-0 p-2"
            style={{height: '60px'}}
          >
            <ExitToAppIcon className="m-0" />
            <h6>odhlásiť</h6>
          </button>
        </div>
      </div>
    </>
  );
};
export default TopNavigationAH;
