import React from 'react';

function ToastUtil(title, description) {
  return (
    <>
      <h5>{title}</h5>
      <p>{description}</p>
    </>
  );
}

export default ToastUtil;
