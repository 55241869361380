import React from 'react';
import Form from 'react-bootstrap/Form';
import {useSelector, useDispatch} from 'react-redux';
import {
  reduxForm,
  Field,
  FieldArray,
  change,
  updateSyncErrors,
  touch,
  setSubmitFailed,
} from 'redux-form';
import {useParams} from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import editOffer from '../../../services/editOffer';
import {requestDTO} from '../../../services';
import ErrorFormText from '../Error/ErrorFormText';
import {BootstrapFormControlWithoutPopover} from '../../ReduxBootstrapFields';
import {selectors as selectorToken} from '../../../features/token';
import SubmitFormValidator, {
  normalizePositiveFloatKeepEmpty,
} from '../../Utils/SubmitFormValidator';

export const FORM_NAME = 'auctionMulticriterialOffersForm';

const AuctionMulticriterialOffersForm = props => {
  const form = useSelector(state => state.form[FORM_NAME]);
  const dispatch = useDispatch();
  // const {handleSubmit, pristine, reset, submitting} = props;
  const {handleSubmit, submitting} = props;
  const token = useSelector(selectorToken.getToken);
  const {auctionId} = useParams();
  const {id} = props;
  // const organizationId = useSelector(selectorToken.getOrganizationId);
  const handleClick = e => {
    e.preventDefault();
    if (SubmitFormValidator(form)) {
      dispatch(setSubmitFailed(FORM_NAME, ...Object.keys(form.syncErrors)));
      return;
    }
    editOffer(
      requestDTO.editOfferRequest(form.values, auctionId, id),
      token,
      dispatch,
      props.setModalShow(false)
    );
  };

  const changeFieldValue = (field, value) => {
    dispatch(change(FORM_NAME, field, value));
  };

  const dispatchFormErrors = errors => {
    dispatch(updateSyncErrors(FORM_NAME, errors));
  };

  const dispatchTouch = touched => {
    dispatch(touch(FORM_NAME, touched));
  };

  return (
    <Form className="mb-5 pl-0 pr-0" onSubmit={handleSubmit}>
      <FieldArray
        name="criteriaOffers"
        criteria={props.offers.criteriaOffers}
        criteriaOffersValues={props.offers.priceOffers}
        component={renderMembers}
        changeFieldValue={changeFieldValue}
        dispatchFormErrors={dispatchFormErrors}
        dispatchTouch={dispatchTouch}
        criteriaPriceOffersFormValues={form.values.priceOffers}
        formErrors={form.syncErrors}
      />
      <div className="row col-lg-12 m-0 pl-0 pr-0 mt-5">
        <div className="col-lg-12 ">
          <div className="col-lg-12 text-center">
            <div className="text-center container">
              <ErrorFormText formName={FORM_NAME} />
              <Button
                onClick={handleClick}
                className="text-white pt-4 pb-4 pl-5 pr-5 text-center"
                variant="success"
                type="submit"
                disabled={submitting}
              >
                Odoslať zmenu ponuky
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};

const renderMembers = ({
  criteria,
  criteriaOffersValues,
  fields,
  meta: {touched, error, submitFailed},
  changeFieldValue,
  dispatchFormErrors,
  dispatchTouch,
  criteriaPriceOffersFormValues,
  formErrors,
}) => {
  return (
    <div className="row col-lg-12 m-0 pl-0 pr-0">
      {fields.map((criteriaField, index) => (
        <React.Fragment key={criteriaField}>
          {criteriaOffersValues?.map((criteriaOfferValue, indexCrit) => (
            <React.Fragment key={`${criteriaField}-${criteriaOfferValue.id}`}>
              {criteriaOffersValues[indexCrit].criteriaUuid ===
              criteria[index].criteriaUuid ? (
                <div className="row col-lg-12 m-0 pl-5 pr-5">
                  <div
                    className="col-lg-6"
                    key={`criteriaDiv #${criteriaOfferValue.id + 1}`}
                  >
                    <div
                      className="col-lg-12"
                      key={`criteriaDiv #${criteriaOfferValue.id + 1}`}
                    >
                      <h5
                        className="mt-4 mb-0 bold-wight"
                        key={`criteriaName #${criteriaOfferValue.id + 1}`}
                      >
                        {criteriaOfferValue.itemName}
                      </h5>
                    </div>
                  </div>
                  <div
                    className="col-lg-5"
                    key={`criteriaDiv2 #${criteriaOfferValue.id + 1}`}
                  >
                    <Form.Group
                      controlId={`${criteriaOfferValue}.value`}
                      key={`criteriaValue #${criteriaOfferValue.id + 1}`}
                      className="p-0"
                    >
                      <Field
                        name={`priceOffers[${indexCrit}].value`}
                        component={BootstrapFormControlWithoutPopover}
                        className="mt-5 mb-5 p-0"
                        type="text"
                        placeholder="Názov"
                        required
                      />
                    </Form.Group>
                  </div>
                  <div
                    className="col-lg-1 text-muted center-horizontal pl-0"
                    key={`criteriaDiv3 #${index + 1}`}
                  >
                    {criteriaOfferValue.mj}
                  </div>
                </div>
              ) : null}
            </React.Fragment>
          ))}

          <div className="row col-lg-12 border-bottom pl-5 pr-5 m-0">
            <div className="col-lg-6" key={`criteriaDiv #${index + 1}`}>
              <div className="col-lg-12" key={`criteriaDiv #${index + 1}`}>
                <h5
                  className="mt-4 mb-0 bold-wight"
                  key={`criteriaName #${index + 1}`}
                >
                  {criteria[index].criteriaName}
                </h5>
              </div>
            </div>
            <div className="col-lg-5" key={`criteriaDiv2 #${index + 1}`}>
              <Form.Group
                controlId={`${criteriaField}.value`}
                key={`criteriaValue #${index + 1}`}
              >
                <Field
                  name={`${criteriaField}.value`}
                  component={BootstrapFormControlWithoutPopover}
                  className="mt-5 mb-5"
                  type="text"
                  placeholder="Názov"
                  normalize={normalizePositiveFloatKeepEmpty}
                  required
                />
              </Form.Group>
            </div>
            <div className="col-lg-1" key={`criteriaDiv3 #${index + 1}`}></div>
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};

export default reduxForm({
  form: FORM_NAME,
})(AuctionMulticriterialOffersForm);
