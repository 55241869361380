/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, {useState} from 'react';

function CollapseHidder(props) {
  const [isOpened, setIsOpened] = useState(false);
  if (props.info.length > 200) {
    return (
      <>
        {isOpened ? (
          <div>
            <p className="mb-2 text-muted">{props.info}</p>
          </div>
        ) : (
          <div className="col-lg-12 p-0">
            <p className="text-muted m-0 overflow-hidden">
              {props.info.substring(0, 196)}
            </p>
            <div className={props.className}> </div>
          </div>
        )}
        <div className="col-lg-12 p-0">
          <p
            className="show-more-btn"
            onClick={() => {
              setIsOpened(state => !state);
            }}
          >
            {isOpened ? 'Skryt informácie' : 'Zobraziť viac informácií'}
          </p>
        </div>
      </>
    );
  }
  return <p className="mb-2 text-muted">{props.info}</p>;
}

export default CollapseHidder;
