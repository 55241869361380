import React from 'react';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import {reduxForm, Field, setSubmitFailed} from 'redux-form';
import {useParams} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import UnitEnum from '../../enums/UnitEnum';
import {
  BootstrapFormControl,
  BootstrapDropDownControl,
  CriteriaMultipleMaterialAutocomplete,
} from '../../ReduxBootstrapFields';
import {actionTypes, selectors} from '../../../features/auction';
import {requestDTO} from '../../../services';
import editAuctionItems from '../../../services/editAuctionItems';
import {selectors as selectorToken} from '../../../features/token';
import ErrorFormText from '../Error/ErrorFormText';
import FIELD_NAMES from './AddOrEditItemFormFieldNames';
import InfoPopoverText from '../../Popover/InfoPopoverTexts';
import RoundLifeCycleEnum from '../../enums/RoundLifeCycleEnum';
import SubmitFormValidator, {
  required,
  maxLength298,
  maxLengthDescription,
  normalizeLength,
  normalizePositiveFloat,
} from '../../Utils/SubmitFormValidator';
import {selectors as selectorUAD} from '../../../features/userAuctionData';

export const TYPES = {
  EDIT: 'EDIT',
  ADD: 'ADD',
};
export const FORM_NAME = 'AddOrEditItemForm';

const warn = values => {
  const warnings = {};

  return warnings;
};

function AddOrEditItemForm(props) {
  const {type} = props;
  const dispatch = useDispatch();
  const auction = useSelector(selectors.getAuction);
  const isLoading = useSelector(selectors.getAuctionItemLoading);
  const form = useSelector(state => state.form[FORM_NAME]);
  const {auctionId} = useParams();
  /* TODO zmenit potom */
  const token = useSelector(selectorToken.getToken);
  const auctionData = useSelector(selectorUAD.getValues);
  const started = !!(
    auctionData != null &&
    auctionData.roundEnum !== RoundLifeCycleEnum.PREPARATION_PHASE
  );
  const handleClick = e => {
    e.preventDefault();
    switch (type) {
      case TYPES.ADD:
        if (SubmitFormValidator(form)) {
          dispatch(setSubmitFailed(FORM_NAME, ...Object.keys(form.syncErrors)));
          return;
        }
        dispatch({
          type: actionTypes.AUCTION_SHOW_LOADING,
        });
        dispatch({
          type: actionTypes.AUCTION_ITEM_SHOW_LOADING,
        });
        editAuctionItems(
          auctionId,
          requestDTO.createAddAuctionItemRequest(form.values, auction),
          token,
          dispatch,
          () => {
            props.setModalShow(false);
          },
          undefined,
          undefined,
          form.values,
          TYPES.ADD
        );
        break;
      case TYPES.EDIT:
        if (SubmitFormValidator(form)) {
          dispatch(setSubmitFailed(FORM_NAME, ...Object.keys(form.syncErrors)));
          return;
        }
        dispatch({
          type: actionTypes.AUCTION_SHOW_LOADING,
        });
        dispatch({
          type: actionTypes.AUCTION_ITEM_SHOW_LOADING,
        });
        editAuctionItems(
          auctionId,
          requestDTO.createEditAuctionItemRequest(form.values, auction),
          token,
          dispatch,
          () => {
            props.setModalShow(false);
          },
          undefined,
          undefined,
          form.values,
          TYPES.EDIT
        );
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Form>
        <div className="container pl-4 pr-4">
          <Form.Group className="pl-4 pr-4" controlId={FIELD_NAMES.NAME}>
            <Form.Label>
              Názov<i className="text-danger">*</i>
            </Form.Label>
            <Field
              name={FIELD_NAMES.NAME}
              type="text"
              label="Názov"
              validate={[required]}
              component={BootstrapFormControl}
              normalize={normalizeLength(300)}
              maxLength="300"
              text={InfoPopoverText.ITEMS.NAME}
            />
          </Form.Group>

          {/* <Form.Group controlId={FIELD_NAMES.TYPE}>
            <Form.Label>Typ položky:</Form.Label>
            <Field
              name={FIELD_NAMES.TYPE}
              as="select"
              label="Typ položky:"
              component={BootstrapDropDownControl}
              text={InfoPopoverText.ITEMS.TYPE}>
              <option>Cenové</option>
            </Field>
          </Form.Group> */}
          {/* <Form.Group className="pl-4 pr-4" controlId={FIELD_NAMES.PRICEAFFECT}>
            <Field
              custom
              name={FIELD_NAMES.PRICEAFFECT}
              type="checkbox"
              label="Ovplyvňuje cenu"
              text={InfoPopoverText.ITEMS.PRICEAFFECT}
              component={BootstrapFormCheck}
            />
          </Form.Group> */}
          <div
            className={
              form.values !== undefined &&
              form.values[FIELD_NAMES.UNIT] ===
                Object.entries(UnitEnum).find(e => e[1] === UnitEnum.CUSTOM)[0]
                ? 'rounded-lg bg-blue5'
                : null
            }
          >
            <Form.Group className="pl-4 pr-4" controlId={FIELD_NAMES.UNIT}>
              <Form.Label>Merná jednotka</Form.Label>
              <Field
                name={FIELD_NAMES.UNIT}
                as="select"
                label="Merná jednotka"
                maxLength="298"
                component={BootstrapDropDownControl}
                text={InfoPopoverText.ITEMS.UNIT}
              >
                {Object.entries(UnitEnum).map(pair => (
                  <option key={pair[0]} value={pair[0]}>
                    {pair[1]}
                  </option>
                ))}
              </Field>
            </Form.Group>
            {form.values !== undefined &&
            form.values[FIELD_NAMES.UNIT] ===
              Object.entries(UnitEnum).find(
                e => e[1] === UnitEnum.CUSTOM
              )[0] ? (
              <Form.Group
                className="pl-4 pr-4"
                controlId={FIELD_NAMES.CUSTOM_UNIT}
              >
                <Form.Label>Vlastná merná jednotka</Form.Label>
                <Field
                  name={FIELD_NAMES.CUSTOM_UNIT}
                  type="text"
                  label="Vlastná merná jednotka"
                  normalize={normalizeLength(254)}
                  validate={maxLength298}
                  component={BootstrapFormControl}
                  maxLength="254"
                  text={InfoPopoverText.ITEMS.CUSTOM_UNIT}
                />
              </Form.Group>
            ) : null}
          </div>
          <Form.Group className="pl-4 pr-4" controlId={FIELD_NAMES.AMOUNT}>
            <Form.Label>
              Požadované množstvo<i className="text-danger">*</i>
            </Form.Label>
            <Field
              name={FIELD_NAMES.AMOUNT}
              type="text"
              label="Požadované množstvo"
              normalize={normalizePositiveFloat}
              validate={required}
              component={BootstrapFormControl}
              maxLength="254"
              text={InfoPopoverText.ITEMS.AMOUNT}
            />
          </Form.Group>
          <Form.Group className="pl-4 pr-4" controlId={FIELD_NAMES.DESCRIPTION}>
            <Form.Label>Popis položky</Form.Label>
            <Field
              name={FIELD_NAMES.DESCRIPTION}
              as="textarea"
              rows="3"
              label="Popis položky"
              component={BootstrapDropDownControl}
              normalize={normalizeLength(4000)}
              maxLength="4000"
              text={InfoPopoverText.ITEMS.DESCRIPTION}
              validate={[maxLengthDescription]}
            />
          </Form.Group>
          <Form.Group className="pl-4 pr-4" controlId={FIELD_NAMES.CRITERIA}>
            <Form.Label>Kritéria pre položku</Form.Label>
            <Field
              multiple
              filterSelectedOptions
              id="tags-standart"
              options={auction.criteria}
              getOptionLabel={option => `${option.name}`}
              name={FIELD_NAMES.CRITERIA}
              style={{width: '100%'}}
              type="text"
              component={CriteriaMultipleMaterialAutocomplete}
              text={InfoPopoverText.AUCTION.CRITERIUM}
            />
          </Form.Group>
          <div className="text-center container">
            {started !== null && !started ? (
              <>
                <ErrorFormText formName={FORM_NAME} />
                <Button
                  onClick={handleClick}
                  variant="success"
                  type="submit"
                  disabled={isLoading}
                  className="ml-5 mr-5 mb-5 mt-0 pt-3 pb-3 pl-5 pr-5"
                >
                  {isLoading ? (
                    <Spinner animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  ) : (
                    'Ulož'
                  )}
                </Button>
              </>
            ) : null}
          </div>
        </div>
      </Form>
    </>
  );
}

export default reduxForm({
  form: FORM_NAME,
  warn,
})(AddOrEditItemForm);
