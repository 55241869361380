import React, {useState} from 'react';
import {useSelector} from 'react-redux';

// import ButtonToolbar from 'react-bootstrap/ButtonToolbar';

import Button from 'react-bootstrap/Button';
import InfoIcon from '@material-ui/icons/Info';
import EditPenIcon from '../../MenuIcons/EditPenIcon';
// import {actionTypes} from '../../../features/auction';
import AddOrEditCriteriaModal, {
  TYPES as MODAL_TYPES,
} from './AddOrEditCriteriaModal';
import {selectors as selectorUAD} from '../../../features/userAuctionData';
import RoundLifeCycleEnum from '../../enums/RoundLifeCycleEnum';

function EditCriteriaButton(props) {
  const [modalShow, setModalShow] = useState(false);
  // const dispatch = useDispatch();
  const auctionData = useSelector(selectorUAD.getValues);
  // const handleEdit = e => {
  //   e.preventDefault();
  //   const initialState = {};
  //   dispatch({
  //     type: actionTypes.AUCTION_SET_INITIAL,
  //     auction: initialState,
  //   });
  //   setModalShow(true);
  // };

  return (
    <>
      <Button
        // className="text-primary text-nowrap p-1 mt-1"
        onClick={() => setModalShow(true)}
        // onKeyDown={() => setModalShow(true)}
        // role="button"
        tabIndex={0}
      >
        {auctionData !== null &&
        auctionData.roundEnum === RoundLifeCycleEnum.PREPARATION_PHASE ? (
          <>
            <EditPenIcon className="icon-white" /> Upraviť
          </>
        ) : (
          <>
            <InfoIcon className="icon-white" /> Info
          </>
        )}
      </Button>
      <AddOrEditCriteriaModal
        id={props.id}
        type={MODAL_TYPES.EDIT}
        show={modalShow}
        onHide={() => setModalShow(false)}
        setModalShow={setModalShow}
      />
    </>
  );
}
export default EditCriteriaButton;
