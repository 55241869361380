export const AUCTION_UPDATE = 'AUCTION_UPDATE';
export const AUCTION_SHOW_LOADING = 'AUCTION_SHOW_LOADING';
export const AUCTION_HIDE_LOADING = 'AUCTION_HIDE_LOADING';
export const AUCTION_SET_INITIAL = 'AUCTION_SET_INITIAL';
export const AUCTION_START_SHOW_LOADING = 'AUCTION_START_SHOW_LOADING';
export const AUCTION_START_HIDE_LOADING = 'AUCTION_START_HIDE_LOADING';
export const AUCTION_STOP_SHOW_LOADING = 'AUCTION_STOP_SHOW_LOADING';
export const AUCTION_STOP_HIDE_LOADING = 'AUCTION_STOP_HIDE_LOADING';
export const AUCTION_CRITERIA_SHOW_LOADING = 'AUCTION_CRITERIA_SHOW_LOADING';
export const AUCTION_CRITERIA_HIDE_LOADING = 'AUCTION_CRITERIA_HIDE_LOADING';
export const AUCTION_ITEM_SHOW_LOADING = 'AUCTION_ITEM_SHOW_LOADING';
export const AUCTION_ITEM_HIDE_LOADING = 'AUCTION_ITEM_HIDE_LOADING';
