import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Link, useRouteMatch, useLocation} from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Spinner from 'react-bootstrap/Spinner';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import BookmarksOutlinedIcon from '@material-ui/icons/BookmarksOutlined';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import AssignmentLateOutlinedIcon from '@material-ui/icons/AssignmentLateOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import LoopOutlinedIcon from '@material-ui/icons/LoopOutlined';
import PictureAsPdfOutlinedIcon from '@material-ui/icons/PictureAsPdfOutlined';
import Button from 'react-bootstrap/Button';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import HammerIcon from '../MenuIcons/HammerIcon/HammerIcon';
import StatisticIcon from '../MenuIcons/StatisticIcon/StatisticIcon';
import loadMenuState from '../../services/loadMenuState';
import editMenuState from '../../services/editMenuState';
import {selectors} from '../../features/menustate';
import {selectors as selectorToken} from '../../features/token';
import {requestDTO} from '../../services';
import VisitedIcon from '../MenuIcons/VisitedIcon/VisitedIcon';
import NotVisitedIcon from '../MenuIcons/NotVisitedIcon/NotVisitedIcon';
import ExportToPdf from '../../services/exportToPdf';
import stopAuction from '../../services/stopAuction';
import {selectors as selectorUAD} from '../../features/userAuctionData';
import {
  selectors as selectorAuction,
  actionTypes,
} from '../../features/auction';
import {
  StartAuctionButton as StartAuctionBtn,
  StopAuctionButton as StopAuctionBtn,
} from '../Modals/AuctionStart';

import RoundLifeCycleEnum from '../enums/RoundLifeCycleEnum';

let visitedAllMenu = true;
const SideBar = () => {
  const dispatch = useDispatch();
  const match = useRouteMatch('/:auctionId');
  const location = useLocation();
  const {auctionId} = match.params;
  const token = useSelector(selectorToken.getToken);
  const menuState = useSelector(selectors.getMenuState);
  const auctionData = useSelector(selectorUAD.getValues);
  const auctionStartLoading = useSelector(
    selectorAuction.getStartAuctionLoading
  );
  const auctionStopLoading = useSelector(selectorAuction.getStopAuctionLoading);
  const handleExportClick = e => ExportToPdf(auctionId, token, dispatch);

  const handleClick = id => e => {
    if (!visitedAllMenu) {
      editMenuState(
        auctionId,
        requestDTO.editMenuStateRequest(menuState, auctionId, id),
        token,
        dispatch
      );
    }
  };

  // const handleStartAuctionClick = e => {
  //   dispatch({
  //     type: actionTypes.AUCTION_START_SHOW_LOADING,
  //   });
  //   startAuction(auctionId, token, dispatch);
  // };
  const handleStopAuctionClick = e => {
    dispatch({
      type: actionTypes.AUCTION_STOP_SHOW_LOADING,
    });
    stopAuction(auctionId, token, dispatch);
  };
  useEffect(() => {
    loadMenuState(auctionId, token, dispatch);
  }, [auctionId, dispatch, token]);

  return (
    <Navbar variant="transparent" bg="transparent" className="p-0">
      <div className="col-md-12 no-gutters pl-0 pr-0">
        {menuState != null ? (
          <>
            <Nav as="div" className="flex-column">
              <Nav className="bg-blue2 text-left">
                <Nav.Item className="col-lg-12 col-md-12  pt-3 pb-3">
                  <span className="text-nowrap text-white h6">
                    <SettingsOutlinedIcon className="mr-2" />
                    Nastavenie zakázky
                  </span>
                </Nav.Item>
              </Nav>
              <Nav
                as="div"
                className="flex-column"
                activeKey={location.pathname}
              >
                <Nav.Item>
                  {/* eventKey kvôli visited */}
                  <Nav.Link
                    onClick={handleClick('eu.starbug.auctionhall.auction')}
                    as={Link}
                    eventKey={`/${auctionId}/auction_setting/auction`}
                    to={`/${auctionId}/auction_setting/auction`}
                    className="text-blue col-lg-12 col-md-12 pr-3 mr-0"
                  >
                    <div className="pb-4">
                      <p className="pl-5 mb-0 navbar-fix-width float-right">
                        <ListAltOutlinedIcon className="text-white mr-2" />
                        Zakázka
                        {visitedMenuLink(
                          'eu.starbug.auctionhall.auction',
                          menuState
                        )}
                      </p>
                    </div>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    onClick={handleClick('eu.starbug.auctionhall.users')}
                    as={Link}
                    eventKey={`/${auctionId}/auction_orders/users`}
                    to={`/${auctionId}/auction_orders/users`}
                    className="text-blue col-lg-12 col-md-12 pr-3 mr-0"
                  >
                    <div className="pb-4">
                      <p className="pl-5 mb-0 navbar-fix-width float-right">
                        <PeopleAltOutlinedIcon className="text-white mr-2" />
                        Uchádzači
                        {visitedMenuLink(
                          'eu.starbug.auctionhall.users',
                          menuState
                        )}
                      </p>
                    </div>
                  </Nav.Link>
                </Nav.Item>
                <div className=" ">
                  <Nav.Item>
                    <Nav.Link
                      onClick={handleClick('eu.starbug.auctionhall.criteria')}
                      as={Link}
                      eventKey={`/${auctionId}/auction_orders/criteria`}
                      to={`/${auctionId}/auction_orders/criteria`}
                      className="text-blue col-lg-12 col-md-12 pr-3 mr-0"
                    >
                      <div className="pb-4">
                        <p className="pl-5 mb-0 navbar-fix-width float-right">
                          <AssignmentLateOutlinedIcon className="text-white mr-2" />
                          Kritéria aukcie
                          {visitedMenuLink(
                            'eu.starbug.auctionhall.criteria',
                            menuState
                          )}
                        </p>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    {/* eventKey kvôli visited */}
                    <Nav.Link
                      onClick={handleClick('eu.starbug.auctionhall.items')}
                      as={Link}
                      eventKey={`/${auctionId}/auction_orders/items`}
                      to={`/${auctionId}/auction_orders/items`}
                      className="text-blue col-lg-12 col-md-12 pr-3 mr-0"
                    >
                      <div className="pb-4">
                        <p className="pl-5 mb-0 navbar-fix-width float-right">
                          <BookmarksOutlinedIcon className="text-white mr-2" />
                          Položky
                          {visitedMenuLink(
                            'eu.starbug.auctionhall.items',
                            menuState
                          )}
                        </p>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                </div>
                <div className="bg-blue2 text-left mt-5">
                  <Nav.Item className="col-lg-12 col-md-12  pt-3 pb-3">
                    <span className="text-nowrap text-white h6">
                      <HammerIcon
                        width="19px"
                        height="19px"
                        fill="#FFFFFF"
                        className="mr-2"
                      />
                      Nastavenie Aukcie
                    </span>
                  </Nav.Item>
                </div>
                {/* <Nav.Item>
                  <Nav.Link
                    onClick={handleClick('eu.starbug.auctionhall.auction')}
                    as={Link}
                    eventKey={`/${auctionId}/auction_setting/auction`}
                    to={`/${auctionId}/auction_setting/auction`}
                    className="text-blue col-lg-12 col-md-12 pr-3"
                  >
                    <div className="pb-4">
                      <p className="pl-5 mb-0 navbar-fix-width float-right">
                        <SettingsOutlinedIcon className="text-white mr-2" />
                        Aukcia
                        {visitedMenuLink(
                          'eu.starbug.auctionhall.auction',
                          menuState
                        )}
                      </p>
                    </div>
                  </Nav.Link>
                </Nav.Item> */}
                <Nav.Item>
                  <Nav.Link
                    as={Link}
                    eventKey={`/${auctionId}/auction_setting/rounds`}
                    to={`/${auctionId}/auction_setting/rounds`}
                    className="text-blue col-lg-12 col-md-12 pr-3 mr-0"
                  >
                    <div className="pb-4">
                      <p className="pl-5 mb-0 navbar-fix-width float-right">
                        <LoopOutlinedIcon className="text-white mr-2" />
                        Aukčné kolo
                        {visitedMenuLink(
                          'eu.starbug.auctionhall.rounds',
                          menuState
                        )}
                      </p>
                    </div>
                  </Nav.Link>
                </Nav.Item>
                {auctionData != null &&
                auctionData.roundEnum !==
                  RoundLifeCycleEnum.PREPARATION_PHASE ? (
                  <Nav.Item>
                    <Nav.Link
                      as={Link}
                      to="#"
                      className="text-blue col-lg-12 col-md-12 mt-4"
                    >
                      {auctionData.roundEnum ===
                      RoundLifeCycleEnum.ACTIVE_PHASE ? (
                        <StopAuctionBtn
                          disabled={auctionStartLoading}
                          id={auctionId}
                          className="col-lg-12 p-3"
                          variant="success"
                        />
                      ) : (
                        <>
                          {auctionData.roundEnum ===
                            RoundLifeCycleEnum.END_PHASE ||
                          auctionData.roundEnum ===
                            RoundLifeCycleEnum.STOPPED_PHASE ? null : (
                            <Button
                              disabled={auctionStopLoading}
                              onClick={handleStopAuctionClick}
                              className="col-lg-12 p-3"
                              variant="danger"
                            >
                              {auctionStopLoading ? (
                                <Spinner animation="border" role="status">
                                  <span className="sr-only">Loading...</span>
                                </Spinner>
                              ) : (
                                ' Zastaviť aukciu'
                              )}
                            </Button>
                          )}
                        </>
                      )}
                    </Nav.Link>
                  </Nav.Item>
                ) : (
                  <Nav.Item>
                    {/* <Nav.Link
                      as={Link}
                      className="text-blue col-lg-12 col-md-12 mt-4"
                      to="#"
                    > */}
                    <StartAuctionBtn
                      disabled={auctionStartLoading}
                      id={auctionId}
                      className="col-lg-12 p-3"
                      variant="success"
                    />
                    {/* </Nav.Link> */}
                  </Nav.Item>
                )}

                <div
                  as="ul"
                  className="flex-column mb-5 mt-3 pt-3 pb-3 menu-div"
                >
                  <div className="bg-blue2 text-left">
                    <Nav.Item className="col-lg-12 col-md-12  pt-3 pb-3">
                      <span className="text-nowrap text-white h6">
                        <StatisticIcon className="mr-2" />
                        Administračné funkcie
                      </span>
                    </Nav.Item>
                  </div>
                  <Nav.Item>
                    <Nav.Link
                      onClick={handleExportClick}
                      as={Link}
                      className="text-blue col-lg-12 col-md-12 pr-3 mr-0"
                      to="#"
                    >
                      <div className="pb-4">
                        <p className="pl-5 mb-0 navbar-fix-width float-right">
                          <PictureAsPdfOutlinedIcon className="text-white mr-2" />
                          Export aukcie (pdf)
                        </p>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      as={Link}
                      eventKey={`/${auctionId}/auction_setting/audit_log`}
                      to={`/${auctionId}/auction_setting/audit_log`}
                      className="text-blue col-lg-12 col-md-12 pr-3 mr-0"
                    >
                      <div className="pb-4">
                        <p className="pl-5 mb-0 navbar-fix-width float-right">
                          <FormatListBulletedIcon className="text-white mr-2" />
                          Audit log
                        </p>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                </div>
              </Nav>
            </Nav>
          </>
        ) : (
          ''
        )}
      </div>
    </Navbar>
  );
};

function visitedMenuLink(id, menuStateArray) {
  let component = null;
  menuStateArray.forEach(element => {
    if (element.key === id) {
      switch (element.menuStateEnum) {
        case 'VISITED':
          component = <VisitedIcon className="float-right" />;
          break;
        default:
          visitedAllMenu = false;
          component = <NotVisitedIcon className="float-right" />;
          break;
      }
    }
  });
  return component;
}
export default SideBar;
