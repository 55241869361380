/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import checkAuctionState from '../../../services/checkAuctionState';
import StartAuctionModal from './StartAuctionModal';
import {selectors as selectorToken} from '../../../features/token';
import loadOrganisations from '../../../services/loadOrganisations';
import {actionTypes} from '../../../features/userAuctionData';

function StartAuctionButton(props) {
  const [modalShow, setModalShow] = useState(false);
  const dispatch = useDispatch();
  const token = useSelector(selectorToken.getToken);

  useEffect(() => {
    loadOrganisations(props.id, token, dispatch);
  }, [props.id, dispatch, token]);

  const handleClick = e => {
    checkAuctionState(
      props.id,
      token,
      dispatch,
      undefined,
      undefined,
      setModalShow(true)
    );
  };
  return (
    <ButtonToolbar>
      <Button
        disabled={props.disabled}
        className="col-lg-12 p-3"
        variant="success"
        onClick={handleClick}
      >
        {props.disabled ? (
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        ) : (
          'Spustiť aukciu'
        )}
      </Button>
      <StartAuctionModal
        id={props.id}
        name={props.name}
        show={modalShow}
        onHide={() => {
          setModalShow(false);
          dispatch({
            type: actionTypes.USER_AUCTION_START_STATE_CLEAR,
          });
        }}
      />
    </ButtonToolbar>
  );
}
export default StartAuctionButton;
