import ordersFormFieldNames from '../../components/Pages/Orders/OrdersFormFieldNames';
import {
  AUCTION_UPDATE,
  AUCTION_SHOW_LOADING,
  AUCTION_HIDE_LOADING,
  AUCTION_SET_INITIAL,
  AUCTION_START_SHOW_LOADING,
  AUCTION_START_HIDE_LOADING,
  AUCTION_STOP_SHOW_LOADING,
  AUCTION_STOP_HIDE_LOADING,
  AUCTION_CRITERIA_SHOW_LOADING,
  AUCTION_CRITERIA_HIDE_LOADING,
  AUCTION_ITEM_SHOW_LOADING,
  AUCTION_ITEM_HIDE_LOADING,
} from './actionTypes';

const initialState = {
  value: null,
  loading: true,
  ordersFormInitial: null,
  loadingStartAuction: false,
  loadingStopAuction: false,
  loadingEditOrAddCriteria: false,
  loadingEditOrAddItems: false,
  maxPoints: null,
};

export default (state = initialState, action) => {
  const auctionFormInitial = {};
  let points = 0;
  switch (action.type) {
    case AUCTION_UPDATE:
      action.auction.criteria.forEach(value => {
        // eslint-disable-next-line operator-assignment
        points = points + value.maximumPoints;
      });
      return {...state, value: action.auction, maxPoints: points};

    case AUCTION_SHOW_LOADING:
      return {...state, loading: true};

    case AUCTION_HIDE_LOADING:
      return {...state, loading: false};

    case AUCTION_START_SHOW_LOADING:
      return {...state, loadingStartAuction: true};

    case AUCTION_START_HIDE_LOADING:
      return {...state, loadingStartAuction: false};

    case AUCTION_STOP_SHOW_LOADING:
      return {...state, loadingStopAuction: true};

    case AUCTION_STOP_HIDE_LOADING:
      return {...state, loadingStopAuction: false};

    case AUCTION_CRITERIA_SHOW_LOADING:
      return {...state, loadingEditOrAddCriteria: true};

    case AUCTION_CRITERIA_HIDE_LOADING:
      return {...state, loadingEditOrAddCriteria: false};

    case AUCTION_ITEM_SHOW_LOADING:
      return {...state, loadingEditOrAddItems: true};

    case AUCTION_ITEM_HIDE_LOADING:
      return {...state, loadingEditOrAddItems: false};

    case AUCTION_SET_INITIAL:
      auctionFormInitial[ordersFormFieldNames.NAME] =
        action.auctionSettingsData.name;
      return {...state, ordersFormInitial: auctionFormInitial};

    default:
      return state;
  }
};
