import React from 'react';

import Modal from 'react-bootstrap/Modal';
import {useSelector} from 'react-redux';
import {selectors} from '../../../features/modals';

function ErrorModal(props) {
  const error = useSelector(selectors.getError);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        closeButton
        className="border-0 bg-danger pt-5 error-border"
      >
        <div className="text-white container">
          <Modal.Title
            as="h4"
            id="contained-modal-title-vcenter"
            className="font-weight-bolder"
          >
            {error.title}
            <br />
            <p className="h5">Prosím kontaktujte správcu.</p>
          </Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="col-lg-12 p-4 text-break">{error.description}</div>
      </Modal.Body>
    </Modal>
  );
}

export default ErrorModal;
