import axios from 'axios';
import settings from './settings';
import {actionTypes} from '../features/auction';
import {actionTypes as errorActionType} from '../features/modals';

const exportToPdf = (
  id,
  token,
  dispatch,
  thenCallback,
  catchCallback,
  finallyCallback
) => {
  axios
    .post(
      settings.SERVICE_URL + settings.SERVICES.EXPORT_TO_PDF,
      {
        auctionId: id,
      },
      {
        responseType: 'blob',
        headers: {
          'Ela-Elc-Auth': token,
        },
      }
    )
    .then(response => {
      // console.log(response.data);
      const {data} = response;
      const fileType = '.pdf';
      // eslint-disable-next-line prefer-template
      const fileName = 'Auction ' + new Date().toDateString();

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(data, fileName + fileType);
        // eslint-disable-next-line no-else-return
      } else {
        const a = window.document.createElement('a');
        a.href = window.URL.createObjectURL(data, {
          type: 'application/pdf',
        });
        a.download = fileName + fileType;
        // Append anchor to body.
        document.body.appendChild(a);
        a.click();
        // Remove anchor from body
        document.body.removeChild(a);
      }

      if (typeof thenCallback === 'function') {
        thenCallback(response);
      }
    })
    .catch(error => {
      // handle error
      // console.log(error);
      if (error.response != null && error.response.data != null) {
        const {data} = error.response;
        dispatch({
          type: errorActionType.MODAL_ERROR_SHOW,
          modal: {title: data.message, description: data.description},
        });
      }
      if (typeof catchCallback === 'function') {
        catchCallback(error);
      }
    })
    .finally(() => {
      dispatch({
        type: actionTypes.AUCTION_HIDE_LOADING,
      });
      if (typeof finallyCallback === 'function') {
        finallyCallback();
      }
    });
};

export default exportToPdf;
