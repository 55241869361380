export default {
  AUCTION_ROUND_NAME: 'auctionRoundName',
  DURATION: 'duration',
  RENEWDURATION: 'renewDuration',
  RENEWTYPE: 'renewType',
  ROUNDTYPE: 'roundType',
  ROUND_EXTENSION_CASE: 'roundExtensionCaseEnum',
  SETTING: {
    ADMIN_HELP_CALC_SHOW: 'auctionRoundSettings.admin_help_calc_show',
    ALLOW_BEAT_FIRST: 'auctionRoundSettings.allow_beat_first',
    ALLOW_BEAT_OTHER: 'auctionRoundSettings.allow_beat_other',
    ALLOW_BEAT_TOP_ITEM_VAL: 'auctionRoundSettings.allow_beat_top_item_val',
    ALLOW_BIGGER_OFFER: 'auctionRoundSettings.allow_bigger_offer',
    AUCTIONROUNDSTARTTIME: 'auctionRoundSettings.auctionRoundStartTime',
    BEST_OPT_OFFER_SHOW: 'auctionRoundSettings.best_opt_offer_show',
    NULL_TIME_AT_RENEWAL: 'auctionRoundSettings.null_time_at_renewal',
    RENEWNULLCURRENTTIME: 'auctionRoundSettings.renewNullCurrentTime',
    SHOW_CONTRACTOR_INF: 'auctionRoundSettings.show_contractor_inf',
    SHOW_STR_PRICE_COLLNAME: 'auctionRoundSettings.show_str_price_collname',
    SHOW_USER_OFFER_END_PRC: 'auctionRoundSettings.show_user_offer_end_prc',
    SHOW_USER_TOP_END_PRC: 'auctionRoundSettings.show_user_top_end_prc',
    SHOW_USER_TOP_ITEM_PRC: 'auctionRoundSettings.show_user_top_item_prc',
    START_PRICE_SHOW: 'auctionRoundSettings.start_price_show',
    USER_HELP_CALC_SHOW: 'auctionRoundSettings.user_help_calc_show',
    USER_HELP_ORD_CALC_SHOW: 'auctionRoundSettings.user_help_ord_calc_show',
    USER_ITEM_ORDER_SHOW: 'auctionRoundSettings.user_item_order_show',
    USER_ORDER_SHOW: 'auctionRoundSettings.user_order_show',
    USER_TOP_COLL_OFF_SHOW: 'auctionRoundSettings.user_top_coll_off_show',
    USER_TOP_HELP_CALC_SHOW: 'auctionRoundSettings.user_top_help_calc_show',
  },
  TIME_FOR_DURATION_RAISE: 'timeForDurationRaise',
};
