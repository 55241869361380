import {MENU_STATE_UPDATE} from './actionTypes';

const initialState = {
  menuState: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MENU_STATE_UPDATE:
      return {...state, menuState: action.payload};
    default:
      return state;
  }
};
