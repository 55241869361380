import {
  ROUNDS_UPDATE,
  ROUNDS_SHOW_LOADING,
  ROUNDS_HIDE_LOADING,
  ROUNDS_ADD_SUCCESS,
  ROUNDS_ADD_FAILED,
  ROUNDS_EDIT_SUCCESS,
  ROUNDS_EDIT_FAILED,
  ROUNDS_EDIT_SET_INITIAL,
} from './actionTypes';

const initialState = {
  rounds: null,
  loading: true,
  editRoundFormInitial: {},
  addSuccess: null,
  editSuccess: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ROUNDS_UPDATE:
      return {...state, rounds: action.payload};
    case ROUNDS_SHOW_LOADING:
      return {...state, loading: true};
    case ROUNDS_HIDE_LOADING:
      return {...state, loading: false};
    case ROUNDS_EDIT_SET_INITIAL:
      return {
        ...state,
        editRoundFormInitial: action.payload,
      };
    case ROUNDS_ADD_SUCCESS:
      return {
        ...state,
        addSuccess: true,
      };
    case ROUNDS_ADD_FAILED:
      return {
        ...state,
        addSuccess: false,
      };
    case ROUNDS_EDIT_SUCCESS:
      return {
        ...state,
        editSuccess: true,
      };
    case ROUNDS_EDIT_FAILED:
      return {
        ...state,
        editSuccess: false,
      };
    default:
      return state;
  }
};
