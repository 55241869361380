import React from 'react';

function GraphUpIcon(props) {
  return (
    <svg
      {...props}
      version="1.1"
      id="IconsRepoEditor"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 17 17"
      xmlSpace="preserve"
      width={props.width != null ? props.width : '17px'}
      height={props.height != null ? props.height : '17px'}
      fill="none"
      className={props.className != null ? props.className : null}
      strokeWidth="0"
    >
      <path
        d="M16.6788 15.995H16.0927V7.48401C16.0927 7.08786 15.7704 6.76558 15.3742 6.76558H14.0641C13.6679 6.76558 13.3456 7.08789 13.3456 7.48401V15.995H11.8573V11.3402C11.8573 10.944 11.535 10.6217 11.1388 10.6217H9.82865C9.4325 10.6217 9.11022 10.944 9.11022 11.3402V15.995H7.62184V13.5843C7.62184 13.1882 7.29953 12.8659 6.90341 12.8659H5.59321C5.19706 12.8659 4.87478 13.1882 4.87478 13.5843V15.995H4.257C4.10939 15.995 3.98975 16.1147 3.98975 16.2623C3.98975 16.4099 4.10939 16.5295 4.257 16.5295H16.6788C16.8264 16.5295 16.9461 16.4099 16.9461 16.2623C16.9461 16.1147 16.8264 15.995 16.6788 15.995Z"
        fill={props.fill != null ? props.fill : '#8FABC7'}
      />
      <path
        d="M16.9479 0.444667C16.9538 0.329272 16.9132 0.211932 16.8251 0.12381C16.737 0.0356884 16.6196 -0.00494733 16.5042 0.00101484C16.4974 0.000718842 16.4907 0 16.4838 0H13.9255C13.692 0 13.5027 0.189309 13.5027 0.422848C13.5027 0.656388 13.692 0.845697 13.9255 0.845697H15.5052L11.325 5.02589L9.74863 3.44951C9.66935 3.37023 9.56177 3.32566 9.44963 3.32566C9.33749 3.32566 9.22992 3.37023 9.15064 3.44951L1.54913 11.0511C1.38401 11.2162 1.38401 11.4839 1.54913 11.6491C1.63172 11.7316 1.73992 11.7729 1.84813 11.7729C1.95634 11.7729 2.06459 11.7316 2.14713 11.649L9.44963 4.34646L11.026 5.92284C11.1911 6.08796 11.4589 6.08796 11.624 5.92284L16.1032 1.44373V2.87338C16.1032 3.10692 16.2925 3.29623 16.526 3.29623C16.7596 3.29623 16.9489 3.10692 16.9489 2.87338V0.465133C16.9489 0.458241 16.9482 0.451517 16.9479 0.444667Z"
        fill={props.fill != null ? props.fill : '#8FABC7'}
      />
      <path
        d="M0.422848 13.2483C0.656381 13.2483 0.845697 13.059 0.845697 12.8254C0.845697 12.5919 0.656381 12.4026 0.422848 12.4026C0.189316 12.4026 0 12.5919 0 12.8254C0 13.059 0.189316 13.2483 0.422848 13.2483Z"
        fill={props.fill != null ? props.fill : '#8FABC7'}
      />
    </svg>
  );
}
export default GraphUpIcon;
