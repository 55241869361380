import {
  AUDIT_LOG_UPDATE,
  AUDIT_LOG_SHOW_LOADING,
  AUDIT_LOG_HIDE_LOADING,
  AUDIT_LOG_BY_ID_UPDATE,
  HISTORY_OFFERS_UPDATE,
  AUDIT_LOG_SELECTED_SHOW_LOADING,
  AUDIT_LOG_SELECTED_HIDE_LOADING,
} from './actionTypes';

const initialState = {
  log: null,
  loading: true,
  selectedLog: null,
  offersHistory: null,
  selectedLogLoading: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case AUDIT_LOG_UPDATE:
      return {...state, log: action.payload};
    case HISTORY_OFFERS_UPDATE:
      return {...state, offersHistory: action.payload};

    case AUDIT_LOG_SHOW_LOADING:
      return {...state, loading: true};

    case AUDIT_LOG_HIDE_LOADING:
      return {...state, loading: false};

    case AUDIT_LOG_SELECTED_SHOW_LOADING:
      return {...state, selectedLogLoading: true};

    case AUDIT_LOG_SELECTED_HIDE_LOADING:
      return {...state, selectedLogLoading: false};

    case AUDIT_LOG_BY_ID_UPDATE:
      return {...state, selectedLog: action.payload.auditLog};
    default:
      return state;
  }
};
