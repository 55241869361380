import React from 'react';

function StatisticIcon(props) {
  return (
    <svg
      {...props}
      version="1.1"
      id="IconsRepoEditor"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 18 18"
      xmlSpace="preserve"
      width={props.width != null ? props.width : '18px'}
      height={props.height != null ? props.height : '18px'}
      fill="none"
      strokeWidth="0"
    >
      <path
        d="M3.75 0.750004H0V17.25H18V0.750004H3.75ZM1.5 2.25H3.75V4.125H1.5V2.25ZM16.5 15.75H1.5V5.625H16.5V15.75ZM16.5 4.125H5.25V2.25H16.5V4.125Z"
        fill="white"
      />
      <path d="M3 7.5H10.5V9H3V7.5Z" fill="white" />
      <path d="M3 10.125H9V11.625H3V10.125Z" fill="white" />
      <path d="M3 12.75H13.875V14.25H3V12.75Z" fill="white" />
    </svg>
  );
}
export default StatisticIcon;
