import React from 'react';

import {useSelector} from 'react-redux';
// import {selectors} from '../../../features/modals';

function ErrorFormText(props) {
  const form = useSelector(state => state.form[props.formName]);

  return (
    <>
      {form && form.syncErrors && form.anyTouched ? (
        <div className="pt-5">
          <p className="text-danger">
            Skontrolujte,či máte správne vyplnený formulár!
          </p>
        </div>
      ) : (
        ''
      )}
    </>
  );
}

export default ErrorFormText;
