import React from 'react';

function GraphDownIcon(props) {
  return (
    <svg
      {...props}
      version="1.1"
      id="IconsRepoEditor"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 18 17"
      xmlSpace="preserve"
      width={props.width != null ? props.width : '18px'}
      height={props.height != null ? props.height : '17px'}
      fill="none"
      className={props.className != null ? props.className : null}
      strokeWidth="0"
    >
      <path
        d="M0.267457 15.995H0.853621V7.48399C0.853621 7.08784 1.17593 6.76556 1.57205 6.76556H2.88223C3.27837 6.76556 3.60065 7.08787 3.60065 7.48399V15.995H5.08904V11.3402C5.08904 10.944 5.41132 10.6217 5.80746 10.6217H7.11764C7.51379 10.6217 7.83607 10.944 7.83607 11.3402V15.995H9.32445V13.5843C9.32445 13.1881 9.64676 12.8659 10.0429 12.8659H11.3531C11.7492 12.8659 12.0715 13.1882 12.0715 13.5843V15.995H12.6893C12.8369 15.995 12.9565 16.1147 12.9565 16.2623C12.9565 16.4099 12.8369 16.5295 12.6893 16.5295H0.267457C0.119853 16.5295 0.000204086 16.4099 0.000204086 16.2623C0.000204086 16.1147 0.119853 15.995 0.267457 15.995Z"
        fill={props.fill != null ? props.fill : '#8FABC7'}
      />
      <path
        d="M17.9581 12.8036C17.9641 12.919 17.9235 13.0364 17.8353 13.1245C17.7472 13.2126 17.6299 13.2532 17.5145 13.2473C17.5077 13.2476 17.5009 13.2483 17.494 13.2483H14.9358C14.7022 13.2483 14.5129 13.059 14.5129 12.8254C14.5129 12.5919 14.7022 12.4026 14.9358 12.4026H16.5154L12.3353 8.2224L10.7589 9.79878C10.6796 9.87806 10.572 9.92263 10.4599 9.92263C10.3477 9.92263 10.2402 9.87806 10.1609 9.79878L2.55939 2.19723C2.39427 2.03211 2.39427 1.76436 2.55939 1.5992C2.64197 1.5167 2.75018 1.47543 2.85838 1.47543C2.96659 1.47543 3.07484 1.5167 3.15738 1.59928L10.4599 8.90183L12.0363 7.32545C12.2014 7.16033 12.4691 7.16033 12.6343 7.32545L17.1135 11.8046V10.3749C17.1135 10.1414 17.3028 9.95206 17.5363 9.95206C17.7698 9.95206 17.9591 10.1414 17.9591 10.3749V12.7832C17.9591 12.7901 17.9584 12.7968 17.9581 12.8036Z"
        fill={props.fill != null ? props.fill : '#8FABC7'}
      />
      <path
        d="M1.4331 6.31809e-06C1.66664 6.31809e-06 1.85595 0.189322 1.85595 0.422855C1.85595 0.656387 1.66664 0.845703 1.4331 0.845703C1.19957 0.845703 1.01025 0.656387 1.01025 0.422855C1.01025 0.189322 1.19957 6.31809e-06 1.4331 6.31809e-06Z"
        fill={props.fill != null ? props.fill : '#8FABC7'}
      />
    </svg>
  );
}
export default GraphDownIcon;
