const INFO = {
  AUCTION_ROUND: {
    HEADER_NAME:
      'V tejto časti nastavujete jednotlivé aukčné kolá. Na výber je typ Prípravné kolo a Aukčnékolo. Oba typy kôl majú rovnaké nastavenia. Prípravné kolo slúži na to, aby ste vyzvali uchádzačov na účasťv aukcii, aby si uchádzači mohli skontrolovať nastavenia aukcie a pripraviť sa na samotné ostré aukčné kolo. V rámci prípravného aukčného kola Vás tak môžu upozorniť na chyby v nastaveniach, identifikačných údajoch a pod. Prípravné aukčné kolo slúži na kontrolu pred ostrou aukciou. Aukčné kolo – jedná sa o priame licitovanie jednotlivých kritérií resp. položiek (v závislosti od nastavenia), pričom výsledkom sú finálne návrhy na plnenie kritérií jednotlivých uchádzačov.',
    SETTINGS:
      'Systémovo sú predvolené nastavenia, ktoré vyžaduje zákon o verejnom obstarávaní. V prípade že zmeníte preddefinované nastavenia je možné, že priebeh aukcie nebude zhodný s požiadavkami podľa zákona o verejnom obstarávaní.',
    AUCTION_ROUND_NAME: 'Zadajte názov aukčného kola',
    ROUNDTYPE:
      'Vyberte typ kola zo zoznamu. Prípravné kolo je kolo, počas ktorého sa účastníci prihlasujú na aukciu a dodávajú technickú dokumentáciu položky, ak to aukcia vyžaduje.Aukčné kolo je kolo, počas ktorého účastníci bidujú, resp. penia svoje ponuky.Aukčné kolo je kolo, počas ktorého účastníci bidujú, resp. penia svoje ponuky',
    RENEWTYPE:
      'Vyberte podmienku zo zoznamu, pri ktorej sa kolo bude predlžovať ',
    DURATION: 'Zadajte ako dlho bude kolo trvať v minútach',
    RENEWDURATION:
      'Zadajte o koľko minút sa predĺži kolo ak je splnená podmienka predĺženia kola.',
    TIME_FOR_DURATION_RAISE:
      'V akom čase pred skončením aukcie je potrebná zmena ceny/kritéria aby bolo kolo automaticky predlžené, napr. ak sa cena/kritéria zmenia v posledných 5 minútach aukcie',
    RENEWNULLCURRENTTIME: 'Nulovať aktuálny čas pri predlžovaní',
    BEST_OPT_OFFER_SHOW: 'Zobraziť najlepšie ponuky voliteľných podmienok',
    USER_TOP_HELP_CALC_SHOW:
      'Zobrazovať účastníkom najlepšie hodnoty pomocných výpočtov',
    USER_ORDER_SHOW: 'Zobrazovať uchádzačom ich poradie',
    USER_ITEM_ORDER_SHOW: 'Zobrazovať uchádzačom poradie pri každej položke',
    ADMIN_HELP_CALC_SHOW: 'Zobrazovať pomocné výpočty u administrátora',
    USER_HELP_CALC_SHOW: 'Zobrazovať pomocné výpočty u uchádzačov',
    USER_HELP_ORD_CALC_SHOW: 'Zobrazovať uchádzačom poradie pomocných výpočtov',
    USER_TOP_COLL_OFF_SHOW: 'Zobraziť uchádzačom stĺpec najlepšej ponuk',
    START_PRICE_SHOW: 'Zobraziť vyvolávacie ceny (pokiaľ sú zadané)',
    ALLOW_BIGGER_OFFER: 'Znemožniť zadanie vyššej ponuky než vyvolávacej',
    SHOW_STR_PRICE_COLLNAME: 'Zobraziť v názve stĺpca Vyv. cena',
    SHOW_USER_TOP_END_PRC: 'Zobrazovať uchádzačom najlepšiu celkovú cenu',
    SHOW_USER_OFFER_END_PRC: 'Zobrazovať uchádzačom celkovú cenu ich ponuky',
    ALLOW_BEAT_FIRST:
      'Znemožniť dorovnať najvýhodnejšiu ponuku podľa typu hodnotenia (tj. na 1. mieste)',
    ALLOW_BEAT_OTHER:
      'Znemožniť dorovnať ostatné ponuky (tj. na druhom a ďalšom mieste v poradí)',
    ALLOW_BEAT_TOP_ITEM_VAL: 'Znemožniť dorovnať najlepšiu hodnotu pri položke',
    SHOW_CONTRACTOR_INF: 'Zobrazovať info dodávateľovi',
    SHOW_USER_TOP_ITEM_PRC:
      'Zobrazovať uchádzačom najlepšie ceny pri položkách',
    AUCTIONROUNDSTARTTIME: 'Začiatok aukcie',
  },
  ITEMS: {
    HEADER_NAME:
      'V tejto časti môžete nastaviť jednotlivé položky pod zvolené kritériá. V prípade ak vytvoríte položky a priradíte ich ku kritériám, budú uchádzačilicitovať hodnotu jednotlivých položiek. Súčet bodových hodnôt za položky bude predstavovať hodnotu kritéria. Pre hodnotu kritéria je rozhodujúce nastavenie samotných kritérií, napr. či najvyšší počet bodov získa najnižšia hodnota alebo naopak najvyššia hodnota. ',
    NAME: 'Zadajte názov položky',
    TYPE: 'Vyberte typ položky zo zoznamu',
    PRICEAFFECT: 'Ovplyvňuje cenu',
    UNIT: 'Vyberte mernú jednotku položky zo zoznamu',
    AMOUNT: 'Vyplňte požadované množstvo vybranej položky ',
    DESCRIPTION: 'Zadajte popis položky, ktorý môže obsahovať potrebné detaily',
    CUSTOM_UNIT: 'Zadajte vlastnú mernú jednotku',
  },
  OFFERS: {
    HEADER_NAME:
      'V tejto časti môžete upraviť ponuku uchadzača na jednotlivé položky a kritéria. Pri pridaní alebo odstránení položky/kritéria , bude daná ponuka automaticky vymazaná. ',
  },
  CRITERIA: {
    HEADER_NAME:
      'Môže byť zvolené jedno až x kritérií, ktoré uchádzač v aukcii navrhujú. Kritériá môžete zvoliť ako „neaukčné“, ktoré nebudú môcťupravovať a do aukcie vstupujú s pevnou hodnotou a pevne pridelenými bodmi, a „aukčné“, ktoré uchádzači v priebehu aukcie upravujú. V prípade, ak sú predmetom aukcie konkrétne položky, najprv vytvorte typ kritéria a následne k nemu v záložke „položky“  jednotlivé položky priradíte. V prípade priradenia položiek môžu uchádzačimeniť návrh na plnenie kritérií konkrétnych položiek, pričomsúčet pridelených bodov položiek predstavuje hodnotu kritéria. ',
    DECIMALCOUNT: 'Decimálna hodnota',
    MAXIMALVALUE:
      'Vyberte maximálnu hodnotu pre kritérium. Ak kritérium nemá určenú maximálnu hodnotu, nevypĺňajte',
    MINIMALVALUE:
      'Zadajte minimálnu hodnotu pre kritérium. Ak kritérium nemá určenú maximálnu hodnotu, nevypĺňajte',
    MINVAULECHANGE:
      'Vyberte číslo, ktoré bude predstavovať minimálnu možnú zmenu (prihadzovanej/podhadzovanej hodnoty) kritéria pre účastníkov',
    MAXVAULECHANGE:
      'Vyberte číslo, ktoré bude predstavovať maximálnu možnú zmenu (prihadzovanej/podhadzovanej hodnoty) kritéria pre účastníkov',
    MAXIMUMPOINTS: 'Uveďte váhu kritéria pre výsledné vyhodnocovanie ponúk',
    NATURE:
      'Smer, ktorým sa bude hodnotiť kritérium. Vzostupne (rastúco): čím viac tým lepšie',
    TAXINCLUSION: 'zaškrtnúť v prípade, ak má kritérium vplyv na cenu ',
    ID: 'id',
    IPADRESS: 'ipAdress',
    NAME: 'Uveďte názov kritéria',
    TYPE: 'Vyberte typ kritéria z vybraných možností',
    UNIT: 'Vyberte mernú jednotku kritéria',
    UUID: 'uuid',
    CUSTOM_UNIT: 'Zadajte vlastnú mernú jednotku',
  },
  ORGANIZATION: {
    HEADER_NAME:
      'Zoznam uchádzačov, ktorí v rámci zákazky predložili ponuky. Zoznam je exportovaný zo systému Tenderia. Identifikačné údaje uchádzačov je možné editovať, k úpravám odporúčamepristúpiť jedine v odôvodnených prípadoch, napr. ak uchádzač uviedol vo formulári ponuky nesprávne údaje. V takomto prípade tiež odporúčame aby ste takéto zmeny uviedli aj v prislúchajúcich zápisniciach pre potreby vysvetlenia.',
    NAME: 'Zadajte názov spoločnosti',
    CITY: 'Zadajte mesto v ktorom organizácia sídli',
    COUNTRY: 'Zadajte štát v ktorom organizácia sídli',
    STREET: 'Zadajte ulicu na ktorej organizácia sídli',
    STREETNUMBER: 'Zadajte číslo ulice alebo domu v ktorom organizácia sídli',
    POSTCODE: 'Zadajte PSČ, ktoré sa vzťahuje na sídlo organizácie',
    COMPANYID: 'Zadajte 8 miestne identifikačné číslo organizácie',
    VATID:
      'Zadajte identifikačné číslo pre daň z pridanej hodnoty ak Vám bolo pridelené',
    TAXID: 'Zadajte 10 miestne daňové identifikačné číslo organizácie',
  },
  ORDER: {
    HEADER_NAME:
      'Jednoduchý prehľad zvolených nastavení aukcie. Pre úpravu nastavení prosím prejdite na jednotlivé kategórie v ľavom menu. Po editácii kategórií sa tieto zobrazia s ikonou potvrdenia.',
    NAME: 'Zadajte názov zákazky',
    PUBLISH_TYPE: 'Vyberte kde má byť aukcia publikovaná a oznámená',
    TEST_AUCTION:
      'Zaškrtnite v prípade ak ide o testovaciu aukciu, ktorá sa reálne neuskutoční',
  },
  AUCTION: {
    HEADER_NAME: '',
    EVALCRITERIUM:
      'Vyberte zo zoznamu spôsob na základe ktorého sa bude aukcia vyhodnocovať',
    EVALCRITERIUM_TEXT: 'Hodnotiace kritérium (zobrazené v protokoloch)',
    DECREASEVALUE: 'Vyberte krok zníženia',
    MINDECREASEVALUE_FIAT:
      'Zadajte minimálne možné jednorazové zníženie ceny účastníka aukcie v eurách',
    MAXDECREASEVALUE_FIAT:
      'Zadajte maximálne možné jednorazové zníženie ceny účastníka aukcie v eurách',
    MINDECREASEVALUE_PERCENTAGE:
      'Zadajte minimálne možné jednorazové zníženie ceny účastníka aukcie v percentách',
    MAXDECREASEVALUE_PERCENTAGE:
      'Zadajte maximálne možné jednorazové zníženie ceny účastníka aukcie v percentách',
    SHOWWINNINGOFFERS:
      'Zaškrtnite v prípade, ak chcete zobraziť víťazné ponuky',
    ESTIMATEDVALUE:
      'Zadajte porovnávaciu cenu celej aukcie. Porovnávaciu cenu môžete použiť aj z predchádzajúcich rovnakých obstarávaní',
    CPVCODE:
      'Vyplňte CPV kód  ktorý všeobecne opisuje produkt. CPV kód klasifikuje predmet obstarávania podľa kategórii definovaných v CPV slovníku',
    CPVCODES:
      'Vyplňte CPV kód, ktorý bližšie špecifikuje produkt (možný výber viacerých CPV kódov)',
    ITEM: 'Vyplňte položky, pre ktoré dané kritérium platí',
    CRITERIUM: 'Vyplňte kritéria, pre ktoré dané položky platí',
  },
  AUDIT_LOG: {
    HEADER_NAME: '',
  },
};

export default INFO;
