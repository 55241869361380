// import React from 'react';
// import {toast} from 'react-toastify';

function SubmitFormValidator(form) {
  if (typeof form.syncErrors !== 'undefined' && form.syncErrors != null) {
    if (Object.keys(form.syncErrors).length) {
      // const msg = 'Niesú správne zadané všetky údaje!';
      // const errors = {};
      // Object.keys(form.syncErrors).forEach((key, i) => {
      //   msg += '\n ' + `${form.syncErrors[key]}`;
      // });
      // toast.error(msg);
      return true;
    }
  }
  return false;
}

/**
 * Normalize to positive integer
 *
 * https://redux-form.com/8.2.2/docs/api/field.md/#-code-normalize-value-previousvalue-allvalues-previousallvalues-name-gt-nextvalue-code-optional-
 *
 * @param {*} value
 * @param {*} previousValue
 * @param {*} allValues
 * @param {*} previousAllValues
 * @param {*} name
 */
export const normalizePositiveInteger = (
  value,
  previousValue,
  allValues,
  previousAllValues,
  name
) => {
  // allow nubers only
  const num = Number(value.replace(/\D/g, ''));
  if (isNaN(num) || num === 0) {
    return previousValue;
  }
  if (value.length > 9) {
    return previousValue;
  }
  return num;
};

export const normalizePositiveOrZeroIntegerKeepEmpty = (
  value,
  previousValue,
  allValues,
  previousAllValues,
  name
) => {
  if (value === '') {
    return value;
  } else
    return normalizePositiveOrZeroInteger(
      value,
      previousValue,
      allValues,
      previousAllValues,
      name
    );
};

/**
 * Normalize to positive integer or zero (0)
 *
 * https://redux-form.com/8.2.2/docs/api/field.md/#-code-normalize-value-previousvalue-allvalues-previousallvalues-name-gt-nextvalue-code-optional-
 *
 * @param {*} value
 * @param {*} previousValue
 * @param {*} allValues
 * @param {*} previousAllValues
 * @param {*} name
 */
export const normalizePositiveOrZeroInteger = (
  value,
  previousValue,
  allValues,
  previousAllValues,
  name
) => {
  const num = Number(value.replace(/\D/g, ''));
  if (isNaN(num)) {
    return isNaN(Number(previousValue)) ? '' : previousValue;
  }
  if (Number(num) === 0) {
    return 0;
  }
  return normalizePositiveInteger(
    value,
    previousValue,
    allValues,
    previousAllValues,
    name
  );
};

/**
 * Normalize to float (positive, negative, zero)
 *
 * https://redux-form.com/8.2.2/docs/api/field.md/#-code-normalize-value-previousvalue-allvalues-previousallvalues-name-gt-nextvalue-code-optional-
 *
 * @param {*} value
 * @param {*} previousValue
 * @param {*} allValues
 * @param {*} previousAllValues
 * @param {*} name
 */
export const normalizeFloat = (
  value,
  previousValue,
  allValues,
  previousAllValues,
  name
) => {
  if (value.endsWith('.')) {
    return value;
  }
  // eslint-disable-next-line no-useless-escape
  const num = Number(value.replace(/[^-\d\.]/g, ''));
  if (isNaN(num)) {
    return previousValue;
  }
  if (value.length > 18) {
    return previousValue;
  }
  return num;
};

/**
 * Normalize to positive float or zero (0)
 *
 * https://redux-form.com/8.2.2/docs/api/field.md/#-code-normalize-value-previousvalue-allvalues-previousallvalues-name-gt-nextvalue-code-optional-
 *
 * @param {*} value
 * @param {*} previousValue
 * @param {*} allValues
 * @param {*} previousAllValues
 * @param {*} name
 */
export const normalizePositiveOrZeroFloat = (
  value,
  previousValue,
  allValues,
  previousAllValues,
  name
) => {
  // allow nubers only
  const num = normalizeFloat(
    value,
    previousValue,
    allValues,
    previousAllValues,
    name
  );
  if (isNaN(num)) {
    return isNaN(Number(previousValue)) ? '' : previousValue;
  }
  if (num < 0) {
    return Math.abs(num);
  }
  if (value.length > 18) {
    return previousValue;
  }
  return num;
};
export const normalizePositiveFloatKeepEmpty = (
  value,
  previousValue,
  allValues,
  previousAllValues,
  name
) => {
  if (value === '') {
    return value;
  }
  if (value === '0') {
    return value;
  }
  return normalizePositiveFloat(
    value,
    previousValue,
    allValues,
    previousAllValues,
    name
  );
};
/**
 * Normalize to positive float
 *
 * https://redux-form.com/8.2.2/docs/api/field.md/#-code-normalize-value-previousvalue-allvalues-previousallvalues-name-gt-nextvalue-code-optional-
 *
 * @param {*} value
 * @param {*} previousValue
 * @param {*} allValues
 * @param {*} previousAllValues
 * @param {*} name
 */
export const normalizePositiveFloat = (
  value,
  previousValue,
  allValues,
  previousAllValues,
  name
) => {
  // allow nubers only
  const num = normalizePositiveOrZeroFloat(
    value,
    previousValue,
    allValues,
    previousAllValues,
    name
  );
  if (isNaN(num)) {
    return isNaN(Number(previousValue)) ? '' : previousValue;
  }
  if (num === 0) {
    return previousValue;
  }
  if (value.length > 18) {
    return previousValue;
  }
  // if (num.value > 9223372036854775808) {
  //   return 9223372036854775807;
  // }
  return num;
};

export const normalizeLength = max => (
  value,
  previousValue,
  allValues,
  previousAllValues,
  name
) => {
  if (value.length > max) {
    return previousValue;
  }
  return value;
};
/* Validátory pre polia */
export const required = value =>
  value || typeof value === 'number'
    ? undefined
    : 'Musíte vyplňiť požadované pole!';

export const maxLength = max => value =>
  value && value.length > max
    ? `Povolená dĺžka pola je ${max} znakov`
    : undefined;

export const minValueChange = minValueChang => value =>
  minValueChang < value
    ? undefined
    : // eslint-disable-next-line prefer-template
      'Minimálne prihodenie pre kritérium je ' + minValueChang;

export const maxLength298 = maxLength(298);
export const maxLengthDescription = maxLength(4000);
export const maxValue1000 = value =>
  value && value > 1000 ? 'Maximálny zadaní počet je 1000!' : undefined;
export const maxValue100 = value =>
  value && value > 100 ? 'Maximálny zadaní počet je 100!' : undefined;

export default SubmitFormValidator;
