import React from 'react';
// import {useRouteMatch} from 'react-router-dom';
import {useSelector} from 'react-redux';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import {selectors as selectorWS} from '../../features/websocket';

const SideBarAHBidder = () => {
  // const match = useRouteMatch('/:auctionId');
  // const {auctionId} = match.params;
  const criteriaOffer = useSelector(selectorWS.getValues);
  return (
    <Navbar variant="transparent" bg="transparent" className="p-0">
      <div className="col-md-12 no-gutters pl-0 pr-0 fixed-sidebar">
        {criteriaOffer != null ? (
          <>
            <Nav as="div" className="flex-column">
              <Nav as="div" className="flex-column">
                <Nav>
                  <Nav.Item className="col-lg-12 col-md-12 pt-4  text-center">
                    <p className="text-white text-center mb-1">
                      Aktuálne umiestnenie
                    </p>
                    <p className="text-white h4">
                      {criteriaOffer.scorePlacement}. miesto
                    </p>
                  </Nav.Item>
                </Nav>
              </Nav>
            </Nav>
            <Nav as="div" className="flex-column">
              <Nav>
                <Nav.Item className="col-lg-12 col-md-12 pt-4 pb-4 text-center">
                  <p className="text-white text-center mb-1">Vaše body</p>
                  <p className="text-white h4 ">
                    {Number.parseFloat(criteriaOffer.yourPoints).toFixed(2)}
                  </p>
                </Nav.Item>
              </Nav>
            </Nav>
            {/* <Nav className="flex-column pl-4 pr-2">
              <Nav.Item className="col-lg-12 mb-2 mt-2">
                <Button
                  as={Link}
                  to={`/${auctionId}/auction_hall/bidder`}
                  variant="outline-success"
                  className="text-white col-lg-12 pt-3 pb-3">
                  <p className="text-white text-center mb-1 mt-2">
                    Vaša ponuka
                  </p>
                  <p className="text-white text-center h-3">
                    <LocalAtmIcon /> {criteriaOffer.yourOfferValue} €
                  </p>
                </Button>
              </Nav.Item>

              <Nav.Item className="col-lg-12 mb-5 mt-2">
                <Button variant="success" className="text-white col-lg-12">
                  <p className="text-white text-center mt-3 mb-3">Prihodiť</p>
                </Button>
              </Nav.Item>
              <Nav.Item fixed="bottom" className="col-lg-12 mb-2 mt-5">
                <Card className="col-lg-12 mb-2 mt-2 bg-grey">
                  <Card.Body>
                    <div className="col-lg-12 bg-transparent p-0">
                      <Card.Img
                        variant="top"
                        className="img-thumbnail bg-transparent float-right p-0 question border-0"
                        src={Question}
                      />
                    </div>
                    <Card.Title className="text-white">
                      Niečo Vám nefunguje?
                    </Card.Title>
                    <Card.Text className="text-white">
                      Kontaktujte náš support ktorý tu je pre Vás 24 hodín denne
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Nav.Item>
              <Nav.Item variant="bottom" className="col-lg-12 mb-2 mt-2">
                <Button variant="primary" className="text-white col-lg-12">
                  <p className="text-white text-center mt-3 mb-3">
                    Kontaktovať admina
                  </p>
                </Button>
              </Nav.Item>
            </Nav> */}
          </>
        ) : (
          ''
        )}
      </div>
    </Navbar>
  );
};
export default SideBarAHBidder;
