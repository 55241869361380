import {
  WS_UPDATE_VALUES,
  WS_SHOW_LOADING,
  WS_HIDE_LOADING,
  WS_SET_INITIAL_VALUES,
  WS_USERS_VALUES,
} from './actionTypes';

const initialState = {
  values: null,
  loading: false,
  initialValues: null,
  users: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case WS_SET_INITIAL_VALUES:
      return {...state, initialValues: action.payload};
    case WS_UPDATE_VALUES:
      return {...state, values: action.payload};
    case WS_SHOW_LOADING:
      return {...state, loading: true};
    case WS_HIDE_LOADING:
      return {...state, loading: false};
    case WS_USERS_VALUES:
      return {...state, users: action.payload};
    default:
      return state;
  }
};
